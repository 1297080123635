import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { decode } from "jsonwebtoken";
import parseJWT from "../../utils/jwt-utils/JWTParser";
// import { useAccountStore } from "../../state-management";
import { ErrorHandlingContext } from "../../contexts/errorHandlingContext";
import { ERROR_MODAL_TYPE } from "../../components/UI/ErrorModal";
import { useContext } from "react";

const auth0RedirectErrors = new Set(["login_required", "consent_required"]);

const useAccessToken = () => {
  // const orgId = useAccountStore(state => state.orgId);

  const [data, setData] = useState({
    accessToken: null,
    decoded: null,
    parsed: null,
  });
  const [error, setError] = useState(null);

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { createError } = useContext(ErrorHandlingContext);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const decoded = decode(accessToken);
        const parsed = parseJWT(decoded);

        setData({ accessToken, decoded, parsed });
      } catch (e) {
        createError(ERROR_MODAL_TYPE.AUTH);
        if (auth0RedirectErrors.has(e.error)) {
          await loginWithRedirect({
            // organization: orgId,
            redirectUri: window.location.path,
          });

          return;
        }
        console.error(e.message);
        setError(e);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently, loginWithRedirect, createError]);

  return { ...data, error };
};

export default useAccessToken;
