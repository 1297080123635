import { createContext, useState, useMemo } from "react";

export const AppConfigContext = createContext();

export const AppConfigProvider = (props) => {
  const TRIAL_MODE_DEFAULT_LIMIT = 250; // have to be one of these options: 25, 50, 100, 250, 1000, 5000, 10000, otherwise it will break 'getSelectedSliderValue' function in NumOfRecordsSelectore component
  const [isTrialMode, setTrialMode] = useState();
  const [trialModeLimit, setTrialModeLimit] = useState();

  const appConfigProviderValue = useMemo(
    () => ({
      TRIAL_MODE_DEFAULT_LIMIT,
      isTrialMode,
      setTrialMode,
      trialModeLimit,
      setTrialModeLimit,
    }),
    [TRIAL_MODE_DEFAULT_LIMIT, isTrialMode, trialModeLimit]
  );

  return (
    <AppConfigContext.Provider value={appConfigProviderValue}>
      {props.children}
    </AppConfigContext.Provider>
  );
};
