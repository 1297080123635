import { useAccessToken } from "../auth0-hooks";

const useAuthorizationHeader = () => {
  const { accessToken, error: accessTokenError } = useAccessToken();

  return {
    error: accessTokenError,
    header: accessToken != null ? `Bearer ${accessToken}` : accessToken,
  };
};

export default useAuthorizationHeader;
