import { createContext, useState, useMemo } from "react";

export const AccountHouseholdsContext = createContext();

export const AccountHouseholdsProvider = (props) => {
  const [availableHouseholds, setAvailableHouseholds] = useState([]);
  const [selectedHouseholds, setSelectedHouseholds] = useState([]);

  const accountHouseholdsProviderValue = useMemo(
    () => ({
      availableHouseholds,
      setAvailableHouseholds,
      selectedHouseholds,
      setSelectedHouseholds,
    }),
    [availableHouseholds, selectedHouseholds]
  );

  return (
    <AccountHouseholdsContext.Provider value={accountHouseholdsProviderValue}>
      {props.children}
    </AccountHouseholdsContext.Provider>
  );
};
