import React from "react";
import PropTypes from "prop-types";
import classes from "./SummaryProp.module.scss";

const SummaryProp = (props) => {
  let valueOverAvgElement = <></>;
  if (props.valueOverAvg != undefined && props.valueOverAvg > 0) {
    valueOverAvgElement = (
      <div className={classes.valueOverAvg}>
        +{props.valueOverAvg} Above Avg
      </div>
    );
  } else if (props.valueOverAvg != undefined && props.valueOverAvg < 0) {
    valueOverAvgElement = (
      <div className={classes.valueOverAvg}>{props.valueOverAvg} Below Avg</div>
    );
  } else if (props.valueOverAvg != undefined && props.valueOverAvg === 0) {
    valueOverAvgElement = <div className={classes.valueOverAvg}>as Avg</div>;
  }

  return (
    <li className={classes.prop}>
      <img
        src={props.logo}
        className={
          props.type === "main" ? classes.barIcon : classes.secondaryIcon
        }
        style={{ width: props.logoWidth, height: props.logoHeight }}
      />
      <div>
        <div
          className={
            props.type === "main"
              ? classes.value
              : `${classes.value} ${classes.secondaryValue}`
          }
        >
          {props.value ? props.value : null}
        </div>
        <div className={props.isFullScreenView === false ? classes.some : null}>
          <div className={classes.title}>{props.title}</div>
          {valueOverAvgElement}
        </div>
      </div>
    </li>
  );
};

SummaryProp.propTypes = {
  valueOverAvg: PropTypes.number,
  logo: PropTypes.string,
  type: PropTypes.string,
  logoWidth: PropTypes.string,
  logoHeight: PropTypes.string,
  value: PropTypes.number,
  isFullScreenView: PropTypes.bool,
  title: PropTypes.string,
};

export default SummaryProp;
