import React from "react";
import { useRef, useEffect } from "react";

import numeral from "numeral";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import configData from "../../../config.js";

import Card from "../../UI/Card";

import RedCircle from "../../../assets/img/redCircle.svg";
import OrangeCircle from "../../../assets/img/orangeCircle.svg";
import YellowCircle from "../../../assets/img/yellowCircle.svg";
import CreamCircle from "../../../assets/img/creamCircle.svg";

import classes from "./SharingLocations.module.scss";

import { useState } from "react";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import NoData from "../../UI/NoData";

import { getSharerLocations } from "../../../external-apis";

import { codes } from "iso-country-codes";

const SharingLocations = () => {
  const chartRef = useRef(null);
  const seriesRef = useRef(null);

  useEffect(() => {
    // Create chart instance
    let chart = am4core.create("sharingLocationChart", am4maps.MapChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4MapLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.maxZoomLevel = 64;
    chart.paddingTop = 5;
    chart.paddingBottom = 5;
    chart.paddingRight = 5;
    chart.paddingLeft = 5;

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Mercator();

    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.nonScalingStroke = true;
    polygonSeries.strokeWidth = 2;
    chart.series.push(polygonSeries);

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = am4core.color("#576079");
    polygonTemplate.propertyFields.fill = "fill";
    polygonTemplate.stroke = am4core.color("#151724");
    polygonTemplate.strokeWidth = 0.5;

    //excluding series
    polygonSeries.exclude = ["AQ"];

    polygonSeries.interactionsEnabled = true;
    // polygonTemplate.events.on("hit", onClickedItem);
    polygonTemplate.events.on("doublehit", (event) => {
      chart.zoomToMapObject(event.target);
    });

    // Set up tooltips
    polygonSeries.calculateVisualCenter = true;
    polygonTemplate.tooltipPosition = "fixed";
    polygonSeries.tooltip.label.interactionsEnabled = true;
    polygonSeries.tooltip.keepTargetHover = true;
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = "#576079";
    polygonSeries.tooltip.background.strokeWidth = 0;
    polygonTemplate.propertyFields.value = "sharers";
    polygonTemplate.tooltipText = "{name}: {value} Households";
    polygonTemplate.adapter.add("tooltipText", function (text, target) {
      if (target.tooltipDataItem.dataContext?.sharers) {
        let text =
          target.tooltipDataItem.dataContext.country +
          ": " +
          target.tooltipDataItem.dataContext.sharers.toLocaleString() +
          " Household";
        return target.tooltipDataItem.dataContext?.sharers === 1
          ? text
          : text + "s";
      } else return null;
    });

    // Zoom control
    let restoreMap = () => {
      chart.goHome();
    };

    chart.zoomControl = new am4maps.ZoomControl();

    chart.zoomControl.plusButton.background.fill = am4core.color("#25293D");
    chart.zoomControl.plusButton.background.stroke = am4core.color("#25293D");
    chart.zoomControl.plusButton.stroke = am4core.color("#576079");
    chart.zoomControl.plusButton.fill = am4core.color("#576079");
    chart.zoomControl.plusButton.marginBottom = -5;
    chart.zoomControl.plusButton.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#25293D");
    chart.zoomControl.plusButton.background.states.getKey(
      "hover"
    ).properties.stroke = am4core.color("#25293D");

    chart.zoomControl.minusButton.background.fill = am4core.color("#25293D");
    chart.zoomControl.minusButton.background.stroke = am4core.color("#25293D");
    chart.zoomControl.minusButton.stroke = am4core.color("#576079");
    chart.zoomControl.minusButton.fill = am4core.color("#576079");
    chart.zoomControl.minusButton.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#25293D");
    chart.zoomControl.minusButton.background.states.getKey(
      "hover"
    ).properties.stroke = am4core.color("#25293D");

    let homeButton = new am4core.Button();
    homeButton.events.on("hit", restoreMap);

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.marginBottom = 0;
    homeButton.width = 30;
    homeButton.background.fill = am4core.color("#25293D");
    homeButton.background.stroke = am4core.color("#25293D");
    homeButton.fill = am4core.color("#576079");
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.parent = chart.zoomControl;
    homeButton.insertBefore(chart.zoomControl.plusButton);

    homeButton.defaultState.properties.fill = am4core.color("white");
    homeButton.background.states.getKey("hover").properties.fill =
      am4core.color("#25293D");
    homeButton.background.states.getKey("hover").properties.stroke =
      am4core.color("#25293D");

    seriesRef.current = polygonSeries;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const [maxValue, setMaxValue] = useState(0);
  const [steps, setSteps] = useState([]);
  let interval = 0;

  const getColorByValue = (value) => {
    const color = steps.filter(
      (step) => value > step.minValue && value <= step.maxValue
    )?.[0]?.color;
    return color ? color : am4core.color("#576079");
  };

  const {
    data: sharerLocations,
    isLoading: isLoadingSharerLocations,
    isError: isErrorSharerLocations,
    error: errorSharerLocations,
  } = useQueryWithAuthorization("sharerLocations", getSharerLocations());

  useEffect(() => {
    if (sharerLocations) {
      setMaxValue(
        Math.max(...sharerLocations.map((country) => country.sharers))
      );
    }
  }, [sharerLocations]);

  useDeepCompareEffect(() => {
    if (maxValue) {
      interval =
        maxValue % 4000 === 0
          ? maxValue / 4
          : (Math.floor(maxValue / 4000) + 1) * 1000;
      setSteps([
        { minValue: -1, maxValue: 0, color: am4core.color("#576079") },
        { minValue: 0, maxValue: interval, color: am4core.color("#FDF2D1") },
        {
          minValue: interval,
          maxValue: interval * 2,
          color: am4core.color("#FFC41C"),
        },
        {
          minValue: interval * 2,
          maxValue: interval * 3,
          color: am4core.color("#FF8B45"),
        },
        {
          minValue: interval * 3,
          maxValue: interval * 4,
          color: am4core.color("#FF3333"),
        },
      ]);
    }
  }, [maxValue]);

  useDeepCompareEffect(() => {
    if (steps && steps.length > 0) {
      let locations = sharerLocations.map((sharerLocation) => {
        const countryWords = sharerLocation.country.split(" ");
        countryWords.map((word, j) => {
          let wordProperties = word
            .replaceAll("(", " ( ")
            .replaceAll(")", " ) ")
            .replaceAll("[", " [ ")
            .replaceAll("]", " ] ")
            .replaceAll("-", " - ")
            .split(" ");
          wordProperties.map((prop, i) => {
            if (
              prop == "(" ||
              prop == ")" ||
              prop == "[" ||
              prop == "]" ||
              prop == "-"
            ) {
              wordProperties[i] = prop;
            } else if (
              [
                "of",
                "Of",
                "the",
                "The",
                "and",
                "And",
                "part",
                "Part",
                "da",
                "Da",
              ].includes(prop)
            ) {
              wordProperties[i] = prop.toLowerCase();
            } else {
              wordProperties[i] =
                prop.charAt(0).toUpperCase() + prop.slice(1).toLowerCase();
            }
          });
          word = wordProperties.join("");
          countryWords[j] = word;
        });
        const capitalizeName = countryWords.join(" ");
        const isoCountryObj = codes.filter(
          (countryObj) =>
            countryObj.name == capitalizeName ||
            countryObj.altName == capitalizeName ||
            countryObj.shortName == capitalizeName ||
            countryObj.alpha2 == sharerLocation.country.toUpperCase() ||
            countryObj.alpha3 == sharerLocation.country.toUpperCase()
        );
        sharerLocation.id =
          isoCountryObj.length == 0 ? null : isoCountryObj[0].alpha2;
        sharerLocation.fill = getColorByValue(sharerLocation.sharers);
        return sharerLocation;
      });
      seriesRef.current.data = locations;
    }
  }, [steps]);

  useEffect(() => {
    if (isLoadingSharerLocations || isErrorSharerLocations) {
      seriesRef.current.data = [];
    }
  }, [isLoadingSharerLocations, isErrorSharerLocations]);

  let legendElement = <></>;
  if (steps.length > 0) {
    legendElement = (
      <div className={classes.legend}>
        <div className={classes.legentItem}>
          <img src={RedCircle} className={classes.legendBullet} />
          <div className={classes.legendTitle}>
            {numeral(steps[3].maxValue).format("0.a")}-
            {numeral(steps[4].maxValue).format("0.a")} Households
          </div>
        </div>
        <div className={classes.legentItem}>
          <img src={OrangeCircle} className={classes.legendBullet} />
          <div className={classes.legendTitle}>
            {numeral(steps[2].maxValue).format("0.a")}-
            {numeral(steps[3].maxValue).format("0.a")} Households
          </div>
        </div>
        <div className={classes.legentItem}>
          <img src={YellowCircle} className={classes.legendBullet} />
          <div className={classes.legendTitle}>
            {numeral(steps[1].maxValue).format("0.a")}-
            {numeral(steps[2].maxValue).format("0.a")} Households
          </div>
        </div>
        <div className={classes.legentItem}>
          <img src={CreamCircle} className={classes.legendBullet} />
          <div className={classes.legendTitle}>
            1-{numeral(steps[1].maxValue).format("0.a")} Households
          </div>
        </div>
      </div>
    );
  }

  let nonChartContent = null;
  let showChart = false;

  if (isLoadingSharerLocations) {
    nonChartContent = <Loading />;
  } else if (sharerLocations == null) {
    nonChartContent = <NoData />;
  } else if (isErrorSharerLocations) {
    console.warn(
      "Failed to lookup for sharing locations data",
      errorSharerLocations.message
    );
    nonChartContent = <ErrorMessage />;
  } else {
    showChart = true;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Sharer Account Locations</div>
          </div>
          <div id="sharingLocationChart" className={classes.chart}></div>
          {legendElement}
        </div>
      </Card>
    </div>
  );
};

export default SharingLocations;
