// eslint-disable-next-line
const baseUrl = process.env.REACT_APP_ACCOUNT_PORTAL_API_ACCOUNT;
const baseHeaders = {
  "content-type": "application/json",
};

const getAll = (params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/tenancyManager/productConfiguration`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const getByProductId = (productId, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/tenancyManager/productConfiguration/${productId}`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const postProductConfig = (productConfig, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "POST",
    url: `${baseUrl}/tenancyManager/productConfiguration`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
    body: JSON.stringify(productConfig),
  };
};

export { getAll, getByProductId, postProductConfig };
