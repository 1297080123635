import React, { useContext } from "react";

import classes from "./ErrorModal.module.scss";
import "./ErrorModal.css";

import CANCEL from "../../assets/img/cancel.svg";
import DangerLogo from "../../assets/img/danger.svg";
import WarningLogo from "../../assets/img/warning.svg";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { ErrorHandlingContext } from "../../contexts/errorHandlingContext";

export enum ERROR_MODAL_TYPE {
  AUTH,
  CRITICAL,
  MINOR,
  NETWORK,
}

export enum ERROR_MODAL_STATE {
  OPEN,
  CLOSE,
}
export interface IError {
  errorType?: ERROR_MODAL_TYPE;
  errorState?: ERROR_MODAL_STATE;
  errorTitle?: string;
  errorMessage?: string;
  buttonText?: string;
  enableCloseButton?: boolean;
  onClose?: () => void;
  onButtonClick?: () => void;
}

const ErrorModal = () => {
  const { error } = useContext(ErrorHandlingContext);

  const colorPerType = [
    { modalType: ERROR_MODAL_TYPE.NETWORK, color: "#FFC41C" },
    { modalType: ERROR_MODAL_TYPE.MINOR, color: "#FFC41C" },
    { modalType: ERROR_MODAL_TYPE.CRITICAL, color: "#FF3333" },
    { modalType: ERROR_MODAL_TYPE.AUTH, color: "#FF3333" },
  ];

  return (
    error && (
      <div
        className={classes.modal}
        style={{
          backgroundColor:
            error.errorType == ERROR_MODAL_TYPE.AUTH
              ? "rgba(0, 0, 0, 1)"
              : "rgba(0, 0, 0, 0.6)",
        }}
      >
        <div
          className={classes.window}
          style={{
            borderTop:
              "5px solid" +
              (colorPerType.find((item) => item.modalType === error.errorType)
                ?.color || "#06A3ED"),
          }}
        >
          <div className={classes.window_container}>
            <div className={classes.window_header}>
              <div className={classes.window_title}>
                <span>{error.errorTitle || ""}</span>
                {error.enableCloseButton && (
                  <img
                    src={CANCEL}
                    className={classes.cancelIcon}
                    onClick={() => {
                      error.onClose();
                    }}
                  />
                )}
              </div>
            </div>
            <div className={classes.window_divider}></div>
            <div className={classes.window_content}>
              <div style={{ display: "flex" }}>
                {error.errorType === ERROR_MODAL_TYPE.MINOR && (
                  <img
                    src={WarningLogo}
                    className={classes.window_content_icon}
                  ></img>
                )}
                {error.errorType === ERROR_MODAL_TYPE.CRITICAL && (
                  <img
                    src={DangerLogo}
                    className={classes.window_content_icon}
                  ></img>
                )}
                {error.errorType === ERROR_MODAL_TYPE.NETWORK && (
                  <Stack
                    sx={{
                      color: "white",
                      opacity: "0.75",
                      marginRight: "1rem",
                    }}
                    spacing={2}
                    direction="row"
                  >
                    <CircularProgress
                      color="inherit"
                      size="1.5rem"
                      className="slowCircularProgress"
                    />
                  </Stack>
                )}
                <div>
                  {error.errorMessage && (
                    <div className={classes.window_content_title}>
                      {error.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              {error.errorType != ERROR_MODAL_TYPE.NETWORK && (
                <div className={classes.window_buttonsRow}>
                  {error.enableCloseButton && (
                    <button
                      className={classes.window_cancelButton}
                      onClick={() => {
                        error.onClose();
                      }}
                    >
                      CANCEL
                    </button>
                  )}
                  <button
                    className={classes.window_saveButton}
                    onClick={() => {
                      error.onButtonClick();
                    }}
                  >
                    {error.buttonText?.toUpperCase() || "CONTINUE"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ErrorModal;
