import React from "react";

import classes from "./Modal.module.scss";

import CANCEL from "../../assets/img/cancel.svg";
import WarningLogo from "../../assets/img/warning.svg";
import DangerLogo from "../../assets/img/danger.svg";
import ModalBullet from "../../assets/img/modalBullet.svg";

export enum MODAL_TYPES {
  INFO,
  WARNING,
  DANGER,
  SUCCESS,
}

export interface IModalProps {
  type: MODAL_TYPES;
  title?: string;
  subtitle?: string;
  buttonText: string;
  contentTitle?: string;
  contentText?: string;
  contentList?: string[];
  onClose: () => void;
  onButtonClick: () => void;
}

const Modal = (props: IModalProps) => {
  const colorPerType = [
    { modalType: MODAL_TYPES.INFO, color: "#06A3ED" },
    { modalType: MODAL_TYPES.WARNING, color: "#FFC41C" },
    { modalType: MODAL_TYPES.DANGER, color: "#FF3333" },
  ];

  return (
    <div className={classes.modal}>
      <div
        className={classes.window}
        style={{
          borderTop:
            "5px solid" +
            (colorPerType.find((item) => item.modalType === props.type)
              ?.color || "#06A3ED"),
        }}
      >
        <div className={classes.window_container}>
          <div className={classes.window_header}>
            <div className={classes.window_title}>
              <span>{props.title || ""}</span>
              <img
                src={CANCEL}
                className={classes.cancelIcon}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {props.subtitle && (
              <div className={classes.window_subtitle}>{props.subtitle}</div>
            )}
          </div>
          <div className={classes.window_divider}></div>
          <div className={classes.window_content}>
            <div style={{ display: "flex" }}>
              {props.type === MODAL_TYPES.WARNING && (
                <img
                  src={WarningLogo}
                  className={classes.window_content_icon}
                ></img>
              )}
              {props.type === MODAL_TYPES.DANGER && (
                <img
                  src={DangerLogo}
                  className={classes.window_content_icon}
                ></img>
              )}
              <div>
                {props.contentTitle && (
                  <div className={classes.window_content_title}>
                    {props.contentTitle}
                  </div>
                )}
                {props.contentText && (
                  <div className={classes.window_content_text}>
                    {props.contentText}
                  </div>
                )}
                {props.contentList &&
                  props.contentList.map((item) => (
                    <div key={item}>
                      <img
                        src={ModalBullet}
                        style={{ marginRight: ".5rem" }}
                      ></img>
                      <span>{item}</span>
                    </div>
                  ))}
              </div>
            </div>

            <div className={classes.window_buttonsRow}>
              <button
                className={classes.window_cancelButton}
                onClick={() => {
                  props.onClose();
                }}
              >
                CANCEL
              </button>
              <button
                className={classes.window_saveButton}
                onClick={() => {
                  props.onButtonClick();
                }}
              >
                {props.buttonText.toUpperCase() || "CONTINUE"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
