import React, { useContext, useState } from "react";
import { useRef, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from "../../../UI/Card";

import classes from "./ScoreDistributionForGroup.module.scss";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import configData from "../../../../config.js";
import { getFilteredScoresDistributionByDate } from "../../../../external-apis";
import { AccountsContext } from "../../../../contexts/accountsContext";

const ScoreDistributionForGroup = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const { filtersProperties } = useContext(AccountsContext);

  const chartRef = useRef(null);

  const {
    data: filteredScoresDistributionByDate,
    isLoading: isLoadingFilteredScoresDistributionByDate,
    isError: isErrorFilteredScoresDistributionByDate,
    error: errorFilteredScoresDistributionByDate,
  } = useQueryWithAuthorization(
    ["filteredScoresDistributionByDate", filtersProperties],
    getFilteredScoresDistributionByDate(filtersProperties)
  );

  useEffect(() => {
    const serieses = [
      { name: "Honest (score 0)", value: "Honest" },
      { name: "0-20", value: "0-20" },
      { name: "21-40", value: "21-40" },
      { name: "41-60", value: "41-60" },
      { name: "61-80", value: "61-80" },
      { name: "81-100", value: "81-100" },
    ];

    // Create chart instance
    const chart = am4core.create(
      "scoreDistributionForGroupChart",
      am4charts.XYChart
    );
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 5;
    chart.paddingTop = 10;
    chart.paddingBottom = -10;
    chart.paddingLeft = -10;

    chart.colors.list = [
      am4core.color("#FFFFFF"),
      am4core.color("#3380FF"),
      am4core.color("#4BCAD4"),
      am4core.color("#9164D1"),
      am4core.color("#D964A1"),
      am4core.color("#1E4D99"),
    ];

    chart.dateFormatter.dateFormat = "dd-MMM-yyyy";
    chart.numberFormatter.numberFormat = "#";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 40;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.renderer.baseGrid.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 10;
    dateAxis.fontWeight = "600";
    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    dateAxis.renderer.labels.template.location = 0.5;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.strokeDasharray = "3,2";
    valueAxis.renderer.minGridDistance = 15;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "600";
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");

    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#'%'";

    serieses.map((seriesObj) => {
      const series = chartRef.current.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(70);
      series.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      series.name = seriesObj.name;
      series.dataFields.dateX = "date";
      series.dataFields.valueY = seriesObj.value;
      series.dataFields.valueYShow = "totalPercent";
      series.dataItems.template.locations.dateX = 0.5;
      series.stacked = true;
      series.tooltip.pointerOrientation = "vertical";

      series.columns.template.tooltipText =
        "{date}\n{value1}({valueY.totalPercent.formatNumber('#.00')}%) accounts";
      series.columns.template.tooltip = new am4core.Tooltip();
      series.columns.template.tooltip.getFillFromObject = false;
      series.columns.template.tooltip.label.fill = am4core.color("#000");
      series.columns.template.tooltip.label.fontSize = 10;
      series.columns.template.tooltip.background.strokeWidth = 2;
      series.columns.template.tooltip.background.stroke =
        am4core.color("#FEFEFE");
      series.columns.template.tooltip.background.fill =
        am4core.color("#FEFEFE");

      const hoverState = series.columns.template.states.create("hover");
      hoverState.properties.stroke = am4core.color("#ffffff");
    });

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.contentAlign = "center";
    chart.legend.maxWidth = undefined;
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontWeight = "600";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = 5;
    chart.legend.paddingTop = -10;

    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 8;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    if (
      filteredScoresDistributionByDate &&
      filteredScoresDistributionByDate.length > 0
    ) {
      const scoreDistributionGroupByDate = [];
      for (const { date, bin, accounts } of filteredScoresDistributionByDate) {
        if (
          scoreDistributionGroupByDate.findIndex(
            (element) => element.date == date
          ) == -1
        )
          scoreDistributionGroupByDate.push({ date: date });
        scoreDistributionGroupByDate.find((element) => element.date == date)[
          bin
        ] = accounts;
      }
      const chartObj = scoreDistributionGroupByDate.map((dateObj) => {
        return {
          date: dateObj.date,
          Honest: dateObj["Honest"] || 0,
          "0-20": (dateObj["0-10"] || 0) + (dateObj["10-20"] || 0),
          "21-40": (dateObj["20-30"] || 0) + (dateObj["30-40"] || 0),
          "41-60": (dateObj["40-50"] || 0) + (dateObj["50-60"] || 0),
          "61-80": (dateObj["60-70"] || 0) + (dateObj["70-80"] || 0),
          "81-100": (dateObj["80-90"] || 0) + (dateObj["90+"] || 0),
        };
      });
      chartRef.current.data = chartObj;
    }
  }, [filteredScoresDistributionByDate]);

  useEffect(() => {
    if (
      isLoadingFilteredScoresDistributionByDate ||
      isErrorFilteredScoresDistributionByDate
    ) {
      chartRef.current.data = [];
    }
  }, [
    isLoadingFilteredScoresDistributionByDate,
    isErrorFilteredScoresDistributionByDate,
  ]);

  useEffect(() => {
    if (isLoadingFilteredScoresDistributionByDate) {
      setNonChartContent(<Loading />);
    } else if (filteredScoresDistributionByDate === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorFilteredScoresDistributionByDate) {
      console.warn(
        "Failed to lookup for scores distribution by date data",
        errorFilteredScoresDistributionByDate?.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingFilteredScoresDistributionByDate,
    filteredScoresDistributionByDate,
    isErrorFilteredScoresDistributionByDate,
    errorFilteredScoresDistributionByDate,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>
              Score Distribution of Sharer Accounts
            </div>
          </div>
          <div
            id="scoreDistributionForGroupChart"
            className={classes.chart}
          ></div>
        </div>
      </Card>
    </div>
  );
};

export default ScoreDistributionForGroup;
