import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import numeral from "numeral";

import LeftMenu from "../../../components/left-menu/LeftMenu";
import SpecificAccount from "../../../components/pages/specific-account/SpecificAccount";
import ExecutiveSummary from "../../../components/pages/executive-summary/ExecutiveSummary";
import AccountInfo from "../account-info/AccountInfo";

import CSFEyeLogo from "../../../assets/img/csfeye_png_logo.png";
import BlueInfoLogo from "../../../assets/img/blue_info.svg";
import GrayCancelLogo from "../../../assets/img/gray_close.svg";

import classes from "./HomePage.module.scss";

import { useAuth0 } from "@auth0/auth0-react";

import { useDeepCompareEffect } from "../../../custom-hooks";

import Page from "../../UI/Page";
import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import ErrorModal from "../../UI/ErrorModal";

import { useQueryWithAuthorization } from "../../../custom-hooks";

import { getHealthStatus, getAppConfig } from "../../../external-apis/api";

import { AppConfigContext } from "../../../contexts/appConfigContext";
import { ErrorHandlingContext } from "../../../contexts/errorHandlingContext";

import { ERROR_MODAL_STATE, ERROR_MODAL_TYPE } from "../../UI/ErrorModal";

const HomePage = () => {
  const { logout } = useAuth0();
  const [isHealth, setIsHealth] = useState(false);
  const [isUnknownTrialMode, setIsUnknownTrialMode] = useState(undefined);
  const [showTrialModeAlertBox, setShowTrialModeAlertBox] = useState(false);
  const [healthStatusEnabled, setHealthStatusEnabled] = useState(true);
  const [healthStatusRefetchInterval, setHealthStatusRefetchInterval] =
    useState(1000); // Polling every one second

  const { TRIAL_MODE_DEFAULT_LIMIT, setTrialMode, setTrialModeLimit } =
    useContext(AppConfigContext);

  // load a locale
  if (numeral.locales["user-locale"] === undefined) {
    numeral.register("locale", "user-locale", {
      delimiters: {
        thousands: ",",
        decimal: ".",
      },
      abbreviations: {
        thousand: "K",
        million: "M",
        billion: "b",
        trillion: "t",
      },
      ordinal: function (number) {
        return number === 1 ? "er" : "ème";
      },
      currency: {
        symbol: "$",
      },
    });
    // switch between locales
    numeral.locale("user-locale");
  }

  const logoutWithRedirect = () => {
    logout({
      // eslint-disable-next-line
      returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    });
  };

  let { path } = useRouteMatch();

  const {
    data: healthStatus,
    isLoading: isLoadingHealthStatus,
    isError: isErrorHealthStatus,
  } = useQueryWithAuthorization(["healthStatus"], getHealthStatus(), {
    refetchInterval: healthStatusRefetchInterval,
    staleTime: healthStatusRefetchInterval,
    refetchOnWindowFocus: true,
    cacheTime: 0,
    retry: 0,
    enabled: healthStatusEnabled,
  });

  useEffect(() => {
    if (healthStatus && healthStatus.ready == true) {
      setIsHealth(true);
      setHealthStatusEnabled(false);
      setHealthStatusRefetchInterval(0);
    }
  }, [healthStatus]);

  const {
    data: appConfig,
    isLoading: isLoadingAppConfig,
    isError: isErrorAppConfig,
  } = useQueryWithAuthorization(["appConfig", isHealth], getAppConfig(), {
    enabled: isHealth == true,
  });

  useDeepCompareEffect(() => {
    if (appConfig) {
      setTrialMode(appConfig.trialMode);
      setTrialModeLimit(appConfig.trialModeLimit || TRIAL_MODE_DEFAULT_LIMIT);
      setIsUnknownTrialMode(false);
      if (appConfig.trialMode == true) {
        setShowTrialModeAlertBox(true);
      }
    } else if (appConfig === null) {
      setIsUnknownTrialMode(true);
    }
  }, [appConfig]);

  return (
    <div>
      <LeftMenu />
      <div className={classes.main}>
        <header>
          <div className={classes.titleWrapper}>
            <img src={CSFEyeLogo} alt="sidebar icon" className={classes.logo} />
            <div className={classes.csfeyeTitle}>CSFEye</div>
          </div>
          {showTrialModeAlertBox && (
            <div className={classes.trialAlertBox}>
              <img
                src={BlueInfoLogo}
                className={classes.trialAlertBox__infoImg}
              />
              <div className={classes.trialAlertBox__text}>
                <span>Please note</span>
                <span>
                  The system is in trial mode, some functionality may be limited
                </span>
              </div>
              {/* <button className={classes.trialAlertBox__moreBtn} onClick={() => setShowTrialInfoBox(true)}>LEARN MORE</button> */}
              <button>
                <img
                  src={GrayCancelLogo}
                  onClick={() => setShowTrialModeAlertBox(false)}
                />
              </button>
            </div>
          )}
          <button className={classes.logout} onClick={logoutWithRedirect}>
            Logout
          </button>
        </header>
        {(isLoadingHealthStatus || isHealth == false || isLoadingAppConfig) && (
          <Page className={classes.loadingPage}>
            <Loading />
          </Page>
        )}
        {(isErrorHealthStatus || isErrorAppConfig || isUnknownTrialMode) && (
          <Page className={classes.loadingPage}>
            <ErrorMessage errorMessage="Sorry... an error occured." />
          </Page>
        )}
        {isUnknownTrialMode == false && (
          <Switch>
            <Route exact path={path} component={ExecutiveSummary}>
              <Redirect to="/home/dashboard" />
            </Route>
            <Route path={`${path}/dashboard`}>
              <ExecutiveSummary />
            </Route>
            <Route exact path={`${path}/accounts`}>
              <AccountInfo />
            </Route>
            <Route path={`${path}/accounts/:id`}>
              <SpecificAccount />
            </Route>
            <Route path={`${path}/action-tracking`}>
              <AccountInfo />
            </Route>
            <Route exact path={`${path}/health`}>
              {" "}
              <div style={{ color: "#FFFFFF" }}>App is healthy</div>
            </Route>
          </Switch>
        )}
      </div>
    </div>
  );
};

export default HomePage;
