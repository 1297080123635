import React from "react";
import {
  /*BrowserRouter as Router, Link,*/ useLocation,
} from "react-router-dom";

// Copied from https://v5.reactrouter.com/web/example/query-parameters

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
