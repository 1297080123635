import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

import dateFormat from "dateformat";

import Page from "../../UI/Page";
import PageHeader from "../../UI/PageHeader";
import PageBody from "../../UI/PageBody";

import SpecificAccountHeader from "./SpecificAccountHeader";
import SummaryBar from "./summary-bar/SummaryBar";
import TabsSection from "./tabs-section/TabsSection";
import AccountStatistics from "./account-statistics/AccountStatistics";

import classes from "./SpecificAccount.module.css";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import { getSummary } from "../../../external-apis";

import { AccountsContext } from "../../../contexts/accountsContext";

const SpecificAccount = (props) => {
  const history = useHistory();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { selectedAccount, setSelectedAccount, setSelectedAccountFromList } =
    useContext(AccountsContext);

  const id = props.match.params.id;

  const { data: summary } = useQueryWithAuthorization("summary", getSummary());

  useDeepCompareEffect(() => {
    if (id) {
      if (selectedAccount && id != selectedAccount.account_id) {
        setSelectedAccountFromList(false);
      }
      setSelectedAccount({ account_id: id });
    }
  }, [id]);

  useDeepCompareEffect(() => {
    if (summary && summary[0]) {
      setStartDate(dateFormat(summary[0].start_date.min, "mmm d, HH:MM"));
      setEndDate(dateFormat(summary[0].end_date.max, "mmm d, HH:MM"));
    }
  }, [summary]);

  const searchAccountID = (id) => {
    history.push("/home/accounts/" + id);
  };

  return (
    <Page>
      <PageHeader>
        <SpecificAccountHeader searchAccountIDHandler={searchAccountID} />
      </PageHeader>
      <PageBody>
        <div className={classes.bodyContainer}>
          <div className={classes.titleRow}>
            <div className={classes.title}>Account Usage Summary</div>
            {startDate && endDate && (
              <div className={classes.dateRange}>
                Data Range {startDate} - {endDate}
              </div>
            )}
          </div>
          <SummaryBar isFullScreenView={true} />
          <div className={classes.chartsContainer}>
            <TabsSection />
            <AccountStatistics />
          </div>
        </div>
      </PageBody>
    </Page>
  );
};

SpecificAccount.propTypes = {
  match: PropTypes.any,
};

export default withRouter(SpecificAccount);
