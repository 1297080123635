import { ClickAwayListener, Popper, Slider } from "@material-ui/core";

import DownArrowForMenuSvg from "../../../../assets/img/downArrowForMenu.svg";

import filterClasses from "./RangeFilter.module.scss";
import classes from "./NumOfRecordsSelector.module.scss";

import { useAlignedPopperWidth } from "./useAlignedPopperWidth";

import { AppConfigContext } from "../../../../contexts/appConfigContext";
import { useContext } from "react";

const marks = [
  {
    value: 1,
    label: 25,
  },
  {
    value: 2,
    label: 50,
  },
  {
    value: 3,
    label: 100,
  },
  {
    value: 4,
    label: 250,
  },
  {
    value: 5,
    label: 1000,
  },
  {
    value: 6,
    label: 5000,
  },
  {
    value: 7,
    label: 10000,
  },
];

function getSelectedSliderValue(recordCount: number): number {
  return marks.find((m) => m.label === recordCount).value;
}

export interface INumOfRecordsSelectorProps {
  selectedRecordCount: number;
  queryRecordCount: number; // Value used by last call to getAllAccounts
  onSelectionChange: (recordCount: number) => void;
}

const NumOfRecordsSelector = (props: INumOfRecordsSelectorProps) => {
  const { isTrialMode } = useContext(AppConfigContext);
  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth();

  const handleNumOfRecordsChange = (sliderValue) => {
    const selectedRecordCount = marks.find(
      (m) => m.value === sliderValue
    ).label;
    props.onSelectionChange(selectedRecordCount);
  };

  let sliderMarkLabelClass = classes.sliderMarkLabel1;
  const sliderValue = getSelectedSliderValue(props.queryRecordCount);
  if (sliderValue === 2) {
    sliderMarkLabelClass = classes.sliderMarkLabel2;
  } else if (sliderValue === 3) {
    sliderMarkLabelClass = classes.sliderMarkLabel3;
  } else if (sliderValue === 4) {
    sliderMarkLabelClass = classes.sliderMarkLabel4;
  } else if (sliderValue === 5) {
    sliderMarkLabelClass = classes.sliderMarkLabel5;
  } else if (sliderValue === 6) {
    sliderMarkLabelClass = classes.sliderMarkLabel6;
  } else if (sliderValue === 7) {
    sliderMarkLabelClass = classes.sliderMarkLabel7;
  }

  return (
    <div
      style={{ height: 25 }}
      className={filterClasses.container}
      ref={elementAlignedToRef}
    >
      <button
        className={filterClasses.toggleRangePopperBtn}
        type="button"
        onClick={toggleIsPopperOpenClick}
      >
        # of Records: {props.selectedRecordCount} ({props.queryRecordCount})
        <img
          style={{ top: 10 }}
          className={filterClasses.arrowDownSvg}
          src={DownArrowForMenuSvg}
        />
      </button>
      <Popper open={isPopperOpen} anchorEl={elementAlignedToRef.current}>
        <ClickAwayListener onClickAway={toggleIsPopperOpenClick}>
          <div
            style={{
              width: popperWidth,
              background: "#25293D",
              border: "1px solid #9AA0AF",
            }}
          >
            <div className={filterClasses.popperContentContainer}>
              <div></div>

              <div style={{ marginLeft: 5, marginRight: 5 }}>
                <Slider
                  classes={{
                    markLabel: sliderMarkLabelClass,
                    marked: classes.sliderMarksContainer,
                  }}
                  valueLabelDisplay="off"
                  step={null}
                  marks={marks}
                  min={1}
                  max={7}
                  value={getSelectedSliderValue(props.selectedRecordCount)}
                  onChange={(evt, newValue) =>
                    handleNumOfRecordsChange(newValue)
                  }
                  disabled={isTrialMode}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default NumOfRecordsSelector;
