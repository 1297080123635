import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import configData from "../../../config.js";

import classes from "./HorizontalBarsChart.module.css";

import { useDeepCompareEffect } from "../../../custom-hooks";

const HorizontalBarsChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create("horizontalBarsChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    // chart.responsive.enabled = true;

    chart.paddingRight = 12;
    chart.paddingTop = 0;
    chart.paddingBottom = 12;
    chart.paddingLeft = 5;

    chart.numberFormatter.numberFormat = "#.a";

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.baseGrid.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 1;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    // valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = chart.data.sharer + chart.data.shadow;
    valueAxis.strictMinMax = true;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 12;
    valueAxis.fontWeight = "bold";
    valueAxis.renderer.labels.template.fill = am4core.color("#F0F0F0");
    valueAxis.renderer.labels.template.dy = 15;
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.renderer.grid.template.location = 1;

    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.dy = 10;
    valueAxis.renderer.ticks.template.strokeOpacity = 1;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#F0F0F0");
    valueAxis.renderer.ticks.template.strokeWidth = 2;
    valueAxis.renderer.ticks.template.length = 10;

    // Create series
    const createSeries = (field, name, color) => {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.stacked = true;
      series.name = name;

      series.columns.template.fill = color;
      series.columns.template.strokeWidth = 0;

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = name;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.fontWeight = "bold";

      // series.columns.template.events.on("hit", function (ev) {
      //     console.log(ev.target.dataItem.component.name);
      // }, this);
      series.columns.template.tooltipText = "[bold]{name}: {valueX}[/]";
      series.columns.template.tooltip = new am4core.Tooltip();
      // series.columns.template.tooltip.label.fill = am4core.color("#fff");
      series.columns.template.tooltip.getFillFromObject = false;
      series.columns.template.tooltip.label.fill = color;
      series.columns.template.tooltip.background.stroke = color;
      series.columns.template.tooltip.background.fill =
        am4core.color("#25293D");
      // series.columns.template.tooltip.background.opacity = 0.5;

      // series.columns.template.tooltip.pointerOrientation = "right";
    };

    createSeries("sharer", "Sharer Accounts", am4core.color("#3380FF"));
    createSeries("shadow", "Borrower Accounts", am4core.color("#4BCAD4"));

    // var series = chart.series.push(new am4charts.ColumnSeries());
    // series.dataFields.valueX = field;
    // series.dataFields.categoryY = "category";
    // series.stacked = true;
    // // series.fill = am4core.color("#C4C4C4");
    // series.strokeWidth = 0;

    // series.columns.template.adapter.add("fill", function (fill, target) {
    //     if (!target.dataItem) {
    //         return fill;
    //     }
    //     return target.dataItem.categories.categoryY === "Sharer Accounts" ? am4core.color("#8F8F8F") : am4core.color("#C2C2C2");
    // });

    // var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
    // categoryLabel.label.text = "{category}";
    // categoryLabel.label.fontFamily = 'Source Sans Pro, sans-serif';
    // categoryLabel.label.fontSize = 12;
    // categoryLabel.label.fontWeight = 'normal';
    // categoryLabel.label.horizontalCenter = "left";
    // categoryLabel.label.dx = 10;
    // categoryLabel.locationX = 1;
    // categoryLabel.label.fill = am4core.color("#000000");

    chart.maskBullets = false;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    // Add data
    chartRef.current.data = [{ category: "category", ...props.data }];
  }, [props.data]);

  return <div id="horizontalBarsChart" className={classes.chart}></div>;
};

HorizontalBarsChart.propTypes = {
  data: PropTypes.any,
};

export default HorizontalBarsChart;
