import { useAlignedPopperWidth } from "./useAlignedPopperWidth";

import filterClasses from "./RangeFilter.module.scss";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import DownArrowForMenuSvg from "../../../../assets/img/downArrowForMenu.svg";
import {
  ACCOUNT_FIELDNAMES,
  ACCOUNT_LABELNAMES,
} from "../../../../services/accountInterfaces";
import { IOrderBy } from "../AccountInfo";

export interface IOrderBySelectorProps {
  selectedOrderBy: IOrderBy;
  queryOrderBy: IOrderBy;
  onOrderByChange: (val: IOrderBy) => void;
}

const OrderBySelector = (props: IOrderBySelectorProps) => {
  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth();

  const handleSelectedFieldChange = (event) => {
    const fieldName: ACCOUNT_FIELDNAMES = event.target.value;
    props.onOrderByChange({ fieldName, isAsc: props.selectedOrderBy.isAsc });
  };

  const handleAscendingChange = (evt) => {
    const isChecked = evt.target.checked;
    props.onOrderByChange({
      fieldName: props.selectedOrderBy.fieldName,
      isAsc: isChecked,
    });
  };

  const isAscendingToText = (val: boolean): string => {
    return val ? "asc" : "dsc";
  };

  const accountFieldNameToLabel = (
    fieldName: ACCOUNT_FIELDNAMES
  ): ACCOUNT_LABELNAMES => {
    if (fieldName === ACCOUNT_FIELDNAMES.SCORE) return ACCOUNT_LABELNAMES.SCORE;
    if (fieldName === ACCOUNT_FIELDNAMES.DURATION)
      return ACCOUNT_LABELNAMES.DURATION;
    return ACCOUNT_LABELNAMES.HOUSEHOLDS;
  };

  return (
    <div
      style={{ height: 25 }}
      className={`${filterClasses.container} ${filterClasses.containerSmall}`}
      ref={elementAlignedToRef}
    >
      <button
        className={filterClasses.toggleRangePopperBtn}
        type="button"
        onClick={toggleIsPopperOpenClick}
      >
        Order by: {accountFieldNameToLabel(props.selectedOrderBy.fieldName)},{" "}
        {isAscendingToText(props.selectedOrderBy.isAsc)}
        <img className={filterClasses.arrowDownSvg} src={DownArrowForMenuSvg} />
      </button>
      <Popper open={isPopperOpen} anchorEl={elementAlignedToRef.current}>
        <ClickAwayListener onClickAway={toggleIsPopperOpenClick}>
          <div
            style={{
              width: popperWidth,
              background: "#25293D",
              border: "1px solid #9AA0AF",
            }}
          >
            <div className={filterClasses.popperContentContainer}>
              <div style={{ marginLeft: 5, marginRight: 5 }}>
                <FormControl classes={{ root: filterClasses.filterLabelColor }}>
                  <div
                    className={filterClasses.filterLabelColor}
                    id="orderBy-label"
                  >
                    Query: Order by{" "}
                    {accountFieldNameToLabel(props.queryOrderBy.fieldName)},{" "}
                    {isAscendingToText(props.queryOrderBy.isAsc)}
                  </div>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={props.selectedOrderBy.fieldName}
                    onChange={handleSelectedFieldChange}
                  >
                    <FormControlLabel
                      classes={{ root: filterClasses.filterLabelColor }}
                      value={ACCOUNT_FIELDNAMES.SCORE}
                      control={<Radio />}
                      label={ACCOUNT_LABELNAMES.SCORE}
                    />
                    <FormControlLabel
                      classes={{ root: filterClasses.filterLabelColor }}
                      value={ACCOUNT_FIELDNAMES.DURATION}
                      control={<Radio />}
                      label={ACCOUNT_LABELNAMES.DURATION}
                    />
                    <FormControlLabel
                      classes={{ root: filterClasses.filterLabelColor }}
                      value={ACCOUNT_FIELDNAMES.HOUSEHOLDS}
                      control={<Radio />}
                      label={ACCOUNT_LABELNAMES.HOUSEHOLDS}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                <FormControlLabel
                  classes={{ root: filterClasses.filterLabelColor }}
                  control={<Checkbox />}
                  label="Ascending"
                  checked={props.selectedOrderBy.isAsc}
                  onChange={(evt) => {
                    handleAscendingChange(evt);
                  }}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default OrderBySelector;
