export const countriesData = [
  {
    id: "TV",
    north: -8.4653,
    east: 179.2312,
    south: -8.554,
    west: 179.2023,
  },
  {
    id: "BV",
    north: -54.3997,
    east: 3.4814,
    south: -54.4511,
    west: 3.3461,
  },
  {
    id: "GI",
    north: 36.1623,
    east: -5.3345,
    south: 36.1122,
    west: -5.3562,
  },
  {
    id: "GO",
    north: -11.5543,
    east: 47.3029,
    south: -11.5752,
    west: 47.2789,
  },
  {
    id: "JU",
    north: -17.0519,
    east: 42.761,
    south: -17.075,
    west: 42.7379,
  },
  {
    id: "UM-DQ",
    north: -0.3744,
    east: -160.0178,
    south: -0.398,
    west: -160.0453,
  },
  {
    id: "UM-FQ",
    north: 0.2226,
    east: -176.456,
    south: 0.215,
    west: -176.4675,
  },
  {
    id: "UM-HQ",
    north: 0.8085,
    east: -176.6311,
    south: 0.7934,
    west: -176.6433,
  },
  {
    id: "UM-JQ",
    north: 16.7305,
    east: -169.5237,
    south: 16.7243,
    west: -169.5388,
  },
  {
    id: "UM-MQ",
    north: 28.2144,
    east: -177.3646,
    south: 28.1865,
    west: -177.3881,
  },
  {
    id: "UM-WQ",
    north: 19.3246,
    east: 166.6588,
    south: 19.2829,
    west: 166.6087,
  },
  {
    id: "BQ",
    north: 12.3018,
    east: -68.2058,
    south: 12.1446,
    west: -68.3711,
  },
  {
    id: "NL",
    north: 53.4413,
    east: 7.1973,
    south: 50.7504,
    west: 3.35,
  },
  {
    id: "ZW",
    north: -15.643,
    east: 33.0069,
    south: -22.4021,
    west: 25.2242,
  },
  {
    id: "ZM",
    north: -8.1936,
    east: 33.659,
    south: -17.9583,
    west: 21.9789,
  },
  {
    id: "ZA",
    north: -22.1465,
    east: 32.8862,
    south: -34.7858,
    west: 16.4475,
  },
  {
    id: "YE",
    north: 18.9961,
    east: 53.0855,
    south: 12.6165,
    west: 42.7171,
  },
  {
    id: "WS",
    north: -13.4653,
    east: -172.225,
    south: -13.8044,
    west: -172.7441,
  },
  {
    id: "WF",
    north: -14.2425,
    east: -178.0467,
    south: -14.3184,
    west: -178.1585,
  },
  {
    id: "PS",
    north: 32.5344,
    east: 35.5589,
    south: 31.2085,
    west: 34.1983,
  },
  {
    id: "VU",
    north: -14.8267,
    east: 167.8365,
    south: -16.5552,
    west: 166.5274,
  },
  {
    id: "VN",
    north: 23.3454,
    east: 109.4238,
    south: 8.5833,
    west: 102.1276,
  },
  {
    id: "VI",
    north: 17.7945,
    east: -64.6862,
    south: 17.7017,
    west: -64.889,
  },
  {
    id: "VG",
    north: 18.5176,
    east: -64.3246,
    south: 18.4648,
    west: -64.4261,
  },
  {
    id: "VE",
    north: 12.1779,
    east: -59.8315,
    south: 0.7854,
    west: -73.3563,
  },
  {
    id: "VC",
    north: 13.356,
    east: -61.1239,
    south: 13.1581,
    west: -61.2773,
  },
  {
    id: "VA",
    north: 41.9063,
    east: 12.4392,
    south: 41.8974,
    west: 12.4307,
  },
  {
    id: "UZ",
    north: 45.5555,
    east: 73.113,
    south: 37.1722,
    west: 55.9757,
  },
  {
    id: "US",
    north: 71.319,
    east: -67.1067,
    south: 19.0121,
    west: -171.4631,
  },
  {
    id: "UY",
    north: -30.1072,
    east: -53.1254,
    south: -34.9328,
    west: -58.4002,
  },
  {
    id: "UA",
    north: 52.3448,
    east: 40.0701,
    south: 44.3876,
    west: 22.132,
  },
  {
    id: "UG",
    north: 4.2203,
    east: 34.9654,
    south: -1.3878,
    west: 29.5619,
  },
  {
    id: "TZ",
    north: -1.0021,
    east: 40.4634,
    south: -11.7163,
    west: 29.3426,
  },
  {
    id: "TW",
    north: 25.2754,
    east: 121.9291,
    south: 22.3564,
    west: 120.0723,
  },
  {
    id: "TR",
    north: 42.0794,
    east: 44.8171,
    south: 35.8316,
    west: 26.0391,
  },
  {
    id: "TN",
    north: 37.3404,
    east: 11.5048,
    south: 30.2293,
    west: 7.5,
  },
  {
    id: "TT",
    north: 10.67,
    east: -61.0121,
    south: 10.0646,
    west: -61.5967,
  },
  {
    id: "TO",
    north: -21.1188,
    east: -175.1567,
    south: -21.2636,
    west: -175.3354,
  },
  {
    id: "TL",
    north: -8.4595,
    east: 126.9149,
    south: -9.512,
    west: 124.9223,
  },
  {
    id: "TM",
    north: 42.7785,
    east: 66.5745,
    south: 35.2333,
    west: 52.4938,
  },
  {
    id: "TK",
    north: -8.5478,
    east: -172.4791,
    south: -8.5829,
    west: -172.4987,
  },
  {
    id: "TJ",
    north: 41.0234,
    east: 74.8912,
    south: 36.6941,
    west: 67.3495,
  },
  {
    id: "TH",
    north: 20.4246,
    east: 105.6223,
    south: 5.7894,
    west: 97.4849,
  },
  {
    id: "TG",
    north: 11.1157,
    east: 1.6224,
    south: 6.0895,
    west: -0.0686,
  },
  {
    id: "TD",
    north: 23.4452,
    east: 23.9835,
    south: 7.4753,
    west: 13.4481,
  },
  {
    id: "TC",
    north: 21.8521,
    east: -71.6615,
    south: 21.7651,
    west: -71.8304,
  },
  {
    id: "SY",
    north: 37.2765,
    east: 42.3588,
    south: 32.3173,
    west: 35.7644,
  },
  {
    id: "SC",
    north: -4.5586,
    east: 55.5402,
    south: -4.6931,
    west: 55.3835,
  },
  {
    id: "SX",
    north: 18.068808,
    east: -63.0111,
    south: 18.0191,
    west: -63.123,
  },
  {
    id: "SZ",
    north: -25.7464,
    east: 32.1129,
    south: -27.3058,
    west: 30.7941,
  },
  {
    id: "SE",
    north: 69.037,
    east: 24.1557,
    south: 55.3464,
    west: 11.147,
  },
  {
    id: "SI",
    north: 46.8632,
    east: 16.5163,
    south: 45.4286,
    west: 13.3993,
  },
  {
    id: "SK",
    north: 49.5976,
    east: 22.5386,
    south: 47.7701,
    west: 16.9531,
  },
  {
    id: "SR",
    north: 5.9927,
    east: -53.9904,
    south: 1.8424,
    west: -58.0544,
  },
  {
    id: "ST",
    north: 0.4041,
    east: 6.7498,
    south: 0.1205,
    west: 6.5243,
  },
  {
    id: "RS",
    north: 46.1552,
    east: 22.9767,
    south: 42.2476,
    west: 18.9054,
  },
  {
    id: "PM",
    north: 47.071,
    east: -56.2671,
    south: 46.8384,
    west: -56.3869,
  },
  {
    id: "SO",
    north: 11.9835,
    east: 51.2549,
    south: -1.6955,
    west: 40.9646,
  },
  {
    id: "SM",
    north: 43.9824,
    east: 12.5146,
    south: 43.9016,
    west: 12.397,
  },
  {
    id: "SV",
    north: 14.416,
    east: -87.7153,
    south: 13.1807,
    west: -90.0952,
  },
  {
    id: "SL",
    north: 9.9931,
    east: -10.2832,
    south: 6.9067,
    west: -13.2928,
  },
  {
    id: "SB",
    north: -7.9037,
    east: 162.1236,
    south: -10.8245,
    west: 158.944,
  },
  {
    id: "SH",
    north: -15.9062,
    east: -5.6923,
    south: -15.9977,
    west: -5.775,
  },
  {
    id: "GS",
    north: -54.0658,
    east: -35.7986,
    south: -54.8666,
    west: -37.1034,
  },
  {
    id: "SG",
    north: 1.4473,
    east: 103.9697,
    south: 1.2653,
    west: 103.6501,
  },
  {
    id: "SN",
    north: 16.6448,
    east: -11.3894,
    south: 12.328,
    west: -17.168,
  },
  {
    id: "SS",
    north: 12.2174,
    east: 35.2681,
    south: 3.4905,
    west: 24.1473,
  },
  {
    id: "SD",
    north: 22.00238,
    east: 38.6093,
    south: 8.6654,
    west: 21.8253,
  },
  {
    id: "SA",
    north: 32.1247,
    east: 55.641,
    south: 16.3718,
    west: 34.616,
  },
  {
    id: "EH",
    north: 27.672443,
    east: -8.6821,
    south: 20.8063,
    west: -17.0481,
  },
  {
    id: "RW",
    north: -1.0674,
    east: 30.8287,
    south: -2.8087,
    west: 28.8765,
  },
  {
    id: "RU",
    north: 81.2804,
    east: 180,
    south: 41.2129,
    west: -180,
  },
  {
    id: "RO",
    north: 48.2597,
    east: 29.7061,
    south: 43.6708,
    west: 20.2419,
  },
  {
    id: "RE",
    north: -20.904,
    east: 55.7972,
    south: -21.3395,
    west: 55.3112,
  },
  {
    id: "QA",
    north: 26.1534,
    east: 51.602,
    south: 24.6074,
    west: 50.763,
  },
  {
    id: "PF",
    north: -17.5224,
    east: -149.3215,
    south: -17.7351,
    west: -149.6114,
  },
  {
    id: "PY",
    north: -19.286,
    east: -54.2418,
    south: -27.5375,
    west: -62.6509,
  },
  {
    id: "PT",
    north: 42.1336,
    east: -6.2125,
    south: 37.0057,
    west: -9.4742,
  },
  {
    id: "KP",
    north: 42.9983,
    east: 130.6871,
    south: 37.7301,
    west: 124.3621,
  },
  {
    id: "PR",
    north: 18.4994,
    east: -65.6288,
    south: 17.9743,
    west: -67.1968,
  },
  {
    id: "PL",
    north: 54.8382,
    east: 24.1056,
    south: 49.0727,
    west: 14.1286,
  },
  {
    id: "PG",
    north: -2.6099,
    east: 155.9575,
    south: -10.6368,
    west: 140.9734,
  },
  {
    id: "PW",
    north: 7.6631,
    east: 134.6595,
    south: 7.3821,
    west: 134.5064,
  },
  {
    id: "PH",
    north: 18.6153,
    east: 126.5816,
    south: 5.6643,
    west: 117.3109,
  },
  {
    id: "PE",
    north: -0.1063,
    east: -68.6852,
    south: -18.3454,
    west: -81.3366,
  },
  {
    id: "PN",
    north: -24.3335,
    east: -128.2901,
    south: -24.3997,
    west: -128.3503,
  },
  {
    id: "PA",
    north: 9.598,
    east: -77.2124,
    south: 7.2201,
    west: -82.9976,
  },
  {
    id: "PK",
    north: 37.0221,
    east: 77.7992,
    south: 23.811,
    west: 60.8434,
  },
  {
    id: "OM",
    north: 24.9793,
    east: 59.7998,
    south: 16.6484,
    west: 51.9775,
  },
  {
    id: "NZ",
    north: -34.935,
    east: 178.536,
    south: -46.6054,
    west: 166.7316,
  },
  {
    id: "SJ",
    north: 80.4022,
    east: 27.1986,
    south: 76.5794,
    west: 10.866,
  },
  {
    id: "NR",
    north: -0.4992,
    east: 166.9584,
    south: -0.5467,
    west: 166.9136,
  },
  {
    id: "NP",
    north: 30.3376,
    east: 88.1614,
    south: 26.3603,
    west: 80.0706,
  },
  {
    id: "NO",
    north: 71.0913,
    east: 30.9442,
    south: 58.0242,
    west: 4.9276,
  },
  {
    id: "NU",
    north: -18.966,
    east: -169.8034,
    south: -19.1378,
    west: -169.9483,
  },
  {
    id: "NI",
    north: 14.9931,
    east: -83.1576,
    south: 10.7806,
    west: -87.6676,
  },
  {
    id: "NG",
    north: 13.8727,
    east: 14.6271,
    south: 4.2904,
    west: 2.686,
  },
  {
    id: "NF",
    north: -29.0175,
    east: 167.979,
    south: -29.0827,
    west: 167.9204,
  },
  {
    id: "NE",
    north: 23.518,
    east: 15.949,
    south: 11.6963,
    west: 0.1638,
  },
  {
    id: "NC",
    north: -20.2462,
    east: 166.9425,
    south: -22.3759,
    west: 164.1699,
  },
  {
    id: "NA",
    north: -16.9676,
    east: 25.2588,
    south: -28.9016,
    west: 11.7432,
  },
  {
    id: "YT",
    north: -12.7093,
    east: 45.2233,
    south: -12.9765,
    west: 45.0692,
  },
  {
    id: "MY",
    north: 6.6768,
    east: 119.2236,
    south: 0.8618,
    west: 100.119,
  },
  {
    id: "MW",
    north: -9.4072,
    east: 35.8474,
    south: -17.0967,
    west: 32.6722,
  },
  {
    id: "MU",
    north: -19.9971,
    east: 57.7921,
    south: -20.485,
    west: 57.3859,
  },
  {
    id: "MQ",
    north: 14.8044,
    east: -60.8263,
    south: 14.4671,
    west: -61.2198,
  },
  {
    id: "MS",
    north: 16.7518,
    east: -62.1484,
    south: 16.6812,
    west: -62.223,
  },
  {
    id: "MR",
    north: 27.2858,
    east: -4.8227,
    south: 14.8089,
    west: -17.0481,
  },
  {
    id: "MZ",
    north: -10.4641,
    east: 40.835,
    south: -26.8495,
    west: 30.2317,
  },
  {
    id: "MP",
    north: 15.2155,
    east: 145.7821,
    south: 15.1249,
    west: 145.6845,
  },
  {
    id: "MN",
    north: 52.0701,
    east: 119.8979,
    south: 41.6586,
    west: 87.8143,
  },
  {
    id: "ME",
    north: 43.5331,
    east: 20.3445,
    south: 41.869,
    west: 18.4362,
  },
  {
    id: "MM",
    north: 28.5101,
    east: 101.139,
    south: 10.1902,
    west: 92.1795,
  },
  {
    id: "MT",
    north: 35.9572,
    east: 14.5663,
    south: 35.8219,
    west: 14.3524,
  },
  {
    id: "ML",
    north: 24.997768,
    east: 4.2347,
    south: 10.1627,
    west: -12.2807,
  },
  {
    id: "MK",
    north: 42.3138,
    east: 23.0056,
    south: 40.8498,
    west: 20.4892,
  },
  {
    id: "MH",
    north: 7.1384,
    east: 171.2269,
    south: 7.0736,
    west: 171.0955,
  },
  {
    id: "MX",
    north: 32.7155,
    east: -86.8242,
    south: 14.5452,
    west: -117.1282,
  },
  {
    id: "MV",
    north: 4.2434,
    east: 73.5285,
    south: 4.1644,
    west: 73.473,
  },
  {
    id: "MG",
    north: -12.0794,
    east: 50.4829,
    south: -25.5631,
    west: 43.2666,
  },
  {
    id: "MD",
    north: 48.4777,
    east: 29.8779,
    south: 45.4503,
    west: 26.6189,
  },
  {
    id: "MC",
    north: 43.7711,
    east: 7.4387,
    south: 43.7316,
    west: 7.3779,
  },
  {
    id: "MA",
    north: 35.9297,
    east: -1.0656,
    south: 27.652,
    west: -13.1774,
  },
  {
    id: "MF",
    north: 18.1154,
    east: -63.0111,
    south: 18.0688,
    west: -63.123,
  },
  {
    id: "MO",
    north: 22.2414,
    east: 113.5467,
    south: 22.1957,
    west: 113.4788,
  },
  {
    id: "LV",
    north: 58.0635,
    east: 28.1481,
    south: 55.6676,
    west: 21.0462,
  },
  {
    id: "LU",
    north: 50.1209,
    east: 6.4875,
    south: 49.4527,
    west: 5.744,
  },
  {
    id: "LT",
    north: 56.4067,
    east: 26.5936,
    south: 53.9398,
    west: 21.0462,
  },
  {
    id: "LS",
    north: -28.5816,
    east: 29.3905,
    south: -30.6421,
    west: 27.0517,
  },
  {
    id: "LK",
    north: 9.8129,
    east: 81.8741,
    south: 5.979,
    west: 79.7923,
  },
  {
    id: "LI",
    north: 47.2707,
    east: 9.5801,
    south: 47.0572,
    west: 9.4877,
  },
  {
    id: "LC",
    north: 14.0112,
    east: -60.8867,
    south: 13.8221,
    west: -61.0636,
  },
  {
    id: "LY",
    north: 33.182,
    east: 25.1505,
    south: 19.4968,
    west: 9.3102,
  },
  {
    id: "LR",
    north: 8.5345,
    east: -7.4299,
    south: 4.3512,
    west: -11.5075,
  },
  {
    id: "LB",
    north: 34.6291,
    east: 36.5851,
    south: 33.0755,
    west: 35.1087,
  },
  {
    id: "LA",
    north: 22.4625,
    east: 107.6531,
    south: 13.9247,
    west: 100.1225,
  },
  {
    id: "KW",
    north: 30.0956,
    east: 48.4424,
    south: 28.5331,
    west: 46.5314,
  },
  {
    id: "XK",
    north: 43.0915,
    east: 21.7529,
    south: 41.8739,
    west: 20.0639,
  },
  {
    id: "KR",
    north: 38.6233,
    east: 129.5619,
    south: 34.4937,
    west: 126.2171,
  },
  {
    id: "KN",
    north: 17.4025,
    east: -62.6307,
    south: 17.2401,
    west: -62.8389,
  },
  {
    id: "KI",
    north: 1.8557,
    east: -157.2461,
    south: 1.7318,
    west: -157.4201,
  },
  {
    id: "KH",
    north: 14.705,
    east: 107.6056,
    south: 10.4112,
    west: 102.3198,
  },
  {
    id: "KG",
    north: 43.2402,
    east: 80.2162,
    south: 39.2758,
    west: 69.229,
  },
  {
    id: "KE",
    north: 5.4925,
    east: 41.8839,
    south: -4.6922,
    west: 33.9031,
  },
  {
    id: "KZ",
    north: 55.3895,
    east: 87.3229,
    south: 40.6563,
    west: 46.661,
  },
  {
    id: "JP",
    north: 45.5094,
    east: 145.3476,
    south: 31.1783,
    west: 129.5801,
  },
  {
    id: "JO",
    north: 33.372,
    east: 39.1455,
    south: 29.1906,
    west: 34.9507,
  },
  {
    id: "JE",
    north: 49.2665,
    east: -2.0186,
    south: 49.1763,
    west: -2.2359,
  },
  {
    id: "JM",
    north: 18.522,
    east: -76.3499,
    south: 17.8336,
    west: -78.2167,
  },
  {
    id: "IT",
    north: 46.9755,
    east: 18.4859,
    south: 36.6879,
    west: 6.6277,
  },
  {
    id: "IL",
    north: 33.4319,
    east: 35.8691,
    south: 29.4773,
    west: 34.2454,
  },
  {
    id: "IS",
    north: 66.5145,
    east: -13.6159,
    south: 63.4066,
    west: -24.2241,
  },
  {
    id: "IQ",
    north: 37.3719,
    east: 48.5467,
    south: 29.0636,
    west: 38.7735,
  },
  {
    id: "IR",
    north: 39.6842,
    east: 63.1681,
    south: 25.1023,
    west: 44.023,
  },
  {
    id: "IE",
    north: 55.3531,
    east: -6.0274,
    south: 51.5849,
    west: -10.3786,
  },
  {
    id: "IO",
    north: -7.2304,
    east: 72.4938,
    south: -7.3773,
    west: 72.4356,
  },
  {
    id: "IN",
    north: 35.496,
    east: 97.3353,
    south: 8.0782,
    west: 68.1649,
  },
  {
    id: "IM",
    north: 54.3768,
    east: -4.4121,
    south: 54.1189,
    west: -4.7457,
  },
  {
    id: "ID",
    north: 5.6092,
    east: 140.9761,
    south: -10.3438,
    west: 95.2068,
  },
  {
    id: "HU",
    north: 48.5522,
    east: 22.8764,
    south: 45.7704,
    west: 16.0929,
  },
  {
    id: "HT",
    north: 19.9039,
    east: -71.6571,
    south: 18.0391,
    west: -74.419,
  },
  {
    id: "HR",
    north: 46.4997,
    east: 19.0549,
    south: 42.4327,
    west: 13.5778,
  },
  {
    id: "HN",
    north: 16.003,
    east: -83.1576,
    south: 12.9792,
    west: -89.3626,
  },
  {
    id: "HM",
    north: -53.0271,
    east: 73.7074,
    south: -53.1843,
    west: 73.3363,
  },
  {
    id: "HK",
    north: 22.541,
    east: 114.268,
    south: 22.2956,
    west: 114.0154,
  },
  {
    id: "GY",
    north: 8.5491,
    east: -56.4828,
    south: 1.2036,
    west: -61.3767,
  },
  {
    id: "GU",
    north: 13.5269,
    east: 144.7905,
    south: 13.2593,
    west: 144.6493,
  },
  {
    id: "GF",
    north: 5.7823,
    east: -51.6526,
    south: 2.1211,
    west: -54.6163,
  },
  {
    id: "GT",
    north: 17.817781,
    east: -88.2284,
    south: 13.7364,
    west: -92.2352,
  },
  {
    id: "GL",
    north: 83.5995,
    east: -11.5288,
    south: 59.9247,
    west: -72.8181,
  },
  {
    id: "GD",
    north: 12.2232,
    east: -61.6071,
    south: 12.0128,
    west: -61.7154,
  },
  {
    id: "GR",
    north: 41.7167,
    east: 26.6246,
    south: 34.9345,
    west: 20.0013,
  },
  {
    id: "GQ",
    north: 2.3044,
    east: 11.3352,
    south: 0.9998,
    west: 9.3861,
  },
  {
    id: "GW",
    north: 12.68,
    east: -13.7294,
    south: 10.9404,
    west: -16.7119,
  },
  {
    id: "GM",
    north: 13.587494,
    east: -15.5096,
    south: 13.064,
    west: -16.8248,
  },
  {
    id: "GP",
    north: 16.4682,
    east: -61.3272,
    south: 16.228,
    west: -61.529,
  },
  {
    id: "GN",
    north: 12.6738,
    east: -7.8236,
    south: 7.2157,
    west: -15.0431,
  },
  {
    id: "GH",
    north: 11.1157,
    east: 1.1874,
    south: 4.7623,
    west: -3.2358,
  },
  {
    id: "GG",
    north: 49.4946,
    east: -2.5124,
    south: 49.4287,
    west: -2.646,
  },
  {
    id: "GE",
    north: 43.534,
    east: 46.6189,
    south: 41.07,
    west: 39.9782,
  },
  {
    id: "GA",
    north: 2.2996,
    east: 14.4806,
    south: -3.9163,
    west: 8.8215,
  },
  {
    id: "FR",
    north: 51.0971,
    east: 9.5508,
    south: 41.3847,
    west: -4.7208,
  },
  {
    id: "FM",
    north: 6.9511,
    east: 158.315,
    south: 6.8011,
    west: 158.1348,
  },
  {
    id: "FO",
    north: 62.2856,
    east: -6.6311,
    south: 62.094,
    west: -7.1722,
  },
  {
    id: "FK",
    north: -51.2698,
    east: -57.8382,
    south: -52.1731,
    west: -60.2863,
  },
  {
    id: "FJ",
    north: -16.1686,
    east: 179.9991,
    south: -18.2549,
    west: 177.2638,
  },
  {
    id: "FI",
    north: 70.0617,
    east: 31.5341,
    south: 59.9656,
    west: 20.6223,
  },
  {
    id: "ET",
    north: 14.8524,
    east: 47.9781,
    south: 3.4559,
    west: 33.0127,
  },
  {
    id: "EE",
    north: 59.6389,
    east: 28.0127,
    south: 57.5282,
    west: 22.0814,
  },
  {
    id: "ES",
    north: 43.7644,
    east: 3.4619,
    south: 28.0071,
    west: -16.9054,
  },
  {
    id: "ER",
    north: 18.0049,
    east: 43.1166,
    south: 12.3764,
    west: 36.4267,
  },
  {
    id: "GB",
    north: 58.515956,
    east: 1.6566,
    south: 50.2293,
    west: -8.1183,
  },
  {
    id: "EG",
    north: 31.655,
    east: 36.8714,
    south: 21.9951,
    west: 24.7115,
  },
  {
    id: "EC",
    north: 1.4553,
    east: -75.2632,
    south: -4.9905,
    west: -91.3692,
  },
  {
    id: "DZ",
    north: 37.0856,
    east: 11.9678,
    south: 18.9886,
    west: -8.6834,
  },
  {
    id: "DO",
    north: 19.9141,
    east: -68.3594,
    south: 17.6462,
    west: -71.7791,
  },
  {
    id: "DK",
    north: 57.581,
    east: 12.5688,
    south: 54.8255,
    west: 8.1321,
  },
  {
    id: "DM",
    north: 15.5853,
    east: -61.2817,
    south: 15.2492,
    west: -61.481,
  },
  {
    id: "DJ",
    north: 12.7084,
    east: 43.41,
    south: 10.9803,
    west: 41.7667,
  },
  {
    id: "DE",
    north: 54.9034,
    east: 15.0164,
    south: 47.3133,
    west: 5.8949,
  },
  {
    id: "CZ",
    north: 50.8986,
    east: 18.8321,
    south: 48.5988,
    west: 12.2763,
  },
  {
    id: "CY",
    north: 35.5937,
    east: 34.4633,
    south: 34.5758,
    west: 32.3171,
  },
  {
    id: "KY",
    north: 19.349,
    east: -81.1306,
    south: 19.2718,
    west: -81.3696,
  },
  {
    id: "CX",
    north: -10.4308,
    east: 105.7253,
    south: -10.564,
    west: 105.5842,
  },
  {
    id: "CW",
    north: 12.3422,
    east: -68.7511,
    south: 12.0457,
    west: -69.154,
  },
  {
    id: "CU",
    north: 23.1905,
    east: -74.2529,
    south: 19.8555,
    west: -84.3612,
  },
  {
    id: "CR",
    north: 11.1845,
    east: -82.5635,
    south: 8.0706,
    west: -85.8305,
  },
  {
    id: "CV",
    north: 15.2714,
    east: -23.4442,
    south: 14.9611,
    west: -23.7054,
  },
  {
    id: "KM",
    north: -11.6142,
    east: 43.4659,
    south: -11.9014,
    west: 43.2266,
  },
  {
    id: "CO",
    north: 12.4199,
    east: -66.8761,
    south: -4.2359,
    west: -78.8597,
  },
  {
    id: "CK",
    north: -21.1885,
    east: -159.7406,
    south: -21.2494,
    west: -159.8395,
  },
  {
    id: "CG",
    north: 3.6845,
    east: 18.6102,
    south: -5.0042,
    west: 11.1301,
  },
  {
    id: "CD",
    north: 5.3123,
    east: 31.2527,
    south: -13.3929,
    west: 12.2137,
  },
  {
    id: "CM",
    north: 13.0786,
    east: 16.1834,
    south: 1.6763,
    west: 8.556,
  },
  {
    id: "CI",
    north: 10.7242,
    east: -2.5058,
    south: 4.3512,
    west: -8.6035,
  },
  {
    id: "CN",
    north: 53.5465,
    east: 134.7523,
    south: 18.2184,
    west: 73.6315,
  },
  {
    id: "CL",
    north: -17.506,
    east: -67.0088,
    south: -55.5214,
    west: -75.6567,
  },
  {
    id: "CH",
    north: 47.7661,
    east: 10.4528,
    south: 45.8298,
    west: 6.06,
  },
  {
    id: "CC",
    north: -12.126,
    east: 96.8404,
    south: -12.182,
    west: 96.8257,
  },
  {
    id: "CA",
    north: 83.1161,
    east: -52.6536,
    south: 41.675,
    west: -141.0021,
  },
  {
    id: "CF",
    north: 10.9963,
    east: 27.4032,
    south: 2.2703,
    west: 14.6169,
  },
  {
    id: "BE",
    north: 51.4746,
    east: 6.3645,
    south: 49.5383,
    west: 2.5249,
  },
  {
    id: "BW",
    north: -17.7936,
    east: 29.3648,
    south: -26.8544,
    west: 19.9774,
  },
  {
    id: "BT",
    north: 28.3112,
    east: 92.0836,
    south: 26.7016,
    west: 88.8579,
  },
  {
    id: "BN",
    north: 4.899902,
    east: 115.1398,
    south: 4.0241,
    west: 114.0638,
  },
  {
    id: "BB",
    north: 13.3179,
    east: -59.4276,
    south: 13.0822,
    west: -59.6114,
  },
  {
    id: "BR",
    north: 5.239,
    east: -34.8056,
    south: -33.7422,
    west: -74.0021,
  },
  {
    id: "BO",
    north: -9.7126,
    east: -57.5741,
    south: -22.822905,
    west: -69.6249,
  },
  {
    id: "BM",
    north: 32.387,
    east: -64.6946,
    south: 32.2596,
    west: -64.8202,
  },
  {
    id: "BZ",
    north: 18.4723,
    east: -88.0973,
    south: 15.8907,
    west: -89.2374,
  },
  {
    id: "BY",
    north: 56.143,
    east: 32.7042,
    south: 51.3825,
    west: 23.1969,
  },
  {
    id: "BL",
    north: 17.9184,
    east: -62.7996,
    south: 17.8766,
    west: -62.8655,
  },
  {
    id: "BS",
    north: 26.489,
    east: -77.0384,
    south: 24.2873,
    west: -78.2988,
  },
  {
    id: "BH",
    north: 26.1907,
    east: 50.6099,
    south: 25.8831,
    west: 50.4523,
  },
  {
    id: "BA",
    north: 45.2168,
    east: 19.4882,
    south: 42.5597,
    west: 15.7369,
  },
  {
    id: "BG",
    north: 44.2376,
    east: 28.5853,
    south: 41.2436,
    west: 22.3442,
  },
  {
    id: "BD",
    north: 26.3696,
    east: 92.5937,
    south: 20.7917,
    west: 88.0846,
  },
  {
    id: "BF",
    north: 15.0592,
    east: 2.389,
    south: 9.4813,
    west: -5.5236,
  },
  {
    id: "BJ",
    north: 12.3679,
    east: 3.8343,
    south: 6.2169,
    west: 0.7799,
  },
  {
    id: "BI",
    north: -2.3396,
    east: 30.7901,
    south: -4.456,
    west: 29.0146,
  },
  {
    id: "AZ",
    north: 41.8907,
    east: 49.7758,
    south: 38.4111,
    west: 44.7683,
  },
  {
    id: "AT",
    north: 48.9839,
    east: 17.1475,
    south: 46.4171,
    west: 9.5241,
  },
  {
    id: "AU",
    north: -10.7074,
    east: 153.6044,
    south: -43.6126,
    west: 113.4175,
  },
  {
    id: "TF",
    north: -49.1093,
    east: 69.9157,
    south: -49.6509,
    west: 68.8721,
  },
  {
    id: "AS",
    north: -14.2576,
    east: -170.6891,
    south: -14.3513,
    west: -170.8205,
  },
  {
    id: "AM",
    north: 41.291,
    east: 46.5501,
    south: 38.8776,
    west: 43.4393,
  },
  {
    id: "AR",
    north: -21.8353,
    east: -53.6712,
    south: -55.0322,
    west: -73.5762,
  },
  {
    id: "AE",
    north: 26.0624,
    east: 56.388,
    south: 22.6214,
    west: 51.5683,
  },
  {
    id: "AD",
    north: 42.5961,
    east: 1.7059,
    south: 42.4345,
    west: 1.4284,
  },
  {
    id: "AX",
    north: 60.3511,
    east: 20.2588,
    south: 60.099,
    west: 19.6871,
  },
  {
    id: "AL",
    north: 42.6285,
    east: 21.0312,
    south: 39.7095,
    west: 19.2809,
  },
  {
    id: "AI",
    north: 18.2699,
    east: -63.0012,
    south: 18.2002,
    west: -63.1534,
  },
  {
    id: "AO",
    north: -4.4454,
    east: 23.9915,
    south: -18.0005,
    west: 11.7432,
  },
  {
    id: "AF",
    north: 38.4564,
    east: 74.8912,
    south: 29.3921,
    west: 60.4856,
  },
  {
    id: "AG",
    north: 17.1691,
    east: -61.7161,
    south: 17.0133,
    west: -61.8597,
  },
  {
    id: "AW",
    north: 12.5677,
    east: -69.8992,
    south: 12.4385,
    west: -70.0661,
  },
];
