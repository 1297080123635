import React, { useContext, useState } from "react";
import { useRef, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from "../../../UI/Card";

import classes from "./ScoreTransition.module.scss";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../../custom-hooks";

import { getFilteredScoresTransition } from "../../../../external-apis";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import configData from "../../../../config.js";
import { IScoresTransition } from "../../../../services/accountInterfaces";

import { AccountsContext } from "../../../../contexts/accountsContext";

const ScoresTransition = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const { filtersProperties } = useContext(AccountsContext);

  const chartRef = useRef(null);

  const {
    data: filteredScoresTransition,
    isLoading: isLoadingFilteredScoresTransition,
    isError: isErrorFilteredScoresTransition,
    error: errorFilteredScoresTransition,
  } = useQueryWithAuthorization(
    ["filteredScoresTransition", filtersProperties],
    getFilteredScoresTransition(filtersProperties)
  );

  const createChartData = (scoresTransitionObj: IScoresTransition) => {
    const total =
      scoresTransitionObj.no_activity +
      scoresTransitionObj.honest +
      scoresTransitionObj.score_increased +
      scoresTransitionObj.same_score +
      scoresTransitionObj.score_decreased;
    const data = [
      {
        from:
          "Score Increased: " + scoresTransitionObj.score_increased.toString(),
        color: "#4BCAD4",
      },
      {
        from: "Same Score: " + scoresTransitionObj.same_score.toString(),
        color: "#9164D1",
      },
      {
        from:
          "Score Decreased: " + scoresTransitionObj.score_decreased.toString(),
        color: "#D964A1",
      },
      {
        from: "Score=0 (Honest): " + scoresTransitionObj.honest.toString(),
        color: "#1E4D99",
      },
      {
        from:
          "No Account Activity: " + scoresTransitionObj.no_activity.toString(),
        color: "#FFFFFF",
      },
      {
        from: "A",
        to:
          "Score Increased: " + scoresTransitionObj.score_increased.toString(),
        value: scoresTransitionObj.score_increased,
        color: "#3380FF",
      },
      {
        from: "A",
        to: "Same Score: " + scoresTransitionObj.same_score.toString(),
        value: scoresTransitionObj.same_score,
        color: "#3380FF",
      },
      {
        from: "A",
        to:
          "Score Decreased: " + scoresTransitionObj.score_decreased.toString(),
        value: scoresTransitionObj.score_decreased,
        color: "#3380FF",
        labelText: "All Accounts: " + total.toString(),
        labelLocation: 0,
      },
      {
        from: "A",
        to: "Score=0 (Honest): " + scoresTransitionObj.honest.toString(),
        value: scoresTransitionObj.honest,
        color: "#3380FF",
      },
      {
        from: "A",
        to:
          "No Account Activity: " + scoresTransitionObj.no_activity.toString(),
        value: scoresTransitionObj.no_activity,
        color: "#3380FF",
      },
    ];
    return data;
  };

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create(
      "ScoresTransitionChart",
      am4charts.SankeyDiagram
    );
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 0;
    chart.paddingTop = 10;
    chart.paddingBottom = -15;
    chart.paddingLeft = -15;

    // Configure data fields
    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";
    chart.dataFields.color = "color";

    chart.nodeAlign = "bottom";
    chart.minNodeSize = 0.001;
    chart.nodePadding = 10;

    // Configure nodes
    const nodeTemplate = chart.nodes.template;
    nodeTemplate.nameLabel.locationX = 0;
    nodeTemplate.nameLabel.label.fill = am4core.color("#fff");
    nodeTemplate.nameLabel.label.fontWeight = "bold";
    nodeTemplate.nameLabel.label.fontSize = "10";
    nodeTemplate.nameLabel.label.truncate = false;
    nodeTemplate.nameLabel.label.wrap = true;
    nodeTemplate.nameLabel.label.horizontalCenter = "right";

    // Configure links
    const linkTemplate = chart.links.template;
    linkTemplate.colorMode = "toNode";
    linkTemplate.tension = 0.3;
    linkTemplate.controlPointDistance = 0.1;
    linkTemplate.fillOpacity = 0.4;
    linkTemplate.tooltipText = "";

    const hoverState = linkTemplate.states.create("hover");
    hoverState.properties.fillOpacity = 1;

    // add labels
    const labelBullet = chart.links.template.bullets.push(
      new am4charts.LabelBullet()
    );
    labelBullet.label.propertyFields.text = "labelText";
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.textAlign = "start";
    labelBullet.propertyFields.locationX = "labelLocation";
    labelBullet.label.dx = 10;
    labelBullet.label.fill = am4core.color("#fff");
    labelBullet.label.fillOpacity = 1;
    labelBullet.label.fontWeight = "bold";
    labelBullet.label.fontSize = "10";

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    if (filteredScoresTransition) {
      const chartObj = createChartData(filteredScoresTransition);
      chartRef.current.data = chartObj;
    }
  }, [filteredScoresTransition]);

  useEffect(() => {
    if (isLoadingFilteredScoresTransition || isErrorFilteredScoresTransition) {
      chartRef.current.data = [];
    }
  }, [isLoadingFilteredScoresTransition, isErrorFilteredScoresTransition]);

  useEffect(() => {
    if (isLoadingFilteredScoresTransition) {
      setNonChartContent(<Loading />);
    } else if (filteredScoresTransition === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorFilteredScoresTransition) {
      console.warn(
        "Failed to lookup for accounts summary by date data",
        errorFilteredScoresTransition?.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingFilteredScoresTransition,
    filteredScoresTransition,
    isErrorFilteredScoresTransition,
    errorFilteredScoresTransition,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Score Transition</div>
          </div>
          <div id="ScoresTransitionChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};

export default ScoresTransition;
