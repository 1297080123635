import React from "react";
import SynamediaLogo from "./SynamediaLogo";
import Sidebar from "./Sidebar";
import classes from "./LeftMenu.module.css";

const LeftMenu = () => {
  return (
    <div className={classes.leftMenu}>
      <SynamediaLogo />
      <Sidebar />
    </div>
  );
};

export default LeftMenu;
