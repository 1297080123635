const configData = {
  am4chartsLicense: "CH246293766",
  am4MapLicense: "MP246293766",
  domain: "dev-r-hxzbg9.us.auth0.com",
  clientId: "mVfxtfoOMn3uRm6TAn4n6B3Otzl2FGfd",
  audience: "https://projects.synamedia.com",
  zoneName: "UTC",
  debug: false,
};

export default configData;
