import React from "react";
import PropTypes from "prop-types";

import classes from "./ErrorMessage.module.css";

const ErrorMessage = (props) => {
  return (
    <div className={classes.loading__container}>
      <div className={classes.loading__text}>
        <div>
          {props.errorMessage
            ? props.errorMessage
            : "Sorry... we could not load this component."}
        </div>
        <div>please try again later.</div>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorMessage;
