import React from "react";
import classes from "./NoData.module.css";

const NoData = () => {
  return (
    <div className={classes.loading__container}>
      <div className={classes.loading__text}>
        <div>No Data.</div>
      </div>
    </div>
  );
};

export default NoData;
