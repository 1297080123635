import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fixHashQueryParams } from "./utils/router-utils";

const QueryParamFixer = ({ children }) => {
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    const auth0Callback =
      window.location.href.includes("state") &&
      window.location.href.includes("code");
    if (/\?.*#/.test(window.location.href) && !auth0Callback) {
      fixHashQueryParams();
    } else {
      setFixed(true);
    }
  }, [fixed]);
  return fixed ? children : <div>fixing query params</div>;
};

QueryParamFixer.propTypes = {
  children: PropTypes.node,
};

export default QueryParamFixer;
