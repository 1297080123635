// eslint-disable-next-line
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const baseHeaders = {
  "content-type": "application/json",
};

const getHealthStatus = () => {
  return {
    method: "GET",
    url: `${baseUrl}/ready`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummary = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountSummary = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${id}/summary`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountTags = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${id}/tags`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummaryAccounts = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/accounts`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountSummaryByDate = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${id}/summary?groupBy=date`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummaryAccountsByDate = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/accounts?groupBy=date`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountSummaryByDateAndComponent = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${id}/summary?groupBy=date,component`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountGantt = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${id}/gant`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountDevices = (accountId) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${accountId}/devices`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getHouseholdDevices = (accountId, householdId) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${accountId}/component/${householdId}/devices`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummaryAccountsHonests = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/accounts?filter=honest`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummaryAccountsSharers = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/accounts?filter=dishonest`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountOverlaps = (accountId) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${accountId}/overlaps`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAllAccounts = (params) => {
  const api = "accountsV2";
  return buildAPIWithParameters(api, params);
};

const getAvailableTags = () => {
  return {
    method: "GET",
    url: `${baseUrl}/tags`,
  };
};

const getSharerLocations = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/sharersPerCountry`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getSummarySharersByDate = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/sharersOverTime`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getScoresDistribution = (params) => {
  const api = "summary/scoresDistribution";
  return buildAPIWithParameters(api, params);
};

const getFilteredScoresDistributionByDate = (params) => {
  const api = "summary/filtered/scoresDistribution";
  const groupBy = ["date"];
  return buildAPIWithParameters(api, { ...params, groupBy: groupBy });
};

const getFilteredScoreTrend = (params) => {
  const api = "summary/filtered/scoreTrend";
  return buildAPIWithParameters(api, params);
};

const getFilteredLabelsAndTags = (params) => {
  const api = "summary/filtered/labelsAndTags";
  return buildAPIWithParameters(api, params);
};

const getFilteredScoresTransition = (params) => {
  const api = "summary/filtered/scoresTransition";
  return buildAPIWithParameters(api, params);
};

const getAccountScoreByDate = (accountId) => {
  return {
    method: "GET",
    url: `${baseUrl}/account/${accountId}/scoresOverTime`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getROI = () => {
  return {
    method: "GET",
    url: `${baseUrl}/config/roi`,
    headers: {
      ...baseHeaders,
    },
  };
};

const saveROIData = (roiParameters) => {
  return {
    method: "PUT",
    url: `${baseUrl}/config/roi`,
    headers: {
      ...baseHeaders,
    },
    body: JSON.stringify(roiParameters),
  };
};

const getAppConfig = () => {
  return {
    method: "GET",
    url: `${baseUrl}/config/app`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getAccountsSummary = (params) => {
  const api = "summary/accounts";
  return buildAPIWithParameters(api, params);
};

const getAccountsSummaryByDate = (params) => {
  const api = "summary/accounts";
  const groupBy = ["date"];
  return buildAPIWithParameters(api, { ...params, groupBy: groupBy });
};

const saveAccountList = (accountList) => {
  return {
    method: "POST",
    url: `${baseUrl}/accounts/filterList`,
    headers: {
      ...baseHeaders,
    },
    body: JSON.stringify(accountList),
  };
};

const buildAPIWithParameters = (api, params = {}) => {
  const limit = params?.limit || null;
  const filters = params?.filters || [];
  const tags = params?.tags || [];
  const importListFilters = params?.importListFilters || [];
  const orderBy = params?.orderBy || null;
  const groupBy = params?.groupBy || [];

  if (!api) {
    return;
  }

  let urlQuery = ``;

  if (limit && limit != "Unlimited") {
    urlQuery += `limit=${limit}`;
  }

  if (filters.length) {
    let filtersString = "&filter=" + filters.join("&filter=");
    urlQuery += filtersString;
  }

  if (orderBy) {
    let orderByString = `&orderBy=${orderBy.fieldName}:${
      orderBy.isAsc ? "asc" : "desc"
    }`;
    urlQuery += orderByString;
  }

  if (tags.length) {
    let tagsString = "&tags=" + tags.join(",");
    urlQuery += tagsString;
  }

  if (groupBy.length) {
    let groupByString = "&groupBy=" + groupBy.join(",");
    urlQuery += groupByString;
  }

  if (importListFilters.length) {
    let importListFiltersString =
      "&filterList=" + importListFilters.join("&filterList=");
    urlQuery += importListFiltersString;
  }

  if (urlQuery[0] == "&") {
    urlQuery = urlQuery.replace("&", "");
  }

  return {
    method: "GET",
    url: `${baseUrl}/${api}?${urlQuery}`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getRevealAccount = (id) => {
  return {
    method: "GET",
    url: `${baseUrl}/reveal/${id}`,
    headers: {
      ...baseHeaders,
    },
  };
};

const getShadowAccounts = () => {
  return {
    method: "GET",
    url: `${baseUrl}/summary/shadowAccounts`,
    headers: {
      ...baseHeaders,
    },
  };
};

export {
  buildAPIWithParameters,
  getHealthStatus,
  getSummary,
  getAccountSummary,
  getAccountTags,
  getSummaryAccounts,
  getAccountSummaryByDate,
  getSummaryAccountsByDate,
  getAccountSummaryByDateAndComponent,
  getAccountGantt,
  getAccountDevices,
  getHouseholdDevices,
  getSummaryAccountsHonests,
  getSummaryAccountsSharers,
  getAccountOverlaps,
  getAllAccounts,
  getAvailableTags,
  getSharerLocations,
  getSummarySharersByDate,
  getScoresDistribution,
  getFilteredScoresDistributionByDate,
  getFilteredScoreTrend,
  getFilteredLabelsAndTags,
  getFilteredScoresTransition,
  getAccountScoreByDate,
  getROI,
  saveROIData,
  getAppConfig,
  getAccountsSummary,
  getAccountsSummaryByDate,
  saveAccountList,
  getRevealAccount,
  getShadowAccounts,
};
