import { useState } from "react";

import dateFormat from "dateformat";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import { getSummary } from "../../../external-apis";

import classes from "./AccountInfoHeader.module.css";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";

export interface AccountInfoHeaderProps {
  view: string;
}

const AccountInfoHeader = (props: AccountInfoHeaderProps) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const { data: summary, isLoading: isLoadingSummary, isError: isErrorSummary, error: errorSummary } = useQueryWithAuthorization('summary', getsummary());
  const { data: summary } = useQueryWithAuthorization("summary", getSummary());

  useDeepCompareEffect(() => {
    if (summary && summary[0]) {
      setStartDate(dateFormat(summary[0]?.start_date?.min, "mmm d, HH:MM"));
      setEndDate(dateFormat(summary[0]?.end_date?.max, "mmm d, HH:MM"));
    }
  }, [summary]);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {props.view === "table" ? "Accounts" : "Action Tracking"}
        </div>
        {startDate && endDate && (
          <div className={classes.dateRange}>
            Data Range {startDate} - {endDate}
          </div>
        )}
      </div>
      {/* <div>
        <Tabs>
          <Tab eventKey="allAccounts" title="All Accounts (Default View)"></Tab>
        </Tabs>
      </div> */}
    </div>
  );
};

export default AccountInfoHeader;
