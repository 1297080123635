import React from "react";
import Page from "../../UI/Page";
import PageHeader from "../../UI/PageHeader";
import PageBody from "../../UI/PageBody";

import ExecutiveSummaryHeader from "./ExecutiveSummaryHeader";
import SharingSummary from "./SharingSummary";
import SharingOverTime from "./SharingOverTime";
import ScoreDistribution from "./ScoreDistribution";
import ViewingStatistics from "./ViewingStatistics";
// import ROI from "./ROI";
import SharingLocations from "./SharingLocations";
// import Groups from './Groups';

import classes from "./ExecutiveSummary.module.css";

const ExecutiveSummary = () => {
  return (
    <Page>
      <PageHeader>
        <ExecutiveSummaryHeader />
      </PageHeader>
      <PageBody>
        <div className={classes.bodyContainer}>
          <div className={classes.leftSide}>
            <div className={classes.leftSide__top}>
              {/* <div className={classes.leftSide__top__left}>
                <ViewingStatistics />
              </div>
              <div className={classes.leftSide__top__right}> */}
              <SharingSummary />
              {/* </div> */}
            </div>
            <div className={classes.leftSide__bottom}>
              <div className={classes.leftSide__bottom__left}>
                <div className={classes.leftSide__bottom__left__top}>
                  <SharingOverTime />
                </div>
                <div className={classes.leftSide__bottom__left__bottom}>
                  <ScoreDistribution />
                </div>
              </div>
              <div className={classes.leftSide__bottom__right}>
                <div className={classes.leftSide__bottom__right__top}>
                  {/* <ROI /> */}
                  <ViewingStatistics />
                </div>
                <div className={classes.leftSide__bottom__right__bottom}>
                  <SharingLocations />
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classes.rightSide}>
                    <Groups />
                </div> */}
        </div>
      </PageBody>
    </Page>
  );
};

export default ExecutiveSummary;
