import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  useAccountStore,
  useComponentsRoutesStore,
} from "../../state-management";
import { NotFoundPage } from "../../components/pages";

import Loader from "../../components/Loader";

const ProtectedRoute = ({ component: Component, path, ...args }) => {
  const {
    params: { accountIdOrAlias, page },
  } = useRouteMatch(path);

  // const [cookies, setCookie] = useCookies(['accountPortal-orgId']);

  const componentsRoutes = useComponentsRoutesStore(
    (state) => state.componentsRoutes
  );

  // const setOrgId = useAccountStore(state => state.setOrgId);
  const setAccountIdOrAlias = useAccountStore(
    (state) => state.setAccountIdOrAlias
  );

  // const {
  // 	isLoading,
  // 	isError,
  // 	data: organizationId,
  // 	error
  // } = useQuery('orgId', fetchFn(apiLookupOrgIdByAccountIdOrAlias(accountIdOrAlias)), {
  // 	refetchOnWindowFocus: false,
  // 	// retry: false,
  // 	refetchOnMount: false,
  // 	refetchOnReconnect: false,
  // 	refetchInterval: false,
  // 	retryOnMount: false
  // });

  // if (isLoading) return <Loader/>;
  // if (isError) {
  // 	console.warn('Failed to lookup for organization ID', error.message);

  // 	return <NotFoundPage/>;
  // }

  setAccountIdOrAlias(accountIdOrAlias);
  // setOrgId(organizationId);

  // TODO:
  //  set account orgId (for 'synamedia.com')
  //  get domain by current environment (DEV, PROD)
  // setCookie('accountPortal-orgId', organizationId, { domain: '.synamedia.com' });

  if (
    page &&
    !Object.prototype.hasOwnProperty.call(componentsRoutes, `/${page}`)
  )
    return <NotFoundPage />;

  return (
    <Route
      component={withAuthenticationRequired(Component, {
        onRedirecting: () => <Loader />,
        returnTo: "#" + path,
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
