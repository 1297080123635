import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import HealthCheck from "./auth/HealthCheck";
import HomePage from "./components/pages/HomePage/HomePage";
import { AccountsProvider } from "./contexts/accountsContext";
import { AccountDevicesProvider } from "./contexts/accountDevicesContext";
import { AppConfigProvider } from "./contexts/appConfigContext";
import NetworkCheck from "./checks/NetworkCheck";

const App = () => {
  return (
    <HealthCheck>
      <AppConfigProvider>
        <AccountDevicesProvider>
          <AccountsProvider>
            <NetworkCheck>
              <div>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <ProtectedRoute path="/home" component={HomePage} />
                  {/* <Route path='/home' component={ HomePage } /> */}
                  <Route exact path="/health">
                    <div>App is healthy</div>
                  </Route>
                </Switch>
              </div>
            </NetworkCheck>
          </AccountsProvider>
        </AccountDevicesProvider>
      </AppConfigProvider>
    </HealthCheck>
  );
};

export default App;
