import { useState, useEffect } from "react";

// Copied from https://usehooks.com/useOnScreen/

export function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const callbackFunction = ([entry]) => {
      // Update our state when observer callback fires
      setIntersecting(entry.isIntersecting);
    };
    const observer = new IntersectionObserver(callbackFunction, { rootMargin });
    let observerRefValue = null;
    if (ref.current) {
      ref.current instanceof Element && observer.observe(ref.current);
      observerRefValue = ref.current;
    }
    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}
