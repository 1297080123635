import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory, {
  Auth0HashRouterPathFix,
} from "./auth/Auth0ProviderWithHistory";
import { OrganizationProvider } from "./auth/OrganizationProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import QueryParamFixer from "./queryParamFixer";
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorHandlingProvider } from "./contexts/errorHandlingContext";

const queryClient = new QueryClient();

const component = (
  <Router>
    <ErrorHandlingProvider>
      <QueryParamFixer>
        <QueryClientProvider client={queryClient}>
          <OrganizationProvider>
            <Auth0ProviderWithHistory>
              <Auth0HashRouterPathFix>
                {/* <CookiesProvider> */}
                <App />
                {/* </CookiesProvider> */}
              </Auth0HashRouterPathFix>
            </Auth0ProviderWithHistory>
          </OrganizationProvider>
        </QueryClientProvider>
      </QueryParamFixer>
    </ErrorHandlingProvider>
  </Router>
);
ReactDOM.render(component, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
