import { useEffect, useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import UploadedIcon from "../../../../assets/img/uploaded.svg";

import classes from "./ImportFileFilter.module.scss";
import { useAlignedPopperWidth } from "./useAlignedPopperWidth";
import {
  IImportListInfo,
  UPLOAD_STATES,
} from "../../../../services/accountInterfaces";
import Modal, { MODAL_TYPES } from "../../../UI/Modal";
import Papa from "papaparse";
import { useMutationWithAuthorization } from "../../../../custom-hooks";
import { saveAccountList, saveROIData } from "../../../../external-apis";
import { AppConfigContext } from "../../../../contexts/appConfigContext";

export interface IImportFileFilterProps {
  filterName: string; // For example, Score, Duration
  onRemoveFilterClick: () => void;
  onFileChange: (file: File) => void;
  onReplaceFile: () => void;
  onAccountListResponse: (importListInfo: IImportListInfo) => void;
  filterInfo: IImportListInfo;
}

export interface IAccountsListJson {
  accounts: string[];
}

const ImportFileFilter = (props: IImportFileFilterProps) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [accountIDs, setAccountIDs] = useState([]);
  const [fileRquirements, setFileRquirements] = useState([]);
  const [uuid, setUuid] = useState(null);
  const { isTrialMode } = useContext(AppConfigContext);

  const { mutateAsync } = useMutationWithAuthorization();

  useEffect(() => {
    if (props.filterInfo.uploadeFileState === UPLOAD_STATES.UPLOADING) {
      const valuesArray = [];
      Papa.parse(props?.filterInfo?.current, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.map((d) => {
            valuesArray.push(Object.values(d));
          });
          const concatArr = [].concat(...valuesArray.map((arr) => arr[0]));
          const nonEmpty = concatArr.filter(
            (element) => element && element.length > 0
          );
          const uniq = [...new Set(nonEmpty)];
          const ids = uniq[0] === "Account ID" ? uniq.splice(1) : uniq;
          setAccountIDs(ids);
        },
      });
    }
  }, [props.filterInfo.uploadeFileState]);

  useEffect(() => {
    if (accountIDs && accountIDs.length > 0) {
      checkFileValidation();
    }
  }, [accountIDs]);

  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth();

  const checkFileValidation = () => {
    const isValidFileType = checkFileType();
    const isValidFileNameLength = checkFileNameLength();
    const isValidFileLength = checkFileLength();
    const isValidIDsFormat = checkIDsFormat();
    if (
      !isValidFileType ||
      !isValidFileNameLength ||
      !isValidFileLength ||
      !isValidIDsFormat
    ) {
      setShowImportModal(true);
    } else {
      const accountsListJson: IAccountsListJson = { accounts: accountIDs };
      const postAccountList = mutateAsync(saveAccountList(accountsListJson));
      postAccountList
        .then((res) =>
          props.onAccountListResponse({
            ...props.filterInfo,
            uploadeFileState: UPLOAD_STATES.UPLOAD_SUCCEEDED,
            uuid: res.uuid,
          })
        )
        .catch((err) => {
          props.onAccountListResponse({
            ...props.filterInfo,
            uploadeFileState: UPLOAD_STATES.UPLOAD_FAILED,
            uuid: null,
          });
        });
    }
  };

  const checkFileType = (): boolean => {
    const isValidFileType = props?.filterInfo?.current?.type === "text/csv";
    if (!isValidFileType) {
      setFileRquirements((prevRquirements) => [
        ...prevRquirements,
        "File type: CSV",
      ]);
    }
    return isValidFileType;
  };

  const checkFileNameLength = (): boolean => {
    const isValidFileNameLength = props?.filterInfo?.current?.name.length <= 64;
    if (!isValidFileNameLength) {
      setFileRquirements((prevRquirements) => [
        ...prevRquirements,
        "File name length should be maximum 64 chars length",
      ]);
    }
    return isValidFileNameLength;
  };

  const checkFileLength = (): boolean => {
    const allowListLen = isTrialMode ? 25 : 10000;
    const isValidFileLen = accountIDs.length <= allowListLen;
    if (!isValidFileLen) {
      allowListLen == 25
        ? setFileRquirements((prevRquirements) => [
            ...prevRquirements,
            "File Length: The system is in trial mode, import list is limited to " +
              allowListLen +
              " uniq accounts",
          ])
        : setFileRquirements([
            ...fileRquirements,
            "File Length: " +
              +"Import list is limited to " +
              allowListLen +
              " accounts",
          ]);
    }
    return isValidFileLen;
  };

  const checkIDsFormat = (): boolean => {
    var re = new RegExp("^([-_a-zA-Z0-9]*)$");
    const isValidIDsFormat = accountIDs.every((id) => re.test(id));
    if (!isValidIDsFormat) {
      setFileRquirements((prevRquirements) => [
        ...prevRquirements,
        "ID Format: Account ID can include only digit, letters and hyphen",
      ]);
    }
    return isValidIDsFormat;
  };

  const handleFileChange = () => {
    props.onFileChange(props?.filterInfo?.current);
  };

  const handleCloseModal = () => {
    setShowImportModal(false);
    setFileRquirements([]);
    props.onRemoveFilterClick();
  };

  const handleReplaceFile = () => {
    setShowReplaceModal(false);
    props.onReplaceFile();
  };

  const handleFilterClicked = () => {
    if (props.filterInfo.current) {
      setShowReplaceModal(true);
    } else {
      props.onReplaceFile();
    }
  };

  return (
    <div className={classes.container} ref={elementAlignedToRef}>
      <button
        className={classes.xBtn}
        onClick={() => {
          props.onRemoveFilterClick();
        }}
      >
        X
      </button>
      <button
        className={classes.toggleRangePopperBtn}
        onClick={handleFilterClicked}
      >
        <span
          className={
            props.filterInfo.uploadeFileState === UPLOAD_STATES.UPLOADING
              ? classes.loadingText_uploading
              : ""
          }
        >
          {props.filterName}: {props?.filterInfo?.current?.name}
        </span>
        {props.filterInfo.uploadeFileState === UPLOAD_STATES.UPLOADING && (
          <div className={classes.loadingWrapper}>
            <Stack
              sx={{ color: "grey.500" }}
              spacing={2}
              direction="row"
              style={{ marginRight: ".5rem" }}
            >
              <CircularProgress color="inherit" size="1rem" />
            </Stack>
            <div>Uploading</div>
          </div>
        )}
        {props.filterInfo.uploadeFileState ===
          UPLOAD_STATES.UPLOAD_SUCCEEDED && (
          <div className={classes.loadingWrapper}>
            <img
              className={classes.arrowDownSvg}
              src={UploadedIcon}
              style={{ marginRight: ".5rem" }}
            />
            <div className={classes.loadingText_success}>Uploaded</div>
          </div>
        )}
      </button>
      {showImportModal && (
        <Modal
          type={MODAL_TYPES.DANGER}
          title="Import List - Unsupported File"
          buttonText="OK"
          contentTitle="Plaese Note"
          contentText="The uploaded file does not match the import requirements:"
          contentList={fileRquirements}
          onClose={handleCloseModal}
          onButtonClick={handleCloseModal}
        />
      )}
      {showReplaceModal && (
        <Modal
          type={MODAL_TYPES.DANGER}
          title="Replace Imported List"
          buttonText="OK"
          contentTitle="Plaese Note"
          contentText="Selecting a new list to import will replace the existing list filter. 
                    Continue?"
          onClose={() => setShowReplaceModal(false)}
          onButtonClick={handleReplaceFile}
        />
      )}
    </div>
  );
};

export default ImportFileFilter;
