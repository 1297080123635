import { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
//import { useDebounce } from "../../../../custom-hooks/useDebounce";
import { Popper, Slider } from "@mui/material";

import DownArrowForMenuSvg from "../../../../assets/img/downArrowForMenu.svg";

import classes from "./RangeFilter.module.scss";
import { useAlignedPopperWidth } from "./useAlignedPopperWidth";
import { IRangeFieldInfo } from "../../../../services/accountInterfaces";

export interface IRangeFilterProps {
  filterName: string; // For example, Score, Duration, Households
  onRemoveFilterClick: () => void;
  onRangeChange: (from: number, to: number) => void;
  filterInfo: IRangeFieldInfo;
}

const RangeFilter = (props: IRangeFilterProps) => {
  const [rangeFromToValue, setRangeFromToValue] = useState([0, 0]);
  const [rangeButtonText, setRangeButtonText] = useState("");

  useEffect(() => {
    if (props.filterInfo) {
      setRangeFromToValue(props.filterInfo.current);

      const min = props.filterInfo.min;
      const max = props.filterInfo.max;
      const currentFrom = props.filterInfo.current[0];
      const currentTo = props.filterInfo.current[1];
      if (currentFrom === min && currentTo === max) {
        setRangeButtonText("Choose Range...");
      } else {
        setRangeButtonText(
          `${currentFrom} - ${currentTo}` +
            (props.filterInfo.infiniteRange && currentTo == props.filterInfo.max
              ? `+`
              : ``)
        );
      }
    }
  }, [props.filterInfo]);

  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth<HTMLDivElement>();

  const handleRangeValueChange = ([from, to]: number[]) => {
    props.onRangeChange(from, to);
  };

  const resetFilterValue = () => {
    handleRangeValueChange([props.filterInfo.min, props.filterInfo.max]);
  };

  const valueLabelFormat = (value: number) => {
    return props.filterInfo.infiniteRange && value == props.filterInfo.max
      ? value.toString() + "+"
      : value.toString();
  };

  return (
    <div className={classes.container} ref={elementAlignedToRef}>
      <button
        className={classes.xBtn}
        onClick={() => {
          props.onRemoveFilterClick();
        }}
      >
        X
      </button>

      <button
        className={classes.toggleRangePopperBtn}
        type="button"
        onClick={toggleIsPopperOpenClick}
      >
        {props.filterName}: {rangeButtonText}
        <img className={classes.arrowDownSvg} src={DownArrowForMenuSvg} />
      </button>
      <Popper open={isPopperOpen} anchorEl={elementAlignedToRef.current}>
        <ClickAwayListener onClickAway={toggleIsPopperOpenClick}>
          <div
            style={{
              width: popperWidth + 2,
              background: "#151724",
              border: "1px solid #576079",
              borderRadius: "4px",
              marginTop: "4px",
            }}
          >
            <div className={classes.popperContentContainer}>
              <div>
                <button
                  onClick={() => {
                    resetFilterValue();
                  }}
                  className={classes.resetButton}
                >
                  RESET
                </button>
              </div>

              <div style={{ marginTop: "2rem", padding: "0 0.5rem" }}>
                <Slider
                  classes={{ markLabel: classes.sliderMarkLabel }}
                  value={rangeFromToValue}
                  min={props.filterInfo.min}
                  max={props.filterInfo.max}
                  step={props.filterInfo.step}
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="on"
                  onChange={(evt, newValue) =>
                    handleRangeValueChange(newValue as number[])
                  }
                  sx={{
                    "& .MuiSlider-thumb": {
                      color: "#06A3ED",
                      width: "15px",
                      height: "15px",
                      border: "2px solid 06A3ED",
                      boxShadow: "0px 0px 0px 6px transparent",
                    },
                    "& .MuiSlider-thumb:active": {
                      border: "2px solid white",
                      boxShadow: "0px 0px 0px 6px rgba(25, 118, 210, 0.16)",
                    },
                    "& .MuiSlider-thumb:hover": {
                      border: "2px solid white",
                      boxShadow: "0px 0px 0px 6px rgba(25, 118, 210, 0.16)",
                    },
                    "& .MuiSlider-track": {
                      color: "white",
                    },
                    "& .MuiSlider-rail": {
                      color: "#334162",
                    },
                    "& .MuiSlider-valueLabel": {
                      fontSize: 12,
                      fontWeight: "normal",
                      top: -6,
                      backgroundColor: "unset",
                      color: "white",
                      "&:before": {
                        display: "none",
                      },
                      "& *": {
                        background: "transparent",
                        color: "white",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default RangeFilter;
