import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import classes from "./HouseholdsOverTime.module.css";

import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import { getAccountSummaryByDate } from "../../../../external-apis";
import { sortItemsWithDate } from "../../../../utils/statisticsUtils";
import { IChartItem } from "./IChartItem";

import configData from "../../../../config.js";

import { AccountsContext } from "../../../../contexts/accountsContext";

interface IHouseholdData {
  date: string;
  components: { distinct: number };
}

const HouseholdsOverTime = (props) => {
  const chartRef = useRef(null);
  const accountSeriesRef = useRef(null);
  const { selectedAccount } = useContext(AccountsContext);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("householdsOverTimeChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.dateFormatter.dateFormat = "dd MMM yyyy";

    chart.paddingRight = 5;
    chart.paddingTop = 15;
    chart.paddingBottom = -17;
    chart.paddingLeft = -5;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 35;
    // dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    // dateAxis.renderer.grid.template.opacity = 0.5;
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 12;
    dateAxis.fontWeight = "bold";
    dateAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    dateAxis.renderer.labels.template.dy = -7;

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    // valueAxis.renderer.grid.template.opacity = 0.5;
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 16;
    // valueAxis.extraMax = 0.2;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "bold";
    valueAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    valueAxis.maxPrecision = 0;
    valueAxis.min = 0;

    const accountSeries = chart.series.push(new am4charts.ColumnSeries());
    accountSeries.dataFields.valueY = "value";
    accountSeries.dataFields.dateX = "date";
    accountSeries.strokeWidth = 2;

    accountSeries.strokeWidth = 0;
    accountSeries.fill = am4core.color("#3380FF");
    accountSeries.columns.template.tooltipText =
      "{date.formatDate('MMM d')}, {value} Households";
    accountSeries.columns.template.tooltip = new am4core.Tooltip();
    // accountSeries.columns.template.tooltip.pointerOrientation = "right";
    accountSeries.columns.template.tooltip.getFillFromObject = false;
    accountSeries.columns.template.tooltip.label.fill =
      am4core.color("#E1E1E1");
    accountSeries.columns.template.tooltip.background.stroke =
      am4core.color("#E1E1E1");
    accountSeries.columns.template.tooltip.background.fill =
      am4core.color("#25293D");
    accountSeries.columns.template.tooltip.background.opacity = 1;

    accountSeriesRef.current = accountSeries;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const {
    data: accountSummaryByDate,
    isLoading: isLoadingAccountSummaryByDate,
    isError: isErrorAccountSummaryByDate,
    error: errorAccountSummaryByDate,
  } = useQueryWithAuthorization(
    ["accountSummaryByDate", selectedAccount],
    getAccountSummaryByDate(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  useDeepCompareEffect(() => {
    if (accountSummaryByDate) {
      const households: IChartItem[] = sortItemsWithDate<IHouseholdData>(
        accountSummaryByDate
      ).map((item) => {
        return { date: item.date, value: item.origItem.components.distinct };
      });

      accountSeriesRef.current.data = households;
    }
  }, [accountSummaryByDate]);

  useEffect(() => {
    if (isLoadingAccountSummaryByDate || isErrorAccountSummaryByDate) {
      accountSeriesRef.current.data = [];
    }
  }, [isLoadingAccountSummaryByDate, isErrorAccountSummaryByDate]);

  //let content = <div className={classes.title}>Households</div>;
  // let content = <div className={classes.main}>
  //     <div className={classes.title}>Households</div>
  //     <div id="householdsOverTimeChart" className={classes.chart}></div>
  // </div>;
  let nonChartContent = null;
  if (isLoadingAccountSummaryByDate) {
    nonChartContent = <Loading />;
  } else if (
    accountSummaryByDate?.every((date) => date.components.distinct === 0)
  ) {
    nonChartContent = (
      <div className={classes.loading__container}>
        <div className={classes.loading__text}>
          <div>No Housholds.</div>
        </div>
      </div>
    );
  } else if (accountSummaryByDate === null) {
    nonChartContent = <NoData />;
  } else if (isErrorAccountSummaryByDate) {
    console.warn(
      "Failed to lookup for account households data",
      errorAccountSummaryByDate.message
    );
    nonChartContent = <ErrorMessage />;
  }

  return (
    <div
      className={
        props.isFullScreenView ? classes.fullScreenView : classes.tabsView
      }
    >
      <div className={classes.container}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}

        <div
          className={classes.main}
          style={{ visibility: nonChartContent ? "hidden" : "visible" }}
        >
          <div className={classes.title}>Households</div>
          <div id="householdsOverTimeChart" className={classes.chart}></div>
        </div>
      </div>
    </div>
  );
};

HouseholdsOverTime.propTypes = {
  accountID: PropTypes.string,
  isFullScreenView: PropTypes.bool,
};

export default HouseholdsOverTime;
