import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoader from "react-loader-spinner";
import { getQueryParams } from "../../../utils/router-utils";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",

      /* position the div in center */
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    title: {
      top: 20,
      fontSize: 24,
    },
  };
});

const Auth0CallbackPage = () => {
  const classes = useStyles();

  const { error, error_description } = getQueryParams();

  if (error)
    return (
      <div>
        {error} - {error_description}
      </div>
    );

  return (
    <div className={classes.container}>
      <div className={classes.title}>Redirecting</div>
      <ReactLoader
        type={"ThreeDots"}
        color={"#3381ff"}
        height={100}
        width={100}
      />
    </div>
  );
};

export default Auth0CallbackPage;
