import React from "react";
import { useState } from "react";

import numeral from "numeral";

import Card from "../../UI/Card";

import ExcessViewing from "../../../assets/img/excessViewing.svg";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import NoData from "../../UI/NoData";

import {
  getSummaryAccountsSharers,
  getSummaryAccountsHonests,
  getSummaryAccounts,
} from "../../../external-apis";

import classes from "./ViewingStatistics.module.css";

const ViewingStatistics = () => {
  const [excessViewingInHours, setExcessViewingInHours] = useState(0);
  const [excessViewingPercentage, setExcessViewingPercentage] = useState(0);
  const [sharersExcessViewing, setSharersExcessViewing] = useState(0);

  const {
    data: summaryAccountsSharers,
    isLoading: isLoadingSummaryAccountsSharers,
    isError: isErrorSummaryAccountsSharers,
    error: errorSummaryAccountsSharers,
  } = useQueryWithAuthorization(
    "summaryAccountsSharers",
    getSummaryAccountsSharers()
  );

  const {
    data: summaryAccountsHonests,
    isLoading: isLoadingSummaryAccountsHonests,
    isError: isErrorSummaryAccountsHonests,
    error: errorSummaryAccountsHonests,
  } = useQueryWithAuthorization(
    "summaryAccountsHonests",
    getSummaryAccountsHonests()
  );

  const {
    data: summaryAccounts,
    isLoading: isLoadingSummaryAccounts,
    isError: isErrorSummaryAccounts,
    error: errorSummaryAccounts,
  } = useQueryWithAuthorization("summaryAccounts", getSummaryAccounts());

  useDeepCompareEffect(() => {
    if (
      summaryAccountsSharers?.[0] &&
      summaryAccountsHonests?.[0] &&
      summaryAccounts?.[0]
    ) {
      // let excessViewing = summaryAccountsSharers[0].duration.sum - (summaryAccountsSharers[0].account_ids.distinct * SummaryAccountsHonests[0].duration.median);
      let excessViewing =
        (summaryAccountsSharers[0].duration.average -
          summaryAccountsHonests[0].duration.average) *
        summaryAccountsSharers[0].account_ids.distinct;
      setExcessViewingInHours(excessViewing / 3600);
      setExcessViewingPercentage(
        excessViewing / (summaryAccounts[0].duration.sum - excessViewing)
      );
      setSharersExcessViewing(
        summaryAccountsSharers[0].duration.average /
          summaryAccountsHonests[0].duration.average -
          1
      );
    }
  }, [summaryAccountsSharers, summaryAccountsHonests, summaryAccounts]);

  let content = (
    <div className={classes.wrapper}>
      <div className={classes.main}>
        <img src={ExcessViewing} alt="sidebar icon" className={classes.logo} />
        <div className={classes.newClass}>
          <div className={classes.values}>
            {numeral(excessViewingInHours).format("0,0")}
            <span className={classes.timeCategory}> hours</span>
          </div>
          <div className={classes.text}>Excess Viewing</div>
        </div>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.detailsWrapper}>
        <div className={classes.details}>
          <div>Total Excess Viewing</div>
          <div>+{numeral(excessViewingPercentage).format("0.%")}</div>
        </div>
        <div className={classes.details}>
          <div>Sharer Accounts Excess Viewing</div>
          <div>+{numeral(sharersExcessViewing).format("0.%")}</div>
        </div>
      </div>
    </div>
  );

  if (
    isLoadingSummaryAccountsSharers ||
    isLoadingSummaryAccountsHonests ||
    isLoadingSummaryAccounts
  ) {
    content = <Loading />;
  } else if (
    summaryAccountsSharers === null ||
    summaryAccountsHonests === null ||
    summaryAccounts === null
  ) {
    content = <NoData />;
  } else if (isErrorSummaryAccountsSharers) {
    console.warn(
      "Failed to lookup for sharer accounts data",
      errorSummaryAccountsSharers?.message
    );
    content = <ErrorMessage />;
  } else if (isErrorSummaryAccountsHonests) {
    console.warn(
      "Failed to lookup for honest accounts data",
      errorSummaryAccountsHonests?.message
    );
    content = <ErrorMessage />;
  } else if (isErrorSummaryAccounts) {
    console.warn(
      "Failed to lookup for all accounts data",
      errorSummaryAccounts?.message
    );
    content = <ErrorMessage />;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>{content}</Card>
    </div>
  );
};

export default ViewingStatistics;
