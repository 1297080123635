import { useState, useEffect, useRef, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import NoMaxWidthTooltip from "../../UI/NoMaxWidthTooltip";

import classes from "./AccountsTable.module.scss";
import "./AccountsTable.css";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../custom-hooks";

import { secondsToHours } from "../../../utils/timeUnitsConverter";
import { IAccount } from "../../../services/accountInterfaces";
import { useOnScreen } from "../../../hooks/use-on-screen";
// import usePrefetchAccountData from "../../../custom-hooks/external-api-hooks/usePrefetchAccountData";
import config from "../../../config";
import { AccountsContext } from "../../../contexts/accountsContext";
import { AppConfigContext } from "../../../contexts/appConfigContext";
import { getRevealAccount } from "../../../external-apis";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import UploadedIcon from "../../../assets/img/uploaded.svg";

function marginObject({ top = 0, right = 0, bottom = 0, left = 0 }) {
  return `${top}px ${right}px ${bottom}px ${left}px `;
}

// DataGrid requires that hooks will be implemented in a separate component
// https://mui.com/x/react-data-grid/column-definition/#using-hooks-inside-a-renderer
const CellWithPrefetch = ({ accountId, disableReveal, setDisableReveal }) => {
  const [accountIdToReveal, setAccountIdToReveal] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const { setRevealedAccountId, getHashedOrRevealedId } =
    useContext(AccountsContext);
  const { isTrialMode } = useContext(AppConfigContext);
  const id = accountId != "" ? getHashedOrRevealedId(accountId) : "";

  const { data: revealAccount, isLoading: isLoadingRevealAccount } =
    useQueryWithAuthorization(
      ["revealAccount", accountIdToReveal],
      getRevealAccount(accountIdToReveal),
      { enabled: accountIdToReveal != null }
    );

  useEffect(() => {
    if (revealAccount) {
      if (revealAccount.account_id == "") {
        setDisableReveal(true);
      } else {
        setRevealedAccountId(
          accountId,
          revealAccount.account_id,
          revealAccount.devices
        );
      }
    }
    // eslint-disable-next-line
  }, [revealAccount]);

  const handleRevealClick = () => {
    setAccountIdToReveal(accountId);
  };

  const ref = useRef();
  const onScreen = useOnScreen(ref, marginObject({ bottom: -340 }));
  // disable prefetch in order to avoid overloading the api cluster
  // usePrefetchAccountData(accountId, onScreen);
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        minWidth: "100%",
        justifyContent: "space-between",
      }}
    >
      {config.debug && onScreen ? "<[debug] prefetch>" : ""}
      {id.length <= 30 && (
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {" "}
          {id}
        </span>
      )}
      {id.length > 30 && (
        <NoMaxWidthTooltip title={id} arrow>
          <span> {id.slice(0, 15) + "..." + id.slice(-15)}</span>
        </NoMaxWidthTooltip>
      )}
      {isTrialMode && (
        <NoMaxWidthTooltip
          title={"You’ve reached reveal attempts daily limit."}
          arrow
          open={disableReveal && isHover && accountId == id}
        >
          <button
            disabled={
              isLoadingRevealAccount || accountId != id || disableReveal
            }
            className={
              isLoadingRevealAccount || accountId != id
                ? classes.revealButtonNotAllowed
                : classes.revealButton
            }
            onClick={handleRevealClick}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {isLoadingRevealAccount == true && (
              <Stack
                sx={{ color: "white.500" }}
                spacing={2}
                direction="row"
                style={{ height: "1rem", margin: "auto 0" }}
              >
                <CircularProgress color="inherit" size="1rem" />
              </Stack>
            )}
            {!isLoadingRevealAccount && accountId == id && <span>REVEAL</span>}
            {!isLoadingRevealAccount && accountId != id && (
              <img
                className={classes.arrowDownSvg}
                src={UploadedIcon}
                style={{ height: "1rem", margin: "auto 0" }}
              />
            )}
          </button>
        </NoMaxWidthTooltip>
      )}
    </div>
  );
};

interface IAccountsTableProps {
  onAccountSelected: (account: IAccount) => void;
  changePageFunc;
}

const AccountsTable = (props: IAccountsTableProps) => {
  const [rows, setRows] = useState([]);
  const [disableReveal, setDisableReveal] = useState(false);
  const { accounts, selectedAccount, totalAccounts, page, setPage } =
    useContext(AccountsContext);
  const columns = [
    {
      field: "account_id",
      headerName: "Account ID",
      flex: 1,
      renderCell: (params) => (
        <CellWithPrefetch
          accountId={params.value}
          disableReveal={disableReveal}
          setDisableReveal={setDisableReveal}
        />
      ),
    },
    {
      field: "score",
      headerName: "Score",
      width: 80,
    },
    {
      field: "label",
      headerName: "Label",
      width: 80,
    },
    {
      field: "components",
      headerName: "Households",
      width: 110,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 120,
      renderCell: (params) => {
        const tags: string[] = params.value;
        let cellValue = tags.length === 0 ? "" : tags[0];
        if (tags.length > 1) {
          cellValue = `(${tags.length}) ` + cellValue;
        }

        return (
          <Tooltip title={tags.join(", ")}>
            <span className="table-cell-trucate">{cellValue}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "sum_duration",
      headerName: "Duration",
      flex: 0.5,
      valueFormatter: (params) => {
        const valueInHours = secondsToHours(params.value);
        // if (valueInHours < 24) {
        //     return `${valueInHours} h`;
        // } else {
        //     return `${Math.floor(valueInHours / 24)} d, ${valueInHours % 24} h`;
        // }
        return `${valueInHours} Hours`;
      },
    },
  ];

  useEffect(() => {
    props.changePageFunc.current = handleChangePage;
  });

  useDeepCompareEffect(() => {
    if (accounts) {
      setRows(accounts);
    }
  }, [accounts]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // accountIds should be an array with only one item - the selected account id
  const onSelectRowHandler = (accountId) => {
    const selectedAccountId = accountId[0];
    if (selectedAccountId) {
      const selectedAccount = accounts.find(
        (a) => a.account_id === selectedAccountId
      );
      if (props.onAccountSelected) {
        props.onAccountSelected(selectedAccount);
      }
    }
  };

  const getRowId = (row) => {
    return row.account_id;
  };

  const content = (
    <div className={classes.main}>
      <div className={classes.accountTable}>
        <DataGrid
          pagination
          hideFooterSelectedRowCount={true}
          rows={rows}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          getRowId={getRowId}
          onSelectionModelChange={onSelectRowHandler}
          selectionModel={[selectedAccount?.account_id] || []}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
      <div className={classes.totalRecords}>Total: {totalAccounts}</div>
    </div>
  );

  return <div className={classes.container}>{content}</div>;
};

export default AccountsTable;
