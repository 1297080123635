import React from "react";
import classes from "./AccountInfoDashboardView.module.scss";
// import SummaryBar from "../../specific-account/summary-bar/SummaryBar";
import Loading from "../../../UI/Loading";
import AvgSummary from "./AvgSummary";
import ScoreDistributionForGroup from "./ScoreDistributionForGroup";
import AvgExcessViewing from "./AvgExcessViewing";
import AvgHhPerAccount from "./AvgHhPerAccount";
import AvgScoreTrend from "./AvgScoreTrend";
import ScoreTransition from "./ScoreTransition";
import UseCases from "./UseCases";
export interface IAccountInfoDashboardViewProps {
  hasAccounts: boolean;
  isLoadingAccounts: boolean;
}

const AccountInfoDashboardView = (props: IAccountInfoDashboardViewProps) => {
  return (
    <div className={classes.bodyContainer}>
      {props.isLoadingAccounts && <Loading />}
      {!props.isLoadingAccounts && !props.hasAccounts && (
        <div className={classes.noResultMsg}>
          cannot display dashboard for 0 results...
        </div>
      )}
      {!props.isLoadingAccounts && props.hasAccounts && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AvgSummary />
          <div className={classes.chartsContainer}>
            <div className={classes.chartsContainer__left}>
              <div className={classes.chartsContainer__left__top}>
                <UseCases />
              </div>
              <div className={classes.chartsContainer__left__bottom}>
                <ScoreTransition />
              </div>
            </div>
            <div className={classes.chartsContainer__middle}>
              <div className={classes.chartsContainer__middle__top}>
                <AvgScoreTrend />
              </div>
              <div className={classes.chartsContainer__middle__bottom}>
                <AvgHhPerAccount />
              </div>
            </div>
            <div className={classes.chartsContainer__right}>
              <div className={classes.chartsContainer__right__top}>
                <AvgExcessViewing />
              </div>
              <div className={classes.chartsContainer__right__bottom}>
                <ScoreDistributionForGroup />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountInfoDashboardView;
