const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window?.location?.search || {});

  const params = {};

  for (const [key, value] of urlSearchParams) {
    params[key] = value;
  }

  return params;
};

// Take any query parameters before the hash symbol and move them after, forcing the page to refresh
// and process the new state.
const fixHashQueryParams = (remove) => {
  let replaceUrl = window.location.href.replace(window.location.search, "");
  if (!remove) replaceUrl += window.location.search;

  // console.log({ href: window.location.href, replaceUrl });
  window.location.href = replaceUrl;
};

export { getQueryParams, fixHashQueryParams };
