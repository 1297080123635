import React, { useState, useContext } from "react";
import { useRef, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from "../../../UI/Card";

import classes from "./AvgHHPerAccount.module.scss";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import configData from "../../../../config.js";

import { sortItemsWithDate } from "../../../../utils/statisticsUtils";
import { IAccountsSummaryByDate } from "../../../../services/accountInterfaces";
import { getAccountsSummaryByDate } from "../../../../external-apis/api";
import { AccountsContext } from "../../../../contexts/accountsContext";

interface IChartItem {
  date: Date;
  value: number;
}

const AvgHHPerAccount = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const { filtersProperties } = useContext(AccountsContext);

  const chartRef = useRef(null);
  const seriesRef = useRef(null);

  const {
    data: accountsSummaryByDate,
    isLoading: isLoadingAccountsSummaryByDate,
    isError: isErrorAccountsSummaryByDate,
    error: errorAccountsSummaryByDate,
  } = useQueryWithAuthorization(
    ["accountsSummaryByDate", filtersProperties],
    getAccountsSummaryByDate(filtersProperties)
  );

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("avgHHPerAccountChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 5;
    chart.paddingTop = 10;
    chart.paddingBottom = -10;
    chart.paddingLeft = -10;

    chart.dateFormatter.dateFormat = "dd MMM yyyy";
    chart.numberFormatter.numberFormat = "#";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 37;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.baseGrid.strokeDasharray = "3,2";
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 10;
    dateAxis.fontWeight = "600";
    // dateAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.strokeDasharray = "3,2";
    valueAxis.renderer.minGridDistance = 25;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "600";
    // valueAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    // valueAxis.renderer.labels.text = "{value}%";
    valueAxis.min = 0;
    valueAxis.maxPrecision = 0;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;

    series.stroke = am4core.color("#4BCAD4");
    series.fill = am4core.color("#4BCAD4");
    series.fillOpacity = 0.15;

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    series.segments.template.fillModifier = fillModifier;

    // series.bullets.push(new am4charts.CircleBullet());
    // if (sharers) {
    //     series.data = sharers.sort(function (a, b) {
    //         return new Date(a.date) - new Date(b.date);
    //     }).map(dataItem => {
    //         const month = dataItem.date.slice(5, 7);
    //         const day = dataItem.date.slice(8);
    //         const year = dataItem.date.slice(0, 4);
    //         return { "date": new Date(year, month - 1, day), "value": dataItem.sharers }
    //     });
    // }

    seriesRef.current = series;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    if (accountsSummaryByDate && accountsSummaryByDate.length > 0) {
      const avgDataByDate: IChartItem[] =
        sortItemsWithDate<IAccountsSummaryByDate>(accountsSummaryByDate).map(
          (item) => {
            return { date: item.date, value: item.origItem.components.average };
          }
        );
      seriesRef.current.data = avgDataByDate;
    }
  }, [accountsSummaryByDate]);

  useEffect(() => {
    if (isLoadingAccountsSummaryByDate || isErrorAccountsSummaryByDate) {
      seriesRef.current.data = [];
    }
  }, [isLoadingAccountsSummaryByDate, isErrorAccountsSummaryByDate]);

  useEffect(() => {
    if (isLoadingAccountsSummaryByDate) {
      setNonChartContent(<Loading />);
    } else if (accountsSummaryByDate === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorAccountsSummaryByDate) {
      console.warn(
        "Failed to lookup for accounts summary by date data",
        errorAccountsSummaryByDate?.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingAccountsSummaryByDate,
    accountsSummaryByDate,
    isErrorAccountsSummaryByDate,
    errorAccountsSummaryByDate,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Average Households per Account</div>
          </div>
          <div id="avgHHPerAccountChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};

export default AvgHHPerAccount;
