import { useState } from "react";

import HouseholdActivity from "./HouseholdActivity";
import DevicesTable from "./DevicesTable";
import { AccountHouseholdsProvider } from "../../../../../contexts/accountHouseholdsContext";

import classes from "./WatchTimeTab.module.css";

const WatchTimeTab = (props) => {
  const [householdActivityLoaded, setHouseholdActivityLoaded] = useState(false);

  const setHouseholdActivityLoadedHandler = (householdActivityLoaded) => {
    setHouseholdActivityLoaded(householdActivityLoaded);
  };

  return (
    <AccountHouseholdsProvider>
      <div className={classes.container}>
        <HouseholdActivity
          setHouseholdActivityLoaded={setHouseholdActivityLoadedHandler}
          isFullScreenView={props.isFullScreenView}
        />
        {householdActivityLoaded && <DevicesTable />}
      </div>
    </AccountHouseholdsProvider>
  );
};

export default WatchTimeTab;
