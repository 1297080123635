// eslint-disable-next-line
const baseUrl = process.env.REACT_APP_ACCOUNT_PORTAL_API_ACCOUNT;
const baseHeaders = {
  "content-type": "application/json",
};

const getAll = (params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/tenancyManager/tenancies`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const getByProjectId = (projectId, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/projects/${projectId}/tenants`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const getById = (id, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/tenancyManager/tenancies/${id}`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const postTenant = (tenant, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "POST",
    url: `${baseUrl}/tenancyManager/createTenancy`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
    body: JSON.stringify(tenant),
  };
};

export { getAll, getByProjectId, getById, postTenant };
