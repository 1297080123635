import React from "react";
import PropTypes from "prop-types";
import classes from "./PageBody.module.css";

const PageBody = (props) => {
  return <div className={classes.pageBody}>{props.children}</div>;
};

PageBody.propTypes = {
  children: PropTypes.node,
};

export default PageBody;
