import { createContext, useState, useMemo } from "react";

export const AccountDevicesContext = createContext();

export const AccountDevicesProvider = (props) => {
  const [accountDevices, setAccountDevices] = useState([]);
  const [isLoadingAccountDevices, setIsLoadingAccountDevices] = useState([]);
  const [isErrorAccountDevices, setIsErrorAccountDevices] = useState([]);
  const [errorAccountDevices, setErrorAccountDevices] = useState([]);

  const setAccountDevicesRequestData = (
    accountDevices,
    isLoadingAccountDevices,
    isErrorAccountDevices,
    errorAccountDevices
  ) => {
    setAccountDevices(accountDevices);
    setIsLoadingAccountDevices(isLoadingAccountDevices);
    setIsErrorAccountDevices(isErrorAccountDevices);
    setErrorAccountDevices(errorAccountDevices);
  };

  const accountDevicesProviderValue = useMemo(
    () => ({
      accountDevices,
      setAccountDevices,
      isLoadingAccountDevices,
      setIsLoadingAccountDevices,
      isErrorAccountDevices,
      setIsErrorAccountDevices,
      errorAccountDevices,
      setErrorAccountDevices,
      setAccountDevicesRequestData,
    }),
    [
      accountDevices,
      isLoadingAccountDevices,
      isErrorAccountDevices,
      errorAccountDevices,
    ]
  );

  return (
    <AccountDevicesContext.Provider value={accountDevicesProviderValue}>
      {props.children}
    </AccountDevicesContext.Provider>
  );
};
