import React from "react";
import SynamediaLogo from "../../assets/img/synamedia_logo.svg";

import classes from "./SynamediaLogo.module.css";

const Logo = () => {
  return (
    <div className={classes.logo_wrapper}>
      <img src={SynamediaLogo} alt="sidebar icon" className={classes.logo} />
    </div>
  );
};

export default Logo;
