import { createContext, useState, useMemo, useCallback } from "react";
import {
  ERROR_MODAL_STATE,
  ERROR_MODAL_TYPE,
  IError,
} from "../components/UI/ErrorModal";

export const ErrorHandlingContext = createContext();

export const ErrorHandlingProvider = (props) => {
  const [error, setError] = useState<IError>(null);

  const createError = useCallback(
    (error: IError | ERROR_MODAL_TYPE): void => {
      const defaultAuthError: IError = {
        errorType: ERROR_MODAL_TYPE.AUTH,
        errorState: ERROR_MODAL_STATE.OPEN,
        errorTitle: "Auth Error",
        errorMessage:
          "Your session was time out.\nPlease press continue to be direct to Synamedia main dashboard.",
        buttonText: "continue",
        enableCloseButton: false,
        onClose: () => {
          setErrorState(ERROR_MODAL_STATE.CLOSE);
        },
        onButtonClick: () => {
          setErrorState(ERROR_MODAL_STATE.CLOSE);
          window.location.replace("https://account.synamedia.com");
        },
      };
      const defaultNetworkError: IError = {
        errorType: ERROR_MODAL_TYPE.NETWORK,
        errorState: ERROR_MODAL_STATE.OPEN,
        errorTitle: "Network Error",
        errorMessage: "Please check your network connection.",
      };
      if (typeof error == "number") {
        if (Object.values(ERROR_MODAL_TYPE).includes(error)) {
          const errorObj: IError = { errorType: error } as IError;
          createError(errorObj);
        }
      } else {
        if (error.errorType == ERROR_MODAL_TYPE.AUTH) {
          setError({
            errorState: error.errorState
              ? error.errorState
              : defaultAuthError.errorState,
            errorType: error.errorType
              ? error.errorType
              : defaultAuthError.errorType,
            errorTitle: error.errorTitle
              ? error.errorTitle
              : defaultAuthError.errorTitle,
            errorMessage: error.errorMessage
              ? error.errorMessage
              : defaultAuthError.errorMessage,
            buttonText: error.buttonText
              ? error.buttonText
              : defaultAuthError.buttonText,
            enableCloseButton: error.enableCloseButton
              ? error.enableCloseButton
              : defaultAuthError.enableCloseButton,
            onClose: error.onClose
              ? () => {
                  error.onClose();
                  defaultAuthError.onClose();
                }
              : () => {
                  defaultAuthError.onClose();
                },
            onButtonClick: error.onButtonClick
              ? () => {
                  error.onButtonClick();
                  defaultAuthError.onButtonClick();
                }
              : () => {
                  defaultAuthError.onButtonClick();
                },
          });
        } else if (error.errorType == ERROR_MODAL_TYPE.NETWORK) {
          setError({
            errorState: error.errorState
              ? error.errorState
              : defaultNetworkError.errorState,
            errorType: error.errorType
              ? error.errorType
              : defaultNetworkError.errorType,
            errorTitle: error.errorTitle
              ? error.errorTitle
              : defaultNetworkError.errorTitle,
            errorMessage: error.errorMessage
              ? error.errorMessage
              : defaultNetworkError.errorMessage,
          });
        }
      }
    },
    [setErrorState]
  );

  const closeNetworkError = useCallback((error) => {
    if (error?.errorType == ERROR_MODAL_TYPE.NETWORK) {
      setError(
        Object.assign({}, error, { errorState: ERROR_MODAL_STATE.CLOSE })
      );
    }
  }, []);

  const setErrorState = useCallback(
    (state: ERROR_MODAL_STATE) => {
      setError(Object.assign({}, error, { errorState: state }));
    },
    [error]
  );

  const errorHandlingProviderValue = useMemo(
    () => ({
      error,
      createError,
      closeNetworkError,
    }),
    [error, createError, closeNetworkError]
  );

  return (
    <ErrorHandlingContext.Provider value={errorHandlingProviderValue}>
      {props.children}
    </ErrorHandlingContext.Provider>
  );
};
