type objWithDateStringType = { date: string };
export function sortItemsWithDate<T extends objWithDateStringType>(
  items: T[]
): { date: Date; origItem: T }[] {
  const ret = items
    .sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
    .map((dataItem) => {
      const month = dataItem.date.slice(5, 7);
      const day = dataItem.date.slice(8, 10); // Note that some code uses just slice(8) but it's the same
      const year = dataItem.date.slice(0, 4);
      return { date: new Date(+year, +month - 1, +day), origItem: dataItem };
    });
  return ret;
}
