import { useContext, useState } from "react";

import classes from "./HouseholdMultiSelect.module.scss";
import "./HouseholdMultiSelect.css";
import { useAlignedPopperWidth } from "../../../account-info/filters/useAlignedPopperWidth";
import DownArrowForMenuSvg from "../../../../../assets/img/whiteDownArrowForMenu.svg";
import HouseholdLogo from "../../../../../assets/img/whiteHousehold.svg";
import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Popper,
} from "@material-ui/core";

import { AccountHouseholdsContext } from "../../../../../contexts/accountHouseholdsContext";

const HouseholdMultiSelect = () => {
  const [selectAll, setSelectAll] = useState(true);
  const { availableHouseholds, selectedHouseholds, setSelectedHouseholds } =
    useContext(AccountHouseholdsContext);

  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth<HTMLDivElement>();

  const onCheckboxCheckChange = (evt, val) => {
    const isChecked = evt.target.checked;
    if (isChecked) {
      if (!selectedHouseholds.includes(val)) {
        setSelectedHouseholds([...selectedHouseholds, val]);
      }
      if (selectedHouseholds.length == availableHouseholds.length - 1) {
        setSelectAll(true);
      }
    } else {
      if (selectedHouseholds.includes(val)) {
        setSelectedHouseholds(
          [...selectedHouseholds].filter((item) => item != val)
        );
        setSelectAll(false);
      }
    }
  };

  const onSelectAllCheckChange = (evt) => {
    const isChecked = evt.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedHouseholds([...availableHouseholds]);
    } else {
      setSelectedHouseholds([]);
    }
  };

  return (
    <div className={classes.container} ref={elementAlignedToRef}>
      <button
        className={classes.toggleRangePopperBtn}
        type="button"
        onClick={toggleIsPopperOpenClick}
      >
        <img src={HouseholdLogo} className={classes.householdIcon} />
        SELECT HOUSEHOLDS
        <img className={classes.arrowDownSvg} src={DownArrowForMenuSvg} />
      </button>
      <Popper open={isPopperOpen} anchorEl={elementAlignedToRef.current}>
        <ClickAwayListener onClickAway={toggleIsPopperOpenClick}>
          <div
            style={{
              width: popperWidth,
              background: "#25293D",
              border: "1px solid #576079",
              borderTop: "0",
              marginTop: "-1px",
            }}
          >
            <div className={classes.popperContentContainer__selectAll}>
              <FormGroup>
                <FormControlLabel
                  classes={{ root: classes.filterLabelColor }}
                  control={<Checkbox />}
                  label={"Select / Deselect All"}
                  checked={selectAll}
                  onChange={(evt) => {
                    onSelectAllCheckChange(evt);
                  }}
                />
              </FormGroup>
            </div>
            <div className={classes.popperContentContainer}>
              <FormGroup>
                {availableHouseholds.map((label) => {
                  return (
                    <div key={label}>
                      <FormControlLabel
                        classes={{ root: classes.filterLabelColor }}
                        control={<Checkbox />}
                        label={"Household " + label}
                        checked={selectedHouseholds.includes(label)}
                        onChange={(evt) => {
                          onCheckboxCheckChange(evt, label);
                        }}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default HouseholdMultiSelect;
