import React from "react";
import Card from "../../../UI/Card";

import ScoreOverTime from "./ScoreOverTime";
import HouseholdsOverTime from "./HouseholdsOverTime";
import DurationOverTime from "./DurationOverTime";

import classes from "./AccountStatistics.module.css";

const AccountStatistics = () => {
  return (
    <div className={classes.container}>
      <Card>
        <div className={classes.statistics}>
          <div className={classes.header}>Account Statistics</div>
          <div className={classes.main}>
            <ScoreOverTime isFullScreenView={true} />
            <HouseholdsOverTime isFullScreenView={true} />
            <DurationOverTime isFullScreenView={true} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AccountStatistics;
