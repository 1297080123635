import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Card from "../../../UI/Card";

import WatchTimeTab from "./watch-time-tab/WatchTimeTab";
import LocationTab from "./location-tab/LocationTab";
// import LeafletMap from "./location-tab/LeafletMap";

import classes from "./TabsSection.module.css";
import "./TabsSection.css";

const TabsSection = () => {
  const [key, setKey] = useState("home");

  return (
    <div className={classes.container}>
      <Card>
        <div className={classes.main}>
          <Tabs
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
            }}
          >
            <Tab
              eventKey="home"
              title="Viewing Pattern"
              className={classes.tab}
            >
              <WatchTimeTab isFullScreenView={true} />
            </Tab>
            <Tab eventKey="map" title="Location Map" className={classes.tab}>
              {/* <LeafletMap /> */}
              <LocationTab />
            </Tab>
          </Tabs>
        </div>
      </Card>
    </div>
  );
};

TabsSection.propTypes = {
  accountID: PropTypes.string,
};

export default TabsSection;
