export function secondsToHours(val: number): number {
  return Math.round(val / 3600);
}

export function secondsToHoursFloor(val: number): number {
  return Math.floor(val / 3600);
}

export function secondsToHoursCeil(val: number): number {
  return Math.ceil(val / 3600);
}

export function hoursToSecondsFloor(val: number): number {
  return val * 3600 - 3599; // -3599 because we used Math.ceil on secondsToHours we want the minimum value to inclue all range
}

export function hoursToSecondsCeil(val: number): number {
  return val * 3600;
}
