export interface IAccount {
  account_id: string;
  score: number;
  label: string;
  components: number;
  sum_duration: number;
  device_types: number;
  devices: number;
  locations: number;
  sessions: number;
  tags: string[];
}

export interface INumericValues {
  sum: number;
  average: number;
  median: number;
  min: number;
  max: number;
}

export interface IAccountsSummary {
  capped_sessions: INumericValues;
  components: INumericValues;
  cities: INumericValues;
  devices: INumericValues;
  sessions: INumericValues;
  device_types: INumericValues;
  duration: INumericValues;
  score: INumericValues;
  account_ids: {
    distinct: number;
  };
  start_time: {
    min: string;
    max: string;
  };
  end_time: {
    min: string;
    max: string;
  };
}

export interface IAccountsSummaryByDate {
  date: string;
  capped_sessions: INumericValues;
  components: INumericValues;
  cities: INumericValues;
  devices: INumericValues;
  sessions: INumericValues;
  device_types: INumericValues;
  duration: INumericValues;
  score: INumericValues;
  account_ids: {
    distinct: number;
  };
  start_time: {
    min: string;
    max: string;
  };
  end_time: {
    min: string;
    max: string;
  };
}

export interface IScoreTrend {
  date: string;
  score: number;
}

export interface IScoresDistributionByDate {
  date: string;
  bin: string;
  accounts: number;
}

export interface ILabelsAndTags {
  labels: ILabel[];
  tags: ITag[];
}

export interface IScoresTransition {
  no_activity: number;
  honest: number;
  score_decreased: number;
  same_score: number;
  score_increased: number;
}

export interface ILabel {
  label: string;
  account_ids: number;
}

export interface ITag {
  tag: string;
  account_ids: number;
}

export enum ACCOUNT_FIELDNAMES {
  SCORE = "score",
  DURATION = "sum_duration",
  HOUSEHOLDS = "components",
  LABEL = "label",
  TAGS = "tags",
  FILTER_LIST = "filterList",
}

export enum ACCOUNT_FILTER_LIST_FIELDNAMES {
  ACCOUNTS_LIST = "accounts_list",
}
export enum ACCOUNT_LABELNAMES {
  SCORE = "Score",
  DURATION = "Duration",
  HOUSEHOLDS = "Households",
  TAGS = "Tags",
}

type fieldInfoValues = {
  //[key in ACCOUNT_FIELDNAMES]: {field: ACCOUNT_FIELDNAMES, label: ACCOUNT_LABELNAMES};
  [key: string]: { field: ACCOUNT_FIELDNAMES; label: ACCOUNT_LABELNAMES };
};

export const ACCOUNT_FIELD_INFOS: fieldInfoValues = {
  score: { field: ACCOUNT_FIELDNAMES.SCORE, label: ACCOUNT_LABELNAMES.SCORE },
  duration: {
    field: ACCOUNT_FIELDNAMES.DURATION,
    label: ACCOUNT_LABELNAMES.DURATION,
  },
  households: {
    field: ACCOUNT_FIELDNAMES.HOUSEHOLDS,
    label: ACCOUNT_LABELNAMES.HOUSEHOLDS,
  },
};

export interface IRangeFieldDistributionInfo {
  min: number;
  max: number;
  percentile_05: number;
  percentile_25: number;
  percentile_50: number;
  percentile_75: number;
  percentile_95: number;
}

export interface IRangeFieldInfo {
  min: number;
  max: number;
  step: number;
  infiniteRange: boolean;
  current: [number, number];
  query: [number, number];
  isDisplayed: boolean;
}

// For labels and tags
export interface IStringItemsFieldInfo {
  available: string[];
  current: string[];
  query: string[];
  isDisplayed: boolean;
}

export interface IImportListInfo {
  current: File;
  query: string;
  isDisplayed: boolean;
  uploadeFileState: UPLOAD_STATES;
  uuid: number;
}

export interface ISummaryData {
  score: number;
  components: number;
  viewing_duration_seconds: number;
  device_types?: number;
  devices?: number;
  locations?: number;
  sessions?: number;
  definition?: string;
}

export interface IOrderBy {
  fieldName: ACCOUNT_FIELDNAMES;
  isAsc: boolean;
}

export enum UPLOAD_STATES {
  INIT,
  UPLOADING,
  UPLOAD_SUCCEEDED,
  UPLOAD_FAILED,
}

export interface FiltersProperties {
  limit: number;
  filters: string[];
  tags: string[];
  importListFilters: string[];
  orderBy: IOrderBy;
}
