import React, { useContext, useState } from "react";
import { useRef, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from "../../../UI/Card";

import classes from "./AvgExcessViewing.module.scss";

import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import {
  getAccountsSummaryByDate,
  getSummaryAccountsByDate,
} from "../../../../external-apis";

import { sortItemsWithDate } from "../../../../utils/statisticsUtils";

import configData from "../../../../config.js";
import { IAccountsSummaryByDate } from "../../../../services/accountInterfaces";
import { AccountsContext } from "../../../../contexts/accountsContext";

interface IAvrDurationOverTimeData {
  date: string;
  duration: { average: number };
}

interface IChartItem {
  date: Date;
  value: number;
  // numericValue: number;
}

const AvgExcessViewing = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const { filtersProperties } = useContext(AccountsContext);

  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const avgSeriesRef = useRef(null);

  const {
    data: accountsSummaryByDate,
    isLoading: isLoadingAccountsSummaryByDate,
    isError: isErrorAccountsSummaryByDate,
    error: errorAccountsSummaryByDate,
  } = useQueryWithAuthorization(
    ["accountsSummaryByDate", filtersProperties],
    getAccountsSummaryByDate(filtersProperties)
  );

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("avgExcessViewingChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 5;
    chart.paddingTop = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = -10;

    chart.dateFormatter.dateFormat = "dd-MMM-yyyy";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 40;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.renderer.baseGrid.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 10;
    dateAxis.fontWeight = "600";
    // dateAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.strokeDasharray = "3,2";
    valueAxis.renderer.minGridDistance = 25;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "600";
    // valueAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    // valueAxis.renderer.labels.text = "{value}%";
    valueAxis.min = 0;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#h";

    // valueAxis.max = 0.3;

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.name = "Avg. Account Viewing Duration";
    series.dummyData = "dummyData";
    series.strokeWidth = 2;
    series.fill = am4core.color("#3380FF");
    series.stroke = am4core.color("#3380FF");
    // series.fillOpacity = 0.15;
    series.columns.template.width = am4core.percent(70);
    series.columns.template.tooltipText =
      "{date}\n{value.formatNumber('#.##')} Hours\n{dummyData.gap}";
    series.columns.template.tooltip = new am4core.Tooltip();
    series.columns.template.tooltip.getFillFromObject = false;
    series.columns.template.tooltip.label.fill = am4core.color("#000");
    series.columns.template.tooltip.label.fontSize = 10;
    series.columns.template.tooltip.background.stroke =
      am4core.color("#FEFEFE");
    series.columns.template.tooltip.background.fill = am4core.color("#FEFEFE");
    series.columns.template.adapter.add("tooltipText", function (text, target) {
      const avgValue = target._dataItem.dataContext["dummyData"].avgValue;
      const duration = target._dataItem.dataContext["value"];
      const percentGap = Math.round((duration / avgValue) * 100);
      if (percentGap > 100) {
        // duration = 350, avg = 50 -> duration/avg*100 = 700% -> 600% over hpnest account
        return (
          "{date}\n{value.formatNumber('#.##')} Hours\n +" +
          (percentGap - 100) +
          "% over avg honest acount"
        );
      } else if (percentGap < 100) {
        // duration = 450, avg = 500 -> 100 - (duration/avg*100) = 10% below honest account
        return (
          "{date}\n{value.formatNumber('#.##')} Hours\n" +
          (100 - percentGap) +
          "% below avg honest acount"
        );
      } else {
        return "{date}\n{value.formatNumber('#.##')} Hours\n as avg honest acount";
      }
    });

    const hoverState = series.columns.template.states.create("hover");
    hoverState.properties.stroke = am4core.color("#ffffff");

    seriesRef.current = series;

    // Create series
    const avgSeries = chart.series.push(new am4charts.LineSeries());
    avgSeries.dataFields.valueY = "value";
    avgSeries.dataFields.dateX = "date";
    avgSeries.name = "Avg. Honest Account Viewing Duration";
    avgSeries.fill = am4core.color("#F0F0F0");
    avgSeries.strokeWidth = 2;

    avgSeries.stroke = am4core.color("#F0F0F0");
    avgSeriesRef.current = avgSeries;

    chart.legend = new am4charts.Legend();
    chart.legend.contentAlign = "center";
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontWeight = "normal";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = -3;
    chart.legend.paddingTop = -10;

    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 8;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const {
    data: avgData,
    isLoading: isLoadingAvgData,
    isError: isErrorAvgData,
    error: errorAvgData,
  } = useQueryWithAuthorization(
    "summaryAccountsByDate",
    getSummaryAccountsByDate()
  );

  useDeepCompareEffect(() => {
    if (avgData) {
      const duration: IChartItem[] =
        sortItemsWithDate<IAvrDurationOverTimeData>(avgData).map((item) => {
          return {
            date: item.date,
            value: item.origItem.duration.average / 3600,
          };
        });
      avgSeriesRef.current.data = duration;
    }
  }, [avgData]);

  useDeepCompareEffect(() => {
    if (accountsSummaryByDate && accountsSummaryByDate.length > 0 && avgData) {
      const avgDataByDate: IChartItem[] =
        sortItemsWithDate<IAccountsSummaryByDate>(accountsSummaryByDate).map(
          (item) => {
            // return { date: item.date, value: item.origItem.duration.average };
            const avgDurationForHonestsAccounts = avgData.find((obj) =>
              obj.date.startsWith(item.origItem.date)
            )?.duration?.average;
            return {
              date: item.date,
              value: item.origItem.duration.average / 3600,
              dummyData: {
                avgValue: avgDurationForHonestsAccounts
                  ? avgDurationForHonestsAccounts / 3600
                  : 0,
              },
            };
          }
        );

      seriesRef.current.data = avgDataByDate;
    }
  }, [accountsSummaryByDate, avgData]);

  useEffect(() => {
    if (isLoadingAccountsSummaryByDate || isErrorAccountsSummaryByDate) {
      seriesRef.current.data = [];
    }
    if (isLoadingAvgData || isErrorAvgData) {
      avgSeriesRef.current.data = [];
    }
  }, [
    isLoadingAccountsSummaryByDate,
    isErrorAccountsSummaryByDate,
    isLoadingAvgData,
    isErrorAvgData,
  ]);

  useEffect(() => {
    if (isLoadingAccountsSummaryByDate || isLoadingAvgData) {
      setNonChartContent(<Loading />);
    } else if (accountsSummaryByDate === null || avgData === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorAccountsSummaryByDate) {
      console.warn(
        "Failed to lookup for accounts duration over time data",
        errorAccountsSummaryByDate?.message
      );
      setNonChartContent(<ErrorMessage />);
    } else if (isErrorAvgData) {
      console.warn(
        "Failed to lookup for average duration data",
        errorAvgData.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingAccountsSummaryByDate,
    isLoadingAvgData,
    accountsSummaryByDate,
    avgData,
    isErrorAccountsSummaryByDate,
    isErrorAvgData,
    errorAccountsSummaryByDate,
    errorAvgData,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Excess Viewing</div>
            <div className={classes.verticalDivider}></div>
            <div className={classes.subtitle}>
              Avg Sharer account viewing vs. avg Honest account viewing
            </div>
          </div>
          <div id="avgExcessViewingChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};

export default AvgExcessViewing;
