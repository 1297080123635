import axios from "axios";

axios.defaults.headers.common = {};
axios.defaults.headers.post = {};
axios.defaults.headers.patch = {};
axios.defaults.headers.put = {};

const fetchFn = (externalApiObj = {}) => {
  return async () => {
    const { url, method, headers, body, onDownloadProgress } = externalApiObj;
    const res = await axios({
      method,
      url,
      data: body,
      headers,
      onDownloadProgress,
    });
    return res.data;
  };
};

export default fetchFn;
