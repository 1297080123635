import create from "zustand";

const initialState = Object.freeze({
  accountIdOrAlias: undefined,
  orgId: undefined,
  logoUrl: "",
});

const useStore = create((set) => ({
  ...initialState,

  setAccountIdOrAlias: (val) => {
    set(() => ({
      accountIdOrAlias: val,
    }));
  },
  setOrgId: (val) => {
    set(() => ({
      orgId: val,
    }));
  },
  setLogoUrl: (val) => {
    set(() => ({
      logoUrl: val,
    }));
  },
  reset: () => {
    set(() => ({ ...initialState }));
  },
}));

export default useStore;
