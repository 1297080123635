import React from "react";
import { useRef, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from "../../UI/Card";

import classes from "./SharingOverTime.module.css";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import NoData from "../../UI/NoData";

import { getSummarySharersByDate } from "../../../external-apis";

import { sortItemsWithDate } from "../../../utils/statisticsUtils";

import configData from "../../../config.js";

interface IChartItem {
  date: Date;
  value: number;
  numericValue: number;
}

interface ISharerData {
  date: string;
  sharers: number;
  percent: number;
}

const SharingOverTime = () => {
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const bulletRef = useRef(null);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("sharingOverTimeChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 15;
    chart.paddingTop = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = -10;

    chart.dateFormatter.dateFormat = "dd MMM yyyy";
    chart.numberFormatter.numberFormat = "#%";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 40;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 12;
    dateAxis.fontWeight = "normal";
    dateAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    dateAxis.baseInterval = {
      timeUnit: "day",
      count: 1,
    };

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 25;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "normal";
    valueAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    valueAxis.min = 0;
    valueAxis.max = 0.4;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;

    series.stroke = am4core.color("#3380FF");
    series.fill = am4core.color("#3380FF");
    series.fillOpacity = 0.15;

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    series.segments.template.fillModifier = fillModifier;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 0;
    bullet.circle.radius = 1;
    bulletRef.current = bullet;

    seriesRef.current = series;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const {
    data: summarySharersByDate,
    isLoading: isLoadingSummarySharersByDate,
    isError: isErrorSummarySharersByDate,
    error: errorSummarySharersByDate,
  } = useQueryWithAuthorization(
    ["summarySharersByDate"],
    getSummarySharersByDate()
  );

  useDeepCompareEffect(() => {
    if (summarySharersByDate) {
      const sharersByDate: IChartItem[] = sortItemsWithDate<ISharerData>(
        summarySharersByDate
      ).map((item) => {
        return {
          date: item.date,
          value: +(item.origItem.percent / 100).toFixed(2),
          numericValue: item.origItem.sharers,
        };
      });
      seriesRef.current.data = sharersByDate;
      bulletRef.current.circle.radius = sharersByDate.length === 1 ? 4 : 1;
    }
  }, [summarySharersByDate]);

  useEffect(() => {
    if (isLoadingSummarySharersByDate || isErrorSummarySharersByDate) {
      seriesRef.current.data = [];
    }
  }, [isLoadingSummarySharersByDate, isErrorSummarySharersByDate]);

  let nonChartContent = null;
  let showChart = false;

  if (isLoadingSummarySharersByDate) {
    nonChartContent = <Loading />;
  } else if (summarySharersByDate === null) {
    nonChartContent = <NoData />;
  } else if (isErrorSummarySharersByDate) {
    console.warn(
      "Failed to lookup for sharers over time data",
      errorSummarySharersByDate.message
    );
    nonChartContent = <ErrorMessage />;
  } else {
    showChart = true;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>
              Percentage of Sharer Accounts Activity
            </div>
          </div>
          <div id="sharingOverTimeChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};

export default SharingOverTime;
