import { useQueryWithAuthorization } from "../../custom-hooks";

import { getHealthStatus } from "../../external-apis/api";

const HealthCheck = ({ children }) => {
  const refetchInterval = 1000 * 60 * 60; // One hour interval

  useQueryWithAuthorization(["healthCheck"], getHealthStatus(), {
    refetchInterval: refetchInterval,
    staleTime: refetchInterval,
    cacheTime: 0,
    retry: 0,
    refetchOnWindowFocus: true,
  });

  return children;
};

export default HealthCheck;
