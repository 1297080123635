import React, { useEffect, useState, useContext } from "react";
import { useRef, useLayoutEffect } from "react";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../../custom-hooks";

import { ILabel, ITag } from "../../../../services/accountInterfaces";

import Card from "../../../UI/Card";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import classes from "./UseCases.module.scss";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import configData from "../../../../config.js";
import { getFilteredLabelsAndTags } from "../../../../external-apis";
import { AccountsContext } from "../../../../contexts/accountsContext";

export interface IChartCategoryDataObj {
  category: string;
  Sharer?: number;
  Honest?: number;
}

const UseCases = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const chartRef = useRef(null);
  const seriesColor = { Sharer: "#3380FF", Honest: "#4BCAD4" };

  const { accounts, filtersProperties } = useContext(AccountsContext);

  const {
    data: labelsAndTags,
    isLoading: isLoadingLabelsAndTags,
    isError: isErrorLabelsAndTags,
    error: errorLabelsAndTags,
  } = useQueryWithAuthorization(
    ["filteredLabelsAndTags", filtersProperties],
    getFilteredLabelsAndTags(filtersProperties)
  );

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = am4core.create("sharingStatusChart", am4charts.XYChart);

    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 0;
    chart.paddingTop = 10;
    chart.paddingBottom = -15;
    chart.paddingLeft = -15;

    chart.numberFormatter.numberFormat = "#.###";

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.strokeDasharray = "3,2";
    categoryAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    categoryAxis.renderer.baseGrid.strokeDasharray = "3,2";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.fontFamily = "Source Sans Pro, sans-serif";
    categoryAxis.fontSize = 10;
    categoryAxis.fontWeight = "600";
    categoryAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 85;
    label.tooltipText = "{category}";

    const range = categoryAxis.axisRanges.create();
    range.category = "All Accounts";
    range.grid.stroke = am4core.color("#E1E1E1");
    range.grid.strokeOpacity = 1;
    range.grid.strokeWidth = 1;
    range.grid.location = 1;
    range.grid.above = true;
    range.grid.strokeDasharray = "1,0";

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 1;
    valueAxis.strictMinMax = true;
    // valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.strokeDasharray = "3,2";
    valueAxis.renderer.minGridDistance = 15;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "600";
    valueAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");

    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#%";

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.contentAlign = "center";
    chart.legend.maxWidth = undefined;
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 13;
    chart.legend.labels.template.fontWeight = "600";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = 5;
    chart.legend.paddingTop = -10;

    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 8;

    chart.maskBullets = false;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const createSeries = (label: string) => {
    const series = chartRef.current.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = label;
    series.dummyData = "dummyData";
    series.stacked = true;
    series.name = label + " Accounts";
    series.fill = am4core.color(seriesColor[label]);
    series.strokeWidth = 0;

    series.columns.template.width = am4core.percent(33);
    series.tooltip.pointerOrientation = "vertical";
    series.columns.template.tooltip = new am4core.Tooltip();
    series.columns.template.tooltip.getFillFromObject = false;
    series.columns.template.tooltip.label.fill = am4core.color("#000");
    series.columns.template.tooltip.label.fontSize = 10;
    series.columns.template.tooltip.background.strokeWidth = 2;
    series.columns.template.tooltip.background.stroke =
      am4core.color("#FEFEFE");
    series.columns.template.tooltip.background.fill = am4core.color("#FEFEFE");
  };

  useDeepCompareEffect(() => {
    if (labelsAndTags) {
      chartRef.current.series.clear();
      const dataObj: IChartCategoryDataObj[] = [{ category: "All Accounts" }];
      labelsAndTags.labels
        ?.sort((a, b) => a.label - b.label)
        .map((labelObj: ILabel) => {
          dataObj[0][labelObj.label] = labelObj.account_ids / accounts.length;
          createSeries(labelObj.label);
        });
      labelsAndTags.tags
        ?.sort((a, b) => b.account_ids - a.account_ids)
        ?.map((tagObj: ITag) => {
          dataObj.push({
            category: tagObj.tag,
            Sharer: tagObj.account_ids / accounts.length,
          });
        });

      chartRef.current.data = dataObj;
    }
  }, [labelsAndTags]);

  useEffect(() => {
    if (isLoadingLabelsAndTags || isErrorLabelsAndTags) {
      chartRef.current.data = [];
    }
  }, [isLoadingLabelsAndTags, isErrorLabelsAndTags]);

  useEffect(() => {
    if (isLoadingLabelsAndTags) {
      setNonChartContent(<Loading />);
    } else if (labelsAndTags === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorLabelsAndTags) {
      console.warn(
        "Failed to lookup for labels and tags data",
        errorLabelsAndTags?.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingLabelsAndTags,
    labelsAndTags,
    isErrorLabelsAndTags,
    errorLabelsAndTags,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Use Cases</div>
          </div>
          <div id="sharingStatusChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};

export default UseCases;
