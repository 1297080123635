import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import classes from "./ScoreOverTime.module.css";

import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import {
  getAccountScoreByDate,
  getSummaryAccountsByDate,
} from "../../../../external-apis";
import { sortItemsWithDate } from "../../../../utils/statisticsUtils";
import { IChartItem } from "./IChartItem";

import configData from "../../../../config.js";

import { AccountsContext } from "../../../../contexts/accountsContext";
interface IAvrScoreData {
  date: string;
  score: { average: number };
}

interface IScoreData {
  date: string;
  score: number;
}

const ScoreOverTime = (props) => {
  const chartRef = useRef(null);
  const avgSeriesRef = useRef(null);
  const accountSeriesRef = useRef(null);
  const avgBulletRef = useRef(null);
  const accountBulletRef = useRef(null);

  const { selectedAccount } = useContext(AccountsContext);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("scoreOverTimeChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.paddingRight = 20;
    chart.paddingTop = 15;
    chart.paddingBottom = -17;
    chart.paddingLeft = -5;

    chart.dateFormatter.dateFormat = "dd MMM yyyy";

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 35;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 12;
    dateAxis.fontWeight = "bold";
    dateAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    dateAxis.renderer.labels.template.dy = -7;
    dateAxis.baseInterval = {
      timeUnit: "day",
      count: 1,
    };

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 16;
    // valueAxis.extraMax = 0.2;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "bold";
    valueAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.maxPrecision = 0;

    // Create series
    const avgSeries = chart.series.push(new am4charts.LineSeries());
    avgSeries.dataFields.valueY = "value";
    avgSeries.dataFields.dateX = "date";
    avgSeries.name = "Average Score";
    avgSeries.fill = am4core.color("#F0F0F0");
    avgSeries.strokeWidth = 2;
    avgSeries.stroke = am4core.color("#F0F0F0");

    avgSeriesRef.current = avgSeries;

    const avgBullet = avgSeries.bullets.push(new am4charts.CircleBullet());
    avgBullet.circle.stroke = am4core.color("#fff");
    avgBullet.circle.strokeWidth = 0;
    avgBullet.circle.radius = 1;
    avgBulletRef.current = avgBullet;

    // var labelBullet = new am4charts.LabelBullet();
    // labelBullet.locationX = 1;
    // labelBullet.label.text = 'Avg.';
    // labelBullet.label.fill = am4core.color("#fff");
    // labelBullet.label.fontWeight = 'bold';

    // avgSeries.events.on("validated", function () {
    //     if (avgSeries.dataItems.length > 0) {
    //         avgSeries.dataItems.last.bullets.push(labelBullet)
    //     }
    // }
    // )

    const accountSeries = chart.series.push(new am4charts.LineSeries());
    accountSeries.dataFields.valueY = "value";
    accountSeries.dataFields.dateX = "date";
    accountSeries.name = "Account Score";
    accountSeries.fill = am4core.color("#3380FF");
    accountSeries.strokeWidth = 2;
    accountSeries.stroke = am4core.color("#3380FF");
    accountSeries.fillOpacity = 0.15;
    // var gradient = new am4core.LinearGradient();
    // gradient.addColor(am4core.color(rgba(51, 128, 255, 0.15)));
    // gradient.addColor(am4core.color(rgba(51, 128, 255, 0)));
    // accountSeries.fill = gradient;
    // accountSeries.fill = am4core.color('#e8a2c6');

    const bullet = accountSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 0;
    bullet.circle.radius = 1;
    accountBulletRef.current = bullet;

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    accountSeries.segments.template.fillModifier = fillModifier;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.contentAlign = "center";
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontWeight = "normal";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = -3;
    chart.legend.paddingTop = -25;

    const marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(0, 0, 0, 0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 8;
    // accountSeries.bullets.push(new am4charts.CircleBullet());

    accountSeriesRef.current = accountSeries;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const {
    data: accountScoreByDate,
    isLoading: isLoadingAccountScoreByDate,
    isError: isErrorAccountScoreByDate,
    error: errorAccountScoreByDate,
  } = useQueryWithAuthorization(
    ["accountScoreByDate", selectedAccount],
    getAccountScoreByDate(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  useDeepCompareEffect(() => {
    if (accountScoreByDate) {
      const score: IChartItem[] = sortItemsWithDate<IScoreData>(
        accountScoreByDate
      ).map((item) => {
        return { date: item.date, value: item.origItem.score };
      });
      accountSeriesRef.current.data = score;
      accountBulletRef.current.circle.radius = score.length === 1 ? 4 : 1;
    }
  }, [accountScoreByDate]);

  const {
    data: summaryAccountsByDate,
    isLoading: isLoadingSummaryAccountsByDate,
    isError: isErrorSummaryAccountsByDate,
    error: errorSummaryAccountsByDate,
  } = useQueryWithAuthorization(
    "summaryAccountsByDate",
    getSummaryAccountsByDate()
  );

  useDeepCompareEffect(() => {
    if (summaryAccountsByDate && accountScoreByDate) {
      const score: IChartItem[] = sortItemsWithDate<IAvrScoreData>(
        summaryAccountsByDate
      )
        .filter((item) =>
          accountScoreByDate.some((e) => item.origItem.date.startsWith(e.date))
        )
        .map((item) => {
          return { date: item.date, value: item.origItem.score.average };
        });
      avgSeriesRef.current.data = score;
      avgBulletRef.current.circle.radius = score.length === 1 ? 4 : 1;
    }
    // setContent(buildContent());
  }, [summaryAccountsByDate, accountScoreByDate]);

  useEffect(() => {
    if (isLoadingSummaryAccountsByDate || isErrorSummaryAccountsByDate) {
      avgSeriesRef.current.data = [];
    }
    if (isLoadingAccountScoreByDate || isErrorAccountScoreByDate) {
      accountSeriesRef.current.data = [];
    }
  }, [
    isLoadingSummaryAccountsByDate,
    isLoadingAccountScoreByDate,
    isErrorAccountScoreByDate,
    isErrorSummaryAccountsByDate,
  ]);

  //let content = <div className={classes.title}>Score</div>;

  // let content = <div className={classes.main}>
  //     <div className={classes.title}>Score</div>
  //     <div id="scoreOverTimeChart" className={classes.chart}></div>
  // </div>;
  let nonChartContent = null;
  let showChart = false;
  if (isLoadingAccountScoreByDate || isLoadingSummaryAccountsByDate) {
    nonChartContent = <Loading />;
  } else if (accountScoreByDate === null) {
    nonChartContent = <NoData />;
  } else if (isErrorAccountScoreByDate) {
    console.warn(
      "Failed to lookup for account score data",
      errorAccountScoreByDate.message
    );
    nonChartContent = <ErrorMessage />;
  } else if (isErrorSummaryAccountsByDate) {
    console.warn(
      "Failed to lookup for average score data",
      errorSummaryAccountsByDate.message
    );
    nonChartContent = <ErrorMessage />;
  } else {
    showChart = true;
  }

  return (
    <div
      className={
        props.isFullScreenView ? classes.fullScreenView : classes.tabsView
      }
    >
      <div className={classes.container}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}

        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.title}>Score</div>
          <div id="scoreOverTimeChart" className={classes.chart}></div>
        </div>
      </div>
    </div>
  );
};

ScoreOverTime.propTypes = {
  accountID: PropTypes.string,
  isFullScreenView: PropTypes.bool,
};

export default ScoreOverTime;
