import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./Loading.module.css";

const Loading = (props) => {
  return (
    <div className={`${classes.loading__container} ${props.className}`}>
      <div className={classes.loading__wrapper}>
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress color="inherit" size="1.5rem" />
        </Stack>
        <div className={classes.loading__text}>
          {props.loadingText || "Loading..."}
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  loadingText: PropTypes.string,
};

export default Loading;
