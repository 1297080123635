import Page from "../../UI/Page";
import PageHeader from "../../UI/PageHeader";
import PageBody from "../../UI/PageBody";

import { AccountsContext } from "../../../contexts/accountsContext";
import { AppConfigContext } from "../../../contexts/appConfigContext";

import AccountInfoHeader from "./AccountInfoHeader";
import AccountsTable from "./AccountsTable";
import NoMaxWidthTooltip from "../../UI/NoMaxWidthTooltip";

import { useEffect, useState, useContext } from "react";
import classes from "./AccountInfo.module.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SummaryBar from "../specific-account/summary-bar/SummaryBar";
import ScoreOverTime from "../specific-account/account-statistics/ScoreOverTime";
import DurationOverTime from "../specific-account/account-statistics/DurationOverTime";
import { useHistory } from "react-router";
import Card from "../../UI/Card";
import Modal, { MODAL_TYPES } from "../../UI/Modal";
import AccountInfoTitleRow from "./AccountInfoTitleRow";
import AccountInfoDashboardView from "./account-info-dashboard-view/AccountInfoDashboardView";
import Loading from "../../UI/Loading";
import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../custom-hooks";
import fullScreenIcon from "../../../assets/img/fullScreen.svg";
// import LeafletMap from "../specific-account/tabs-section/location-tab/LeafletMap";
import LocationTab from "../specific-account/tabs-section/location-tab/LocationTab";
import WatchTimeTab from "../specific-account/tabs-section/watch-time-tab/WatchTimeTab";
import HouseholdsOverTime from "../specific-account/account-statistics/HouseholdsOverTime";
import {
  ACCOUNT_FIELDNAMES,
  ACCOUNT_FILTER_LIST_FIELDNAMES,
  IAccount,
  IImportListInfo,
  IRangeFieldInfo,
  IStringItemsFieldInfo,
  IOrderBy,
  FiltersProperties,
} from "../../../services/accountInterfaces";
import {
  hoursToSecondsCeil,
  hoursToSecondsFloor,
  secondsToHours,
} from "../../../utils/timeUnitsConverter";
import dateFormat from "dateformat";

import {
  getAccountsSummaryByDate,
  getAccountsSummary,
  getFilteredLabelsAndTags,
  getFilteredScoresDistributionByDate,
  getFilteredScoresTransition,
  getFilteredScoreTrend,
  getSummary,
  getAllAccounts,
  getAvailableTags,
} from "../../../external-apis/api";

const AccountInfo = () => {
  const {
    accounts,
    setAccounts,
    totalAccounts,
    setTotalAccounts,
    selectedAccount,
    setSelectedAccount,
    isSelectedAccountFromList,
    setSelectedAccountFromList,
    scoreRangeFilterInfo,
    setScoreRangeFilterInfo,
    durationRangeFilterInfo,
    setDurationRangeFilterInfo,
    householdsRangeFilterInfo,
    setHouseholdsRangeFilterInfo,
    labelItemsFilterInfo,
    setLabelItemsFilterInfo,
    tagItemsFilterInfo,
    setTagItemsFilterInfo,
    importListFilterInfo,
    setImportListFilterInfo,
    filtersProperties,
    setFiltersProperties,
    selectedRecordCount,
    setSelectedRecordCount,
    queryRecordCount,
    setQueryRecordCount,
    selectedOrderBy,
    setSelectedOrderBy,
    queryOrderBy,
    setQueryOrderBy,
    getHashedOrRevealedId,
    changePageFunc,
  } = useContext(AccountsContext);

  const { isTrialMode } = useContext(AppConfigContext);

  const history = useHistory();

  const [showExportModal, setShowExportModal] = useState(false);

  const [loadAccounts, setLoadAccounts] = useState(false);
  const [loadAvailableTags, setLoadAvailableTags] = useState(false);

  const view =
    window.location.href.split("/").at(-1) === "action-tracking"
      ? "dashboard"
      : "table";

  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);

  const [hasAccounts, setHasAccounts] = useState<boolean>(false);
  const [filteredAccounts, setFilteredAccounts] = useState<IAccount[]>([]);

  const [searched, setSearched] = useState("");

  const [selectedTabKey, setSelectedTabKey] = useState<string>("overview");

  const MIN_LOADING_TIME = 250;

  const onAccountSelected = (account) => {
    setSelectedAccount(account);
    setSelectedAccountFromList(true);
  };

  const onRecordCountSelectionChange = (recordCount: number) => {
    setSelectedRecordCount(recordCount);
  };

  const onOrderByChange = (val: IOrderBy) => {
    setSelectedOrderBy(val);
  };

  const { data: allAccounts, isLoading: isLoadingAllAccounts } =
    useQueryWithAuthorization(
      ["allAccounts", loadAccounts, filtersProperties],
      getAllAccounts(filtersProperties),
      { enabled: loadAccounts == true && filtersProperties != null }
    );

  const { data: availableTags } = useQueryWithAuthorization(
    ["availableTags", loadAvailableTags],
    getAvailableTags(),
    { enabled: loadAvailableTags == true }
  );

  useQueryWithAuthorization(
    ["accountsSummaryByDate", filtersProperties],
    getAccountsSummaryByDate(filtersProperties),
    { enabled: filtersProperties != null }
  );
  useQueryWithAuthorization(
    ["filteredScoreTrend", filtersProperties],
    getFilteredScoreTrend(filtersProperties),
    { enabled: filtersProperties != null }
  );
  useQueryWithAuthorization(
    ["accountsSummary", filtersProperties],
    getAccountsSummary(filtersProperties),
    { enabled: filtersProperties != null }
  );
  useQueryWithAuthorization(
    ["filteredScoresDistributionByDate", filtersProperties],
    getFilteredScoresDistributionByDate(filtersProperties),
    { enabled: filtersProperties != null }
  );
  useQueryWithAuthorization(
    ["filteredScoresTransition", filtersProperties],
    getFilteredScoresTransition(filtersProperties),
    { enabled: filtersProperties != null }
  );
  useQueryWithAuthorization(["summary"], getSummary(), {
    enabled: filtersProperties != null,
  });
  useQueryWithAuthorization(
    ["filteredLabelsAndTags", filtersProperties],
    getFilteredLabelsAndTags(filtersProperties),
    { enabled: filtersProperties != null }
  );

  const accountsApiFilterFromRangeInfo = (data: IRangeFieldInfo): string[] => {
    const filters = [];
    if (data?.isDisplayed) {
      if (data.current[0] > data.min) {
        filters.push(`>=${data.current[0]}`);
      }
      if (data.current[1] < data.max) {
        filters.push(`<=${data.current[1]}`);
      }
    }
    return filters;
  };

  const accountsApiFilterFromImportList = (data: IImportListInfo): string[] => {
    const filters = [];
    if (data?.isDisplayed) {
      if (data?.uuid) {
        filters.push(`=${data.uuid.toString()}`);
      }
    }
    return filters;
  };

  // Similar to accountsApiFilterFromRangeInfo except need to convert hoursToSeconds
  // This is because accounts' duration is in seconds while min max is in hours
  const accountsApiDurationFilterFromRangeInfo = (
    data: IRangeFieldInfo
  ): string[] => {
    const filters = [];
    if (data?.isDisplayed) {
      if (data.current[0] > data.min) {
        filters.push(`>=${hoursToSecondsFloor(data.current[0])}`);
      }
      if (data.current[1] < data.max) {
        filters.push(`<=${hoursToSecondsCeil(data.current[1])}`);
      }
    }
    return filters;
  };

  const accountsApiLabelFilterFromStringItemsFieldInfo = (
    data: IStringItemsFieldInfo
  ): string[] => {
    const filters = [];
    if (data?.isDisplayed) {
      if (data?.current?.length > 0) {
        filters.push(`==${data.current}`);
      }
    }
    return filters;
  };

  const accountsApiTagFilterFromStringItemsFieldInfo = (
    data: IStringItemsFieldInfo
  ): string[] => {
    const filters = [];
    if (data?.isDisplayed) {
      for (const tag of data.current) {
        filters.push(tag);
      }
    }
    return filters;
  };

  const checkExportOnTrialMode = () => {
    if (isTrialMode) {
      setShowExportModal(true);
    } else {
      onExportAccounts();
    }
  };

  const onExportAccounts = () => {
    const headerRow = "Account ID,Score,Label,Households,Tags,Duration";
    const csvRows: string[] = [headerRow];
    accounts.forEach((account) => {
      const tags = account.tags.join(";");
      const duration = secondsToHours(account.sum_duration);
      const csvRow = `${account.account_id},${account.score},${account.label},${account.components},${tags},${duration}`;
      csvRows.push(csvRow);
    });
    const textToSave = csvRows.join("\r\n");
    const textToSaveAsBlob = new Blob([textToSave], { type: "text/plain" });
    const textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);

    const now = new Date();
    const dateformat = dateFormat(now, "dd-mm-yyyy HH.MM.ss");
    const fileNameToSaveAs = "CSFEye Export " + dateformat + ".csv";

    const downloadLink = document.createElement("a");
    downloadLink.download = fileNameToSaveAs;
    downloadLink.innerHTML = "Download File";
    downloadLink.href = textToSaveAsURL;
    downloadLink.onclick = () => {
      document.body.removeChild(downloadLink);
    };
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();
  };

  const onReloadAccounts = () => {
    const tagFilters: string[] =
      accountsApiTagFilterFromStringItemsFieldInfo(tagItemsFilterInfo);
    const labelFilters: string[] =
      accountsApiLabelFilterFromStringItemsFieldInfo(labelItemsFilterInfo);
    const scoreFilters: string[] =
      accountsApiFilterFromRangeInfo(scoreRangeFilterInfo);
    const durationFilters: string[] = accountsApiDurationFilterFromRangeInfo(
      durationRangeFilterInfo
    );
    const householdsFilters: string[] = accountsApiFilterFromRangeInfo(
      householdsRangeFilterInfo
    );

    const aggregatedFilter: string[] = [
      ...scoreFilters.map((a) => ACCOUNT_FIELDNAMES.SCORE + a),
      ...durationFilters.map((a) => ACCOUNT_FIELDNAMES.DURATION + a),
      ...householdsFilters.map((a) => ACCOUNT_FIELDNAMES.HOUSEHOLDS + a),
      ...labelFilters.map((a) => ACCOUNT_FIELDNAMES.LABEL + a),
    ];

    const importListFilter: string[] =
      accountsApiFilterFromImportList(importListFilterInfo);

    const aggregatedImportListFilter: string[] = [
      ...importListFilter.map(
        (a) => ACCOUNT_FILTER_LIST_FIELDNAMES.ACCOUNTS_LIST + a
      ),
    ];

    const currentFiltersProperties: FiltersProperties = {
      limit: selectedRecordCount,
      filters: aggregatedFilter,
      tags: tagFilters,
      importListFilters: aggregatedImportListFilter,
      orderBy: selectedOrderBy,
    };

    if (
      JSON.stringify(currentFiltersProperties) ===
      JSON.stringify(filtersProperties)
    ) {
      setIsLoadingAccounts(true);
      setTimeout(() => {
        setIsLoadingAccounts(false);
      }, MIN_LOADING_TIME);
      return;
    }

    setFiltersProperties(currentFiltersProperties);
    setLoadAccounts(true);
  };

  useEffect(() => {
    if (accounts == null) {
      setLoadAccounts(true);
      setLoadAvailableTags(true);
    } else {
      setHasAccounts(accounts.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSelectedAccountFromList && accounts?.length > 0) {
      changePageFunc.current = 0;
      setSelectedAccount(accounts[0]);
      setSelectedAccountFromList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedAccountFromList]);

  useEffect(() => {
    if (isLoadingAllAccounts != undefined) {
      setIsLoadingAccounts(isLoadingAllAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAllAccounts]);

  useDeepCompareEffect(() => {
    if (allAccounts) {
      setTotalAccounts(allAccounts.total?.total_accounts || 0);
      const accountsArr = allAccounts.data || [];
      setAccounts(accountsArr);

      setQueryRecordCount(selectedRecordCount);
      setQueryOrderBy(selectedOrderBy);

      if (scoreRangeFilterInfo?.isDisplayed) {
        const updated = {
          ...scoreRangeFilterInfo,
          query: scoreRangeFilterInfo.current,
        };
        setScoreRangeFilterInfo(updated);
      }

      if (durationRangeFilterInfo?.isDisplayed) {
        const updated = {
          ...durationRangeFilterInfo,
          query: durationRangeFilterInfo.current,
        };
        setDurationRangeFilterInfo(updated);
      }

      if (householdsRangeFilterInfo?.isDisplayed) {
        const updated = {
          ...householdsRangeFilterInfo,
          query: householdsRangeFilterInfo.current,
        };
        setHouseholdsRangeFilterInfo(updated);
      }

      if (tagItemsFilterInfo?.isDisplayed) {
        const updated = {
          ...tagItemsFilterInfo,
          query: tagItemsFilterInfo.current,
        };
        setTagItemsFilterInfo(updated);
      }

      if (labelItemsFilterInfo?.isDisplayed) {
        const updated = {
          ...labelItemsFilterInfo,
          query: labelItemsFilterInfo.current,
        };
        setLabelItemsFilterInfo(updated);
      }

      if (importListFilterInfo?.isDisplayed) {
        const updated = {
          ...importListFilterInfo,
          query: importListFilterInfo.uuid.toString(),
        };
        setImportListFilterInfo(updated);
      }

      const roundedScoreAccounts =
        accountsArr.map((account) => {
          account.score = Math.round(account.score);
          return account;
        }) || [];
      setFilteredAccounts(roundedScoreAccounts);

      setHasAccounts(accountsArr.length > 0);

      changePageFunc.current = 0;
      setSelectedAccount(accountsArr[0]);
      setSelectedAccountFromList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccounts]);

  useEffect(() => {
    if (availableTags) {
      const tags = availableTags.map((a) => a.name);
      const filterInfo = {
        available: tags,
        current: [],
        query: tags,
        isDisplayed: false,
      };
      setTagItemsFilterInfo(filterInfo);
    }
  }, [availableTags, setTagItemsFilterInfo]);

  useEffect(() => {
    applyAccountsFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched]);

  const id = selectedAccount?.account_id || "";
  const hashedOrRevealedId = id == "" ? "" : getHashedOrRevealedId(id);

  const onOpenInFullScreen = () => {
    history.push("/home/accounts/" + id);
  };

  const filterScore = (val: IRangeFieldInfo) => {
    const updatedRangeInfo = val;
    setScoreRangeFilterInfo(updatedRangeInfo);
  };

  const filterDuration = (val: IRangeFieldInfo) => {
    const updatedRangeInfo = val;
    setDurationRangeFilterInfo(updatedRangeInfo);
  };

  const filterHouseholds = (val: IRangeFieldInfo) => {
    const updatedRangeInfo = val;
    setHouseholdsRangeFilterInfo(updatedRangeInfo);
  };

  const filterTags = (val: IStringItemsFieldInfo) => {
    const updatedItemsInfo = val;
    setTagItemsFilterInfo(updatedItemsInfo);
  };

  const filterImportList = (val: IImportListInfo) => {
    const updatedInfo = val;
    setImportListFilterInfo(updatedInfo);
  };

  // Label is legacy and isn't sent to server as part of the filter
  const filterLabels = (val: IStringItemsFieldInfo) => {
    const updatedItemsInfo = val;
    setLabelItemsFilterInfo(updatedItemsInfo);
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal); // This triggers the filter in useEffect
  };

  // Depends on two states: searched and availableLabels
  const applyAccountsFilter = () => {
    const searchTerm: string = searched;

    let filteredRows: IAccount[] = accounts
      ? accounts.map((account) => {
          account.score = Math.round(account.score);
          return account;
        })
      : [];
    if (filteredRows.length !== 0) {
      const searchTermLowerCase = searchTerm.toLowerCase().trim();
      if (searchTermLowerCase) {
        filteredRows = filteredRows.filter((account) => {
          return account.account_id.toLowerCase().includes(searchTermLowerCase);
        });
      }
    }

    setFilteredAccounts(filteredRows);
  };

  const handleCloseModal = () => {
    setShowExportModal(false);
  };

  const handleExportApproved = () => {
    setShowExportModal(false);
    onExportAccounts();
  };

  return (
    <Page>
      <PageHeader>
        <AccountInfoHeader view={view} />
      </PageHeader>
      <PageBody>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div>
            <AccountInfoTitleRow
              hasAccounts={hasAccounts}
              filteredAccountCount={filteredAccounts.length}
              onReloadAccounts={onReloadAccounts}
              onExportAccounts={checkExportOnTrialMode}
              initialSearchTerm={searched}
              onSearchTermChange={requestSearch}
              scoreRangeFilterInfo={scoreRangeFilterInfo}
              onScoreRangeFilterChange={filterScore}
              durationRangeFilterInfo={durationRangeFilterInfo}
              onDurationRangeFilterChange={filterDuration}
              householdsRangeFilterInfo={householdsRangeFilterInfo}
              onHouseholdsRangeFilterChange={filterHouseholds}
              labelItemsFilterInfo={labelItemsFilterInfo}
              onLabelItemsFilterChange={filterLabels}
              tagItemsFilterInfo={tagItemsFilterInfo}
              onTagItemsFilterChange={filterTags}
              importListFilterInfo={importListFilterInfo}
              onImportListFilterChange={filterImportList}
              selectedRecordCount={selectedRecordCount}
              queryRecordCount={queryRecordCount}
              onAccountsRecordCountChange={onRecordCountSelectionChange}
              selectedOrderBy={selectedOrderBy}
              queryOrderBy={queryOrderBy}
              onOrderByChange={onOrderByChange}
              view={view}
            />
          </div>
          {view == "table" && (
            <div className={classes.bodyContainer}>
              <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <div className={`${classes.leftSide} accountInfoLeftSideTable`}>
                  <div className={classes.leftSide__bottom}>
                    {isLoadingAccounts && <Loading />}
                    {!isLoadingAccounts && (
                      <AccountsTable
                        onAccountSelected={onAccountSelected}
                        changePageFunc={changePageFunc}
                        accounts={filteredAccounts}
                        selectedAccount={selectedAccount}
                        totalAccounts={totalAccounts}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.rightSide}>
                  <div className={classes.tabsContainer}>
                    <Card className={classes.card}>
                      <div
                        style={{
                          opacity:
                            hasAccounts && !isLoadingAccounts ? "1" : "0",
                        }}
                      >
                        <div
                          className={classes.title}
                          style={{ display: "flex", color: "white" }}
                        >
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: "0 0 auto" }}>
                              Account ID
                              {hashedOrRevealedId.length <= 30 && (
                                <span> {hashedOrRevealedId}</span>
                              )}
                              {hashedOrRevealedId.length > 30 && (
                                <NoMaxWidthTooltip
                                  title={hashedOrRevealedId}
                                  arrow
                                >
                                  <span>
                                    {" "}
                                    {hashedOrRevealedId.slice(0, 15) +
                                      "..." +
                                      hashedOrRevealedId.slice(-15)}
                                  </span>
                                </NoMaxWidthTooltip>
                              )}
                            </div>
                          </div>
                          <div style={{ marginLeft: "auto", flex: "0 0 auto" }}>
                            <button
                              onClick={onOpenInFullScreen}
                              disabled={!selectedAccount}
                              className={classes.fullScreenButton}
                            >
                              <img
                                src={fullScreenIcon}
                                className={classes.icon}
                              />
                              FULL SCREEN
                            </button>
                          </div>
                        </div>
                        <div>
                          <span className={classes.titleSelectedAccountLabel}>
                            {selectedAccount?.label || ""}
                          </span>
                          {selectedAccount?.tags?.map((tag) => (
                            <span
                              className={classes.titleSelectedAccountLabel}
                              key={tag}
                            >
                              {tag || ""}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div
                        className={`${classes.main} accountInfoRightSideTabs`}
                        style={{
                          opacity:
                            hasAccounts && !isLoadingAccounts ? "1" : "0",
                        }}
                      >
                        <Tabs
                          className={classes.tabList}
                          activeKey={selectedTabKey}
                          onSelect={(k) => setSelectedTabKey(k ? k : "")}
                        >
                          <Tab
                            eventKey="overview"
                            title="Overview"
                            className={classes.tabContent}
                          >
                            {id && (
                              <>
                                <SummaryBar isFullScreenView={false} />
                                <ScoreOverTime />
                                <HouseholdsOverTime />
                                <DurationOverTime />
                              </>
                            )}
                          </Tab>
                          <Tab
                            eventKey="viewing"
                            title="Viewing Pattern"
                            className={classes.tab}
                          >
                            {id && <WatchTimeTab isFullScreenView={false} />}
                          </Tab>
                          <Tab eventKey="location" title="Location Map">
                            {/* {id && <LeafletMap />} */}
                            {id && <LocationTab />}
                          </Tab>
                        </Tabs>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          )}
          {view == "dashboard" && (
            <AccountInfoDashboardView
              hasAccounts={hasAccounts}
              isLoadingAccounts={isLoadingAccounts}
            />
          )}
        </div>
      </PageBody>
      {showExportModal && (
        <Modal
          type={MODAL_TYPES.WARNING}
          title="Export List"
          buttonText="CONTINUE"
          contentTitle="Plaese Note"
          contentText="The system is in trial mode, export is limited to 25 accounts."
          onClose={handleCloseModal}
          onButtonClick={handleExportApproved}
        />
      )}
    </Page>
  );
};

export default AccountInfo;
