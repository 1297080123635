import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useQuery as useQueryParams } from "../../hooks/use-query";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ErrorHandlingContext } from "../../contexts/errorHandlingContext";
import Loading from "../../components/UI/Loading";
import ErrorModal, {
  ERROR_MODAL_STATE,
  ERROR_MODAL_TYPE,
} from "../../components/UI/ErrorModal";

//import { fixHashQueryParams } from "../../utils/router-utils";

const OrganizationContext = createContext<string | null>(null);

/**
 * This component resolves the organization of the user. The organization needs to be set cia external caller (like redirection
 * from account portal) and it will be resolved by the following precedence:
 * 1. If query parameter `?projectId=` is provided, it will be resolved using the url set in REACT_APP_MT_API
 * 2. Query parameter `?org=`
 * 3. Environment variable `REACT_APP_AUTH0_ORGANIZATION`
 * 4. LocalStorage with key "organization". This value will be parsed as JSON
 */
export const OrganizationProvider = ({ children }) => {
  const query = useQueryParams();
  const history = useHistory();
  const orgFromQuery = query.get("org");
  const projectId = query.get("projectId");
  const [organization, setOrganization] = useState<string | null>(null);
  const { error, createError } = useContext(ErrorHandlingContext);

  const {
    isLoading,
    orgError,
    data: orgFromProject,
  } = useQuery<string, Error>(
    ["organization", projectId],
    async () => {
      // console.log(`retrieving organization from with projectId=${projectId}`);
      const res = await fetch(
        `${process.env.REACT_APP_MT_API}/lookup/projectOrgId?projectId=${projectId}`
      );
      if (res.status == 200) {
        return res.text();
      } else throw Error();
    },
    {
      enabled: !!projectId,
    }
  );

  useEffect(() => {
    const orgFromEnv = process.env.REACT_APP_AUTH0_ORGANIZATION;
    const orgFromStorage = localStorage.getItem("organization");
    if (isLoading) return;
    console.log({
      orgFromProject,
      orgFromEnv,
      orgFromStorage,
      orgFromQuery,
      projectId,
    });
    if (orgFromProject) {
      localStorage.setItem("organization", JSON.stringify(orgFromProject));
      setOrganization(orgFromProject);
      query.delete("projectId");
      history.replace({
        search: query.toString(),
      });
    } else if (orgFromQuery) {
      localStorage.setItem("organization", JSON.stringify(orgFromQuery));
      setOrganization(orgFromQuery);
      query.delete("org");
      history.replace({
        search: query.toString(),
      });
    } else if (orgFromEnv) {
      localStorage.setItem("organization", JSON.stringify(orgFromEnv));
      setOrganization(orgFromEnv);
    } else if (orgFromStorage) {
      const org = JSON.parse(orgFromStorage);
      setOrganization(org);
    } else if (!projectId) {
      createError(ERROR_MODAL_TYPE.AUTH);
    }
    // eslint-disable-next-line
  }, [organization, orgFromProject, orgFromQuery]);

  if (!isLoading && orgError) {
    createError(ERROR_MODAL_TYPE.AUTH);
  }

  return (
    <div style={{ width: "100vw", height: "100vh", background: "#334162" }}>
      {isLoading && <Loading loadingText="Authenticate..." />}
      {organization && (
        <OrganizationContext.Provider value={organization}>
          {children}
        </OrganizationContext.Provider>
      )}
      {error?.errorState == ERROR_MODAL_STATE.OPEN && <ErrorModal />}
    </div>
  );
};

OrganizationProvider.propTypes = {
  children: PropTypes.node,
};

export const useOrganization = () => useContext(OrganizationContext);
