import { ErrorHandlingContext } from "../contexts/errorHandlingContext";
import { useContext, useEffect, useState } from "react";
import { ERROR_MODAL_TYPE } from "../components/UI/ErrorModal";

const NetworkCheck = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [refreshOnReconnect, setRefreshOnReconnect] = useState(false);
  const { error, createError, closeNetworkError } =
    useContext(ErrorHandlingContext);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    if (isOnline) {
      if (refreshOnReconnect) {
        window.location.reload();
      } else {
        closeNetworkError(error);
      }
    } else {
      setTimeout(() => {
        const isOnlineAfter3Seconds = navigator.onLine;
        setIsOnline(isOnlineAfter3Seconds);
        if (!isOnlineAfter3Seconds) {
          createError(ERROR_MODAL_TYPE.NETWORK);
          setTimeout(() => {
            const isOnlineAfter6Seconds = navigator.onLine;
            setIsOnline(isOnlineAfter6Seconds);
            if (!isOnlineAfter6Seconds) {
              setRefreshOnReconnect(true);
            }
          }, 5000);
        }
      }, 5000);
    }

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline, createError, closeNetworkError, error, refreshOnReconnect]);

  return children;
};

export default NetworkCheck;
