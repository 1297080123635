import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import classes from "./SpecificAccountHeader.module.css";

import Search from "../../../assets/img/search.svg";
import Back from "../../../assets/img/back.svg";
import NoMaxWidthTooltip from "../../UI/NoMaxWidthTooltip";

import { getAccountSummary, getAccountTags } from "../../../external-apis";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import { AccountsContext } from "../../../contexts/accountsContext";

const SpecificAccountHeader = (props) => {
  const [accountID, setAccountID] = useState("");
  const [accountLabel, setAccountLabel] = useState();
  const [tags, setTags] = useState([]);

  const {
    selectedAccount,
    setSelectedAccount,
    isSelectedAccountFromList,
    getHashedOrRevealedId,
  } = useContext(AccountsContext);

  const history = useHistory();

  const id = getHashedOrRevealedId(selectedAccount?.account_id);

  const { data: accountSummary } = useQueryWithAuthorization(
    ["accountSummary", selectedAccount],
    getAccountSummary(selectedAccount?.account_id),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  useDeepCompareEffect(() => {
    if (accountSummary?.[0]?.definition) {
      setAccountLabel(accountSummary[0].definition);
    }
  }, [accountSummary]);

  const { data: accountTags } = useQueryWithAuthorization(
    ["accountTags", selectedAccount],
    getAccountTags(selectedAccount?.account_id),
    { enabled: selectedAccount && selectedAccount?.account_id != null }
  );

  useDeepCompareEffect(() => {
    if (accountTags) {
      setTags(accountTags);
    }
  }, [accountTags]);

  const accountChangeHandler = (event) => {
    setAccountID(event.target.value);
  };

  const onKeyPressHandler = (event) => {
    if (
      (event.key === "Enter" || event.code === "NumpadEnter") &&
      accountID?.length > 0
    ) {
      event.preventDefault();
      searchAccountID();
    }
  };

  const searchAccountID = () => {
    props.searchAccountIDHandler(accountID);
    setAccountID("");
  };

  const backToAccountList = () => {
    history.push("/home/accounts");
    if (!isSelectedAccountFromList) {
      setSelectedAccount(null);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        <button className={classes.backButton} onClick={backToAccountList}>
          <img src={Back} className={classes.back} />
        </button>
        {selectedAccount?.account_id && (
          <div className={classes.title}>
            Account ID
            {id.length <= 30 && <span> {id} </span>}
            {id.length > 30 && (
              <NoMaxWidthTooltip title={id} arrow>
                <span> {id.slice(0, 15) + "..." + id.slice(-15)} </span>
              </NoMaxWidthTooltip>
            )}
            |
          </div>
        )}
        <div>
          {accountLabel && (
            <span className={classes.titleSelectedAccountLabel}>
              {accountLabel}
            </span>
          )}
          {tags?.map((tag) => (
            <span className={classes.titleSelectedAccountLabel} key={tag.name}>
              {tag.name || ""}
            </span>
          ))}
        </div>
      </div>
      <div className={classes.searchBox}>
        <input
          type="text"
          value={accountID}
          placeholder="Account ID"
          className={classes.search}
          onChange={accountChangeHandler}
          onKeyPress={onKeyPressHandler}
        />
        <button className={classes.searchButton} onClick={searchAccountID}>
          <img src={Search} alt="sidebar icon" />
        </button>
      </div>
    </div>
  );
};

SpecificAccountHeader.propTypes = {
  accountID: PropTypes.string,
  searchAccountIDHandler: PropTypes.func,
};

export default SpecificAccountHeader;
