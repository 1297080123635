import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

// import SidebarBackLogo from '../../assets/img/sidebar_back.svg';
import DashboardLogo from "../../assets/img/dashboard.svg";
import AccountInfoLogo from "../../assets/img/account_info.svg";
import DashboardViewIconWhite from "../../assets/img/dashboard_view_white.svg";

import classes from "./Sidebar.module.css";

const Sidebar = () => {
  let { url } = useRouteMatch();

  return (
    <nav>
      <ul className={classes.sidebar}>
        {/* <li className={classes.sidebar__item}>
                    <NavLink className={classes.sidebar__nav} to='back'>
                        <div className={classes.sidebar__nav__contentWraper}>
                            <img src={SidebarBackLogo} className={classes.sidebar__icon} />
                        </div>
                    </NavLink>
                </li> */}
        <li className={classes.sidebar__item}>
          <NavLink
            className={classes.sidebar__nav}
            activeClassName={classes.active}
            to={`${url}/dashboard`}
          >
            <div className={classes.sidebar__nav__contentWraper}>
              <img src={DashboardLogo} className={classes.sidebar__icon} />
              <span className={classes.sidebar__nav__title}>Dashboard</span>
            </div>
          </NavLink>
        </li>
        {/* <li className={classes.sidebar__item}>
                    <NavLink className={classes.sidebar__nav} activeClassName={classes.active} to={`${url}/sharing-overview`}>
                        <div className={classes.sidebar__nav__contentWraper}>
                            <img src={SharingOverviewLogo} className={classes.sidebar__icon} />
                            <span className={classes.sidebar__nav__title}>Sharing Overview</span>
                        </div>
                    </NavLink>
                </li> */}
        <li className={classes.sidebar__item}>
          <NavLink
            className={classes.sidebar__nav}
            activeClassName={classes.active}
            to={`${url}/accounts`}
          >
            <div className={classes.sidebar__nav__contentWraper}>
              <img src={AccountInfoLogo} className={classes.sidebar__icon} />
              <span className={classes.sidebar__nav__title}>Accounts</span>
            </div>
          </NavLink>
        </li>
        <li className={classes.sidebar__item}>
          <NavLink
            className={classes.sidebar__nav}
            activeClassName={classes.active}
            to={`${url}/action-tracking`}
          >
            <div className={classes.sidebar__nav__contentWraper}>
              <img
                src={DashboardViewIconWhite}
                className={classes.sidebar__icon}
              />
              <span className={classes.sidebar__nav__title}>
                Action Tracking
              </span>
            </div>
          </NavLink>
        </li>
        {/* <li className={classes.sidebar__item}>
                    <NavLink className={classes.sidebar__nav} activeClassName={classes.active} to={`${url}/specific-account`}>
                        <div className={classes.sidebar__nav__contentWraper}>
                            <img src={SpecificAccountLogo} className={classes.sidebar__icon} />
                            <span className={classes.sidebar__nav__title}>Specific Account</span>
                        </div>
                    </NavLink>
                </li> */}
        {/* <li className={classes.sidebar__item}>
                    <NavLink className={classes.sidebar__nav} activeClassName={classes.active} to={`${url}/actions`}>
                        <div className={classes.sidebar__nav__contentWraper}>
                            <img src={ActionsLogo} className={classes.sidebar__icon} />
                            <span className={classes.sidebar__nav__title}>Actions</span>
                        </div>
                    </NavLink>
                </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
