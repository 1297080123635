import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";

import {
  ACCOUNT_FIELDNAMES,
  IRangeFieldInfo,
} from "../services/accountInterfaces";

import { useQueryWithAuthorization } from "../custom-hooks";

import { AccountDevicesContext } from "./accountDevicesContext";
import { getAccountDevices } from "../external-apis";
import { AppConfigContext } from "./appConfigContext";

export const AccountsContext = createContext();

export const AccountsProvider = (props) => {
  const [accounts, setAccounts] = useState(null);
  const [totalAccounts, setTotalAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isSelectedAccountFromList, setSelectedAccountFromList] =
    useState(null);

  const [scoreRangeFilterInfo, setScoreRangeFilterInfo] = useState(null);
  const [durationRangeFilterInfo, setDurationRangeFilterInfo] = useState(null);
  const [householdsRangeFilterInfo, setHouseholdsRangeFilterInfo] =
    useState(null);

  const [labelItemsFilterInfo, setLabelItemsFilterInfo] = useState(null);
  const [tagItemsFilterInfo, setTagItemsFilterInfo] = useState(null);

  const [importListFilterInfo, setImportListFilterInfo] = useState({
    current: null,
    query: null,
    isDisplayed: false,
    uploadeFileState: null,
    uuid: null,
  });

  const { setAccountDevicesRequestData } = useContext(AccountDevicesContext);

  const DEFAULT_RECORD_COUNT = 1000;
  const RECORD_COUNT_IN_REGULAR_MODE = DEFAULT_RECORD_COUNT;
  const [selectedRecordCount, setSelectedRecordCount] =
    useState(DEFAULT_RECORD_COUNT);
  const [recordCountToLoad, setRecordCountToLoad] =
    useState(DEFAULT_RECORD_COUNT);
  const [queryRecordCount, setQueryRecordCount] =
    useState(DEFAULT_RECORD_COUNT);

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    fieldName: ACCOUNT_FIELDNAMES.SCORE,
    isAsc: false,
  });

  const [queryOrderBy, setQueryOrderBy] = useState({
    fieldName: ACCOUNT_FIELDNAMES.SCORE,
    isAsc: false,
  });

  const [filtersProperties, setFiltersProperties] = useState({
    limit: recordCountToLoad,
    filters: [],
    tags: [],
    importListFilters: [],
    orderBy: selectedOrderBy,
  });

  const [page, setPage] = useState(0);

  const changePageFunc = useRef(0);

  const { isTrialMode, trialModeLimit } = useContext(AppConfigContext);

  const setRevealedAccountId = useCallback(
    (hashedId, revealedId, revealedDevices) => {
      setAccounts(
        accounts?.map((account) =>
          account.account_id == hashedId
            ? {
                ...account,
                revealed_id: revealedId,
                revealed_devices: revealedDevices,
              }
            : account
        ) || []
      );
      if (selectedAccount?.account_id == hashedId) {
        setSelectedAccount({
          ...selectedAccount,
          revealed_id: revealedId,
          revealed_devices: revealedDevices,
        });
      }
    },
    [accounts, selectedAccount]
  );

  const getHashedOrRevealedId = useCallback(
    (accountId) => {
      const account = accounts?.find(
        (account) => account.account_id == accountId
      );
      return account?.revealed_id ? account.revealed_id : accountId;
    },
    [accounts]
  );

  const getHashedOrRevealedDeviceId = useCallback(
    (accountId, deviceId) => {
      const account = accounts?.find(
        (account) => account.account_id == accountId
      );
      if (account?.revealed_devices) {
        const device = account.revealed_devices.find(
          (device) => device.hashed_device == deviceId
        );
        return device?.device_id || deviceId;
      } else {
        return deviceId;
      }
    },
    [accounts]
  );

  const createRangeFieldInfoFromDistribution = useCallback(
    (filterInfo: { min; max; step; infiniteRange }): IRangeFieldInfo => {
      const ret: IRangeFieldInfo = {
        min: filterInfo.min,
        max: filterInfo.max,
        step: filterInfo.step,
        infiniteRange: filterInfo.infiniteRange,
        current: [filterInfo.min, filterInfo.max],
        query: [filterInfo.min, filterInfo.max],
        isDisplayed: false,
      };
      return ret;
    },
    []
  );

  const {
    data: accountDevices,
    isLoading: isLoadingAccountDevices,
    isError: isErrorAccountDevices,
    error: errorAccountDevices,
  } = useQueryWithAuthorization(
    ["accountDevices", selectedAccount?.account_id],
    getAccountDevices(selectedAccount?.account_id),
    { enabled: selectedAccount != null }
  );

  useEffect(() => {
    setAccountDevicesRequestData(
      accountDevices,
      isLoadingAccountDevices,
      isErrorAccountDevices,
      errorAccountDevices
    );
  }, [
    accountDevices,
    isLoadingAccountDevices,
    isErrorAccountDevices,
    errorAccountDevices,
    setAccountDevicesRequestData,
  ]);

  useEffect(() => {
    const scoreRangeFilterInfo = createRangeFieldInfoFromDistribution({
      min: 0,
      max: 100,
      step: 10,
      infiniteRange: false,
    });
    setScoreRangeFilterInfo(scoreRangeFilterInfo);

    const durationRangeFilterInfo = createRangeFieldInfoFromDistribution({
      min: 0,
      max: 500,
      step: 50,
      infiniteRange: true,
    });
    setDurationRangeFilterInfo(durationRangeFilterInfo);

    const householdsRangeFilterInfo = createRangeFieldInfoFromDistribution({
      min: 1,
      max: 10,
      step: 1,
      infiniteRange: true,
    });
    setHouseholdsRangeFilterInfo(householdsRangeFilterInfo);

    const availableLabels = ["Sharer", "Honest", "Suspect"];
    const filterInfo = {
      available: availableLabels,
      current: [],
      query: availableLabels,
      isDisplayed: false,
    };
    setLabelItemsFilterInfo(filterInfo);
  }, [
    setScoreRangeFilterInfo,
    setDurationRangeFilterInfo,
    setHouseholdsRangeFilterInfo,
    createRangeFieldInfoFromDistribution,
  ]);

  useEffect(() => {
    if (isTrialMode != undefined) {
      const recordCount = isTrialMode
        ? trialModeLimit
        : RECORD_COUNT_IN_REGULAR_MODE;
      setSelectedRecordCount(recordCount);
      setQueryRecordCount(recordCount);
      setRecordCountToLoad(recordCount);
      setFiltersProperties({
        ...filtersProperties,
        limit: recordCount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrialMode]);

  const accountProviderValue = useMemo(
    () => ({
      accounts,
      setAccounts,
      totalAccounts,
      setTotalAccounts,
      selectedAccount,
      setSelectedAccount,
      isSelectedAccountFromList,
      setSelectedAccountFromList,
      scoreRangeFilterInfo,
      setScoreRangeFilterInfo,
      durationRangeFilterInfo,
      setDurationRangeFilterInfo,
      householdsRangeFilterInfo,
      setHouseholdsRangeFilterInfo,
      labelItemsFilterInfo,
      setLabelItemsFilterInfo,
      tagItemsFilterInfo,
      setTagItemsFilterInfo,
      importListFilterInfo,
      setImportListFilterInfo,
      filtersProperties,
      setFiltersProperties,
      selectedRecordCount,
      setSelectedRecordCount,
      recordCountToLoad,
      setRecordCountToLoad,
      queryRecordCount,
      setQueryRecordCount,
      selectedOrderBy,
      setSelectedOrderBy,
      queryOrderBy,
      setQueryOrderBy,
      RECORD_COUNT_IN_REGULAR_MODE,
      page,
      setPage,
      changePageFunc,
      setRevealedAccountId,
      getHashedOrRevealedId,
      getHashedOrRevealedDeviceId,
      createRangeFieldInfoFromDistribution,
    }),
    [
      accounts,
      totalAccounts,
      selectedAccount,
      isSelectedAccountFromList,
      scoreRangeFilterInfo,
      durationRangeFilterInfo,
      householdsRangeFilterInfo,
      labelItemsFilterInfo,
      tagItemsFilterInfo,
      importListFilterInfo,
      filtersProperties,
      selectedRecordCount,
      recordCountToLoad,
      queryRecordCount,
      selectedOrderBy,
      queryOrderBy,
      RECORD_COUNT_IN_REGULAR_MODE,
      page,
      changePageFunc,
      setRevealedAccountId,
      getHashedOrRevealedId,
      getHashedOrRevealedDeviceId,
      createRangeFieldInfoFromDistribution,
    ]
  );

  return (
    <AccountsContext.Provider value={accountProviderValue}>
      {props.children}
    </AccountsContext.Provider>
  );
};
