import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useOrganization } from "../OrganizationProvider";
import { fixHashQueryParams } from "../../utils/router-utils";
import { useEffect, useContext } from "react";
import { useQuery } from "../../hooks/use-query";
import { ErrorHandlingContext } from "../../contexts/errorHandlingContext";
import { ERROR_MODAL_TYPE } from "../../components/UI/ErrorModal";

const Auth0ProviderWithHistory = ({ children }) => {
  const organization = useOrganization();
  const query = useQuery();
  const error = query.get("error");
  // const error_description = query.get("error_description");

  const { createError } = useContext(ErrorHandlingContext);

  useEffect(() => {
    if (error) {
      createError(ERROR_MODAL_TYPE.AUTH);
    }
  }, [error]);

  return error ? (
    <></>
  ) : (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      organization={organization}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

export const Auth0HashRouterPathFix = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    // copied this trick from here: https://github.com/auth0/auth0-spa-js/issues/407
    // this is a special case for auth0 with HashRouter which apparently is not common thing
    // it manually removes the "code" and "state" query parameters from the url
    if (
      isAuthenticated &&
      window.location.href.includes("state") &&
      window.location.href.includes("code") &&
      !window.location.href.includes("error") &&
      /\?.*#/.test(window.location.href)
    )
      fixHashQueryParams(true);
  }, [isAuthenticated]);
  return children;
};
