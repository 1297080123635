import React from "react";
import { useState } from "react";

import numeral from "numeral";

import Tooltip from "@mui/material/Tooltip";

import Card from "../../UI/Card";

import ShadowAccounts from "../../../assets/img/shadowAccounts.svg";
import SharerAccounts from "../../../assets/img/sharerAccounts.svg";
import Info from "../../../assets/img/info.svg";

import classes from "./SharingSummary.module.css";
import "./SharingSummary.css";

import HorizontalBarsChart from "./HorizontalBarsChart";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import NoData from "../../UI/NoData";

import {
  getSummaryAccountsSharers,
  getSummaryAccounts,
  getShadowAccounts,
} from "../../../external-apis";

const SharingSummary = () => {
  const [allAccounts, setAllAccounts] = useState();
  const [sharerAccounts, setSharerAccounts] = useState();
  const [shadowAccounts, setShadowAccounts] = useState();

  const {
    data: summaryAccountsSharers,
    isLoading: isLoadingSummaryAccountsSharers,
    isError: isErrorSummaryAccountsSharers,
    error: errorSummaryAccountsSharers,
  } = useQueryWithAuthorization(
    "summaryAccountsSharers",
    getSummaryAccountsSharers()
  );

  useDeepCompareEffect(() => {
    if (summaryAccountsSharers && summaryAccountsSharers.length !== 0) {
      setSharerAccounts(summaryAccountsSharers[0].account_ids.distinct);
    }
  }, [summaryAccountsSharers]);

  const {
    data: summaryShadowAccounts,
    isLoading: isLoadingShadowAccounts,
    isError: isErrorShadowAccounts,
    error: errorShadowAccounts,
  } = useQueryWithAuthorization("shadowAccounts", getShadowAccounts());

  useDeepCompareEffect(() => {
    if (summaryShadowAccounts?.count) {
      setShadowAccounts(summaryShadowAccounts.count);
    }
  }, [summaryShadowAccounts]);

  const {
    data: summaryAccounts,
    isLoading: isLoadingSummaryAccounts,
    isError: isErrorSummaryAccounts,
    error: errorSummaryAccounts,
  } = useQueryWithAuthorization("summaryAccounts", getSummaryAccounts());

  useDeepCompareEffect(() => {
    if (summaryAccounts && summaryAccounts.length !== 0) {
      setAllAccounts(summaryAccounts[0].account_ids.distinct);
    }
  }, [summaryAccounts]);

  const infoText =
    "Persistent Household = a set of devices, persistently active, and identified by their behavior as belonging to the same household. An honest account has a single household. A sharer account has two or more households that are persistently active.";

  let content = (
    <div className={classes.main}>
      <div className={classes.left}>
        <div className={classes.statistic}>
          <img src={SharerAccounts} className={classes.logo} />
          <div className={classes.statisticValues}>
            <div className={`${classes.values} ${classes.sharingColor}`}>
              {sharerAccounts < 1000 && sharerAccounts}
              {sharerAccounts >= 1000 &&
                sharerAccounts <= 1000000 &&
                numeral(sharerAccounts).format("0.a").slice(0, -1)}
              {sharerAccounts > 1000000 &&
                numeral(sharerAccounts).format("0.[0]a").slice(0, -1)}
              <span className={classes.numberCategory}>
                {sharerAccounts < 1000 && ""}
                {sharerAccounts >= 1000 &&
                  sharerAccounts <= 1000000 &&
                  numeral(sharerAccounts).format("0.a").slice(-1)}
                {sharerAccounts > 1000000 &&
                  numeral(sharerAccounts).format("0.[0]a").slice(-1)}
              </span>
            </div>
            <div className={`${classes.text} ${classes.sharingColor}`}>
              Sharer Accounts
            </div>
            <div className={classes.text}>
              {numeral(sharerAccounts / allAccounts).format("0.[0]a%")} Of Total{" "}
              {allAccounts <= 1000000 && numeral(allAccounts).format("0.a")}
              {allAccounts > 1000000 &&
                numeral(allAccounts).format("0.[0]a")}{" "}
              Accounts
            </div>
          </div>
        </div>
        <div className={classes.statistic}>
          <img src={ShadowAccounts} className={classes.logo} />
          <div className={classes.newClass}>
            <div className={`${classes.values} ${classes.shadowColor}`}>
              {shadowAccounts < 1000 && shadowAccounts}
              {shadowAccounts >= 1000 &&
                shadowAccounts <= 1000000 &&
                numeral(shadowAccounts).format("0.a").slice(0, -1)}
              {shadowAccounts > 1000000 &&
                numeral(shadowAccounts).format("0.[0]a").slice(0, -1)}
              <span className={classes.numberCategory}>
                {shadowAccounts < 1000 && ""}
                {shadowAccounts >= 1000 &&
                  shadowAccounts <= 1000000 &&
                  numeral(shadowAccounts).format("0.a").slice(-1)}
                {shadowAccounts > 1000000 &&
                  numeral(shadowAccounts).format("0.[0]a").slice(-1)}
              </span>
            </div>
            <div className={`${classes.text} ${classes.shadowColor}`}>
              Borrower Accounts
            </div>
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <HorizontalBarsChart
          data={{ sharer: sharerAccounts, shadow: shadowAccounts }}
        />
        <div className={classes.flex}>
          <div className={classes.divider}></div>
          <div className={classes.flex}>
            <div className={classes.chartText}>
              Persistent Sharer Households
            </div>
            <Tooltip title={infoText} arrow className={classes.tooltip}>
              <img src={Info} className={classes.info} />
            </Tooltip>
          </div>
          <div className={classes.divider}></div>
        </div>
      </div>
    </div>
  );

  if (
    isLoadingSummaryAccountsSharers ||
    isLoadingSummaryAccounts ||
    isLoadingShadowAccounts
  ) {
    content = <Loading />;
  } else if (
    summaryAccountsSharers === null ||
    summaryAccounts === null ||
    summaryShadowAccounts === null
  ) {
    content = <NoData />;
  } else if (isErrorSummaryAccountsSharers) {
    console.warn(
      "Failed to lookup for sharer accounts data",
      errorSummaryAccountsSharers.message
    );
    content = <ErrorMessage />;
  } else if (isErrorSummaryAccounts) {
    console.warn(
      "Failed to lookup for accounts data",
      errorSummaryAccounts.message
    );
    content = <ErrorMessage />;
  } else if (isErrorShadowAccounts) {
    console.warn(
      "Failed to lookup for shadow accounts data",
      errorShadowAccounts.message
    );
    content = <ErrorMessage />;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>{content}</Card>
    </div>
  );
};

export default SharingSummary;
