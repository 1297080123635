// eslint-disable-next-line
const baseUrl = process.env.REACT_APP_ACCOUNT_PORTAL_API_ACCOUNT;
const baseHeaders = {
  "content-type": "application/json",
};

const getAll = (params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/accounts`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const getById = (id, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/accounts/${id}`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const postAccount = (account, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "POST",
    url: `${baseUrl}/accounts`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
    body: JSON.stringify(account),
  };
};

const lookupOrgIdByIdOrAlias = (idOrAlias, params = {}) => {
  const { headers = {} } = params;
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.set("accountId", idOrAlias);

  return {
    method: "GET",
    url: `${baseUrl}/lookup/accountOrgId?${urlSearchParams.toString()}`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

export { getAll, getById, postAccount, lookupOrgIdByIdOrAlias };
