// eslint-disable-next-line
const baseUrl = process.env.REACT_APP_ACCOUNT_PORTAL_API_ACCOUNT;
const baseHeaders = {
  "content-type": "application/json",
};

const getAll = (params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/accounts/projects`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

const getProjectsByAccountId = (accountId, params = {}) => {
  const { headers = {} } = params;

  return {
    method: "GET",
    url: `${baseUrl}/accounts/${accountId}/projects`,
    headers: {
      ...headers,
      ...baseHeaders,
    },
  };
};

export { getAll, getProjectsByAccountId };
