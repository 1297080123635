import { useQuery } from "react-query";
import { fetchFn } from "../../external-apis";
import useAuthorizationHeader from "./useAuthorizationHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorHandlingContext } from "../../contexts/errorHandlingContext";
import { useContext, useEffect, useState } from "react";
import { ERROR_MODAL_TYPE } from "../../components/UI/ErrorModal";

const ERROR_STATUS = {
  AUTHORIZATION_ERROR: 401,
};

const useQueryWithAuthorization = (queryKey, apiObj, options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { createError } = useContext(ErrorHandlingContext);

  const { header: authHeader, error: accessTokenError } =
    useAuthorizationHeader();

  queryKey = [...queryKey, authHeader];

  const [refetchApiObj, setRefetchApiObj] = useState(null);
  const [refetchOptions, setRefetchOptions] = useState(null);
  const [refetchEnabled, setRefetchEnabled] = useState(false);

  const DEFAULT_RETRIES = 3;
  const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

  apiObj.headers = apiObj.headers ?? {};
  apiObj.headers.Authorization = authHeader;

  options = {
    ...options,
    retry: options.retry != undefined ? options.retry : DEFAULT_RETRIES,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval:
      options.refetchInterval != undefined ? options.refetchInterval : false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus:
      options.refetchOnWindowFocus != undefined
        ? options.refetchOnWindowFocus
        : false,
    retryOnMount: false,
    staleTime:
      options.staleTime != undefined ? options.staleTime : DEFAULT_STALE_TIME,
  };

  const authHeaderExist = authHeader != null;
  const enabled =
    options.enabled != null && options.enabled != undefined
      ? options.enabled && authHeaderExist
      : authHeaderExist;

  const useQueryResult = useQuery([...queryKey], fetchFn(apiObj), {
    ...options,
    enabled,
  });

  const useQueryRefetchResult = useQuery(
    [...queryKey, refetchEnabled],
    fetchFn(refetchApiObj),
    {
      ...refetchOptions,
      enabled: refetchEnabled && options.enabled,
    }
  );

  // case first api faild with authorization error
  useEffect(() => {
    if (
      useQueryResult.error?.response?.status == ERROR_STATUS.AUTHORIZATION_ERROR
    ) {
      const refetchData = async () => {
        try {
          let optionsCopy = { ...options };
          optionsCopy.retry = 0;
          optionsCopy.staleTime = 0;
          setRefetchOptions(optionsCopy);
          options.refetchInterval = 0; // disable retries if there are
          const accessToken = await getAccessTokenSilently({
            ignoreCache: true,
          });
          let apiObjCopy = { ...apiObj };
          apiObjCopy.headers.Authorization = `Bearer ${accessToken}`;
          setRefetchApiObj(apiObjCopy);
          setRefetchEnabled(true);
        } catch (error) {
          //case new token request failed
          createError(ERROR_MODAL_TYPE.AUTH);
        }
      };
      refetchData();
    }
    // eslint-disable-next-line
  }, [useQueryResult.error?.response?.status]);

  // case refetch faild also with authorization error
  useEffect(() => {
    if (
      useQueryRefetchResult.error?.response?.status ==
      ERROR_STATUS.AUTHORIZATION_ERROR
    ) {
      createError(ERROR_MODAL_TYPE.AUTH);
    }
  }, [useQueryRefetchResult.error?.response?.status, createError]);

  if (refetchEnabled) {
    // return data of refetch obj
    return {
      ...useQueryRefetchResult,
      isLoading:
        (!authHeaderExist && !accessTokenError) ||
        useQueryRefetchResult.isLoading,
      isError: accessTokenError != null || useQueryRefetchResult.isError,
      error: accessTokenError ?? useQueryRefetchResult.error,
    };
  } else {
    // default - return data of source api request
    return {
      ...useQueryResult,
      isLoading:
        (!authHeaderExist && !accessTokenError) ||
        useQueryResult.isLoading ||
        useQueryResult.error?.response?.status ==
          ERROR_STATUS.AUTHORIZATION_ERROR,
      isError: accessTokenError != null || useQueryResult.isError,
      error: accessTokenError ?? useQueryResult.error,
    };
  }
};

export default useQueryWithAuthorization;
