import React from "react";
import PropTypes from "prop-types";
import classes from "./Page.module.css";

const Page = (props) => {
  return (
    <div className={`${classes.page} ${props.className}`}>{props.children}</div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
  className: PropTypes.string,
};

export default Page;
