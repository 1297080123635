import { useState } from "react";

import DownArrowForMenuSvg from "../../../../assets/img/downArrowForMenu.svg";

import {
  Checkbox,
  ClickAwayListener,
  FormGroup,
  Popper,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import classes from "./RangeFilter.module.scss";
import { useAlignedPopperWidth } from "./useAlignedPopperWidth";

export interface IStringItemsFilterProps {
  filterName: string; // For example, Label, Tags
  available: string[];
  selected: string[];
  isMultiSelect: boolean;
  onSelectionChange: (selected: string[]) => void;
  onRemoveFilterClick: () => void;
}

const StringItemsFilter = (props: IStringItemsFilterProps) => {
  const getButtonText = (selected?) => {
    const currentSelected = selected || props.selected;
    if (currentSelected == null || currentSelected.length == 0) {
      return "Choose " + props.filterName + "...";
    } else {
      if (props.isMultiSelect) {
        if (currentSelected.length == 1) {
          return currentSelected.length + " " + props.filterName.slice(0, -1);
        } else {
          return currentSelected.length + " " + props.filterName;
        }
      } else {
        return currentSelected[0];
      }
    }
  };

  const [rangeButtonText, setRangeButtonText] = useState(getButtonText());

  const {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  } = useAlignedPopperWidth();

  const handleSelectionChange = (selected: string[]) => {
    props.onSelectionChange(selected);
    setRangeButtonText(getButtonText(selected));
  };

  const resetFilterValue = () => {
    handleSelectionChange([]);
    setRangeButtonText("Choose " + props.filterName + "...");
  };

  const onCheckboxCheckChange = (evt, val) => {
    const isChecked = evt.target.checked;
    if (isChecked) {
      if (!props.selected.includes(val)) {
        if (props.isMultiSelect) {
          handleSelectionChange([...props.selected, val]);
        } else {
          handleSelectionChange([val]);
        }
      }
    } else {
      const indexOfLabel = props.selected.indexOf(val);
      if (indexOfLabel !== -1) {
        const updatedSelectedLabels = [...props.selected];
        updatedSelectedLabels.splice(indexOfLabel, 1);
        handleSelectionChange(updatedSelectedLabels);
      }
    }
  };

  return (
    <div className={classes.container} ref={elementAlignedToRef}>
      <button
        className={classes.xBtn}
        onClick={() => {
          props.onRemoveFilterClick();
        }}
      >
        X
      </button>

      <button
        className={classes.toggleRangePopperBtn}
        type="button"
        onClick={toggleIsPopperOpenClick}
      >
        {props.filterName}: {rangeButtonText}
        <img className={classes.arrowDownSvg} src={DownArrowForMenuSvg} />
      </button>
      <Popper open={isPopperOpen} anchorEl={elementAlignedToRef.current}>
        <ClickAwayListener onClickAway={toggleIsPopperOpenClick}>
          <div
            style={{
              width: popperWidth + 2,
              background: "#151724",
              border: "1px solid #576079",
              borderRadius: "4px",
              marginTop: "4px",
            }}
          >
            <div className={classes.popperContentContainer}>
              <div>
                <button
                  onClick={() => {
                    resetFilterValue();
                  }}
                  className={classes.resetButton}
                >
                  RESET
                </button>
              </div>

              <div style={{ marginTop: ".5rem", padding: "0 .5rem" }}>
                <FormControl>
                  {props.isMultiSelect && (
                    <FormGroup>
                      {props.available.map((label) => {
                        return (
                          <div key={label}>
                            <FormControlLabel
                              classes={{ root: classes.filterLabelColor }}
                              control={<Checkbox />}
                              label={label}
                              checked={props.selected.includes(label)}
                              onChange={(evt) => {
                                onCheckboxCheckChange(evt, label);
                              }}
                            />
                          </div>
                        );
                      })}
                    </FormGroup>
                  )}
                  {!props.isMultiSelect && (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {props.available.map((label) => {
                        return (
                          <div key={label}>
                            <FormControlLabel
                              classes={{ root: classes.filterLabelColor }}
                              value={label}
                              control={<Radio />}
                              label={label}
                              checked={props.selected.includes(label)}
                              onChange={(evt) => {
                                onCheckboxCheckChange(evt, label);
                              }}
                              sx={{
                                padding: ".35rem 0",
                                "& .Mui-checked": {
                                  color: "#06A3ED !important",
                                },
                                "& .MuiFormControlLabel-label": {
                                  paddingLeft: ".5rem",
                                },
                              }}
                            />
                          </div>
                        );
                      })}
                    </RadioGroup>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default StringItemsFilter;
