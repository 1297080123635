import React, { useEffect, useContext, useState } from "react";
import { useRef } from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

import configData from "../../../../../config.js";

import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../../custom-hooks";
import Loading from "../../../../UI/Loading";
import ErrorMessage from "../../../../UI/ErrorMessage";
import NoData from "../../../../UI/NoData";

import { getAccountDevices } from "../../../../../external-apis";

import { AccountsContext } from "../../../../../contexts/accountsContext";

import classes from "./LocationTab.module.scss";

import { codes } from "iso-country-codes";
import { countriesData } from "./CountriesData.js";

interface IDevice {
  priority: number; // NOTE: Returned from the server as string
  household: number; // NOTE: Returned from the server as string
  lat: number;
  long: number;

  device_id: string;
  device_type: string;
  country: string;
  city: string;
  total_viewing_duration_seconds: number; // NOTE: Returned from the server as string
  stationary: boolean;
}

const LocationTab = () => {
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const [directions, setDirections] = useState({
    north: undefined,
    east: undefined,
    south: undefined,
    west: undefined,
    zoom: undefined,
  });

  const { selectedAccount } = useContext(AccountsContext);

  const {
    data: accountDevices,
    isLoading: isLoadingAccountDevices,
    isError: isErrorAccountDevices,
    error: errorAccountDevices,
  } = useQueryWithAuthorization(
    ["accountDevices", selectedAccount],
    getAccountDevices(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  useEffect(() => {
    // Create map instance
    const MapChart = am4core.create("mapChart", am4maps.MapChart);
    chartRef.current = MapChart;

    am4core.addLicense(configData.am4MapLicense);
    if (MapChart.logo !== undefined) {
      MapChart.logo.disabled = true;
    }

    MapChart.paddingTop = 5;
    MapChart.paddingBottom = 5;
    MapChart.paddingRight = 5;
    MapChart.paddingLeft = 5;

    // Set map definition
    MapChart.geodata = am4geodata_worldLow;

    MapChart.maxZoomLevel = 1024;

    //TBD: to be removed later after approved by product
    // MapChart.events.on("ready", function () {
    //   if (
    //     directions?.north &&
    //     directions?.east &&
    //     directions?.south &&
    //     directions?.west &&
    //     directions?.zoom
    //   ) {
    //     MapChart.zoomToRectangle(
    //       directions?.north,
    //       directions?.east,
    //       directions?.south,
    //       directions?.west,
    //       directions?.zoom,
    //       true
    //     );
    //   }
    // });

    // Set projection
    MapChart.projection = new am4maps.projections.Mercator();

    const polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.nonScalingStroke = true;
    polygonSeries.strokeWidth = 2;
    MapChart.series.push(polygonSeries);

    // Configure series
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = am4core.color("#576079");
    polygonTemplate.propertyFields.fill = "fill";
    polygonTemplate.stroke = am4core.color("#151724");
    polygonTemplate.strokeWidth = 0.5;

    //excluding series
    polygonSeries.exclude = ["AQ"];
    polygonSeries.hiddenInLegend = true;

    polygonSeries.interactionsEnabled = true;
    polygonTemplate.events.on("doublehit", (event) => {
      MapChart.zoomToMapObject(event.target);
    });

    // Set up tooltips
    polygonSeries.calculateVisualCenter = true;
    polygonTemplate.tooltipPosition = "fixed";
    polygonSeries.tooltip.label.interactionsEnabled = true;
    polygonSeries.tooltip.keepTargetHover = true;
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color("#C2C2C2");
    polygonSeries.tooltip.label.fill = am4core.color("#151724");
    polygonSeries.tooltip.background.strokeWidth = 0;
    polygonTemplate.propertyFields.value = "households";
    polygonTemplate.tooltipText = "{name}: {value} Households";
    polygonTemplate.propertyFields.tooltipHTML = "tooltip";
    polygonTemplate.adapter.add("tooltipText", function (text, target) {
      if (target.tooltipDataItem.dataContext?.households) {
        const text =
          target.tooltipDataItem.dataContext.country +
          ": " +
          target.tooltipDataItem.dataContext.households.toLocaleString() +
          " Household";
        return target.tooltipDataItem.dataContext?.households === 1
          ? text
          : text + "s";
      } else return null;
    });

    //default zoom
    polygonTemplate.events.on("hit", function (event) {
      MapChart.zoomToMapObject(event.target);
    });

    seriesRef.current = polygonSeries;

    MapChart.legend = new am4maps.Legend();
    MapChart.legend.valign = "bottom";
    MapChart.legend.contentAlign = "center";
    MapChart.legend.useDefaultMarker = true;
    MapChart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    MapChart.legend.labels.template.fontSize = 12;
    MapChart.legend.labels.template.fontWeight = "bold";
    MapChart.legend.labels.template.fill = am4core.color("#E1E1E1");
    MapChart.legend.paddingBottom = 10;
    MapChart.legend.paddingTop = 10;

    const marker = MapChart.legend.markers.template.children.getIndex(0);
    //marker.cornerRadius(12, 12, 12, 12);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = MapChart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;

    // Zoom control
    const restoreMap = () => {
      MapChart.goHome();
    };

    MapChart.zoomControl = new am4maps.ZoomControl();

    MapChart.zoomControl.plusButton.background.fill = am4core.color("#151724");
    MapChart.zoomControl.plusButton.background.stroke =
      am4core.color("#151724");
    MapChart.zoomControl.plusButton.stroke = am4core.color("white");
    MapChart.zoomControl.plusButton.fill = am4core.color("white");
    MapChart.zoomControl.plusButton.marginBottom = -5;
    MapChart.zoomControl.plusButton.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#151724");
    MapChart.zoomControl.plusButton.background.states.getKey(
      "hover"
    ).properties.stroke = am4core.color("#151724");

    MapChart.zoomControl.minusButton.background.fill = am4core.color("#151724");
    MapChart.zoomControl.minusButton.background.stroke =
      am4core.color("#151724");
    MapChart.zoomControl.minusButton.stroke = am4core.color("white");
    MapChart.zoomControl.minusButton.fill = am4core.color("white");
    MapChart.zoomControl.minusButton.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#151724");
    MapChart.zoomControl.minusButton.background.states.getKey(
      "hover"
    ).properties.stroke = am4core.color("#151724");

    const homeButton = new am4core.Button();
    homeButton.events.on("hit", restoreMap);

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(0, 7, 17, 5);
    homeButton.marginBottom = 10;
    homeButton.width = 30;
    homeButton.height = 30;
    homeButton.background.fill = am4core.color("#151724");
    homeButton.background.stroke = am4core.color("#151724");
    homeButton.fill = am4core.color("white");
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.parent = MapChart.zoomControl;
    homeButton.insertBefore(MapChart.zoomControl.plusButton);

    homeButton.defaultState.properties.fill = am4core.color("white");
    homeButton.background.states.getKey("hover").properties.fill =
      am4core.color("#151724");
    homeButton.background.states.getKey("hover").properties.stroke =
      am4core.color("#151724");

    seriesRef.current = polygonSeries;

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  const getCountryId = (countryName) => {
    const countryWords = countryName.split(" ");
    countryWords.map((word, j) => {
      const wordProperties = word
        .replaceAll("(", " ( ")
        .replaceAll(")", " ) ")
        .replaceAll("[", " [ ")
        .replaceAll("]", " ] ")
        .replaceAll("-", " - ")
        .split(" ");
      wordProperties.map((prop, i) => {
        if (
          prop == "(" ||
          prop == ")" ||
          prop == "[" ||
          prop == "]" ||
          prop == "-"
        ) {
          wordProperties[i] = prop;
        } else if (
          [
            "of",
            "Of",
            "the",
            "The",
            "and",
            "And",
            "part",
            "Part",
            "da",
            "Da",
          ].includes(prop)
        ) {
          wordProperties[i] = prop.toLowerCase();
        } else {
          wordProperties[i] =
            prop.charAt(0).toUpperCase() + prop.slice(1).toLowerCase();
        }
      });
      word = wordProperties.join("");
      countryWords[j] = word;
    });
    const capitalizeName = countryWords.join(" ");
    const isoCountryObj = codes.filter(
      (countryObj) =>
        countryObj.name == capitalizeName ||
        countryObj.altName == capitalizeName ||
        countryObj.shortName == capitalizeName ||
        countryObj.alpha2 == countryName.toUpperCase() ||
        countryObj.alpha3 == countryName.toUpperCase()
    );
    return isoCountryObj.length == 0 ? null : isoCountryObj[0].alpha2;
  };

  useDeepCompareEffect(() => {
    if (accountDevices) {
      const devices: IDevice[][] = Object.values(
        (accountDevices as IDevice[]).reduce((prevResults, device: IDevice) => {
          prevResults[device.country] = prevResults[device.country] || [];
          prevResults[device.country].push(device);
          return prevResults;
        }, Object.create(null))
      );

      const devicesPerCountry = [];
      devices.map((country) => {
        devicesPerCountry.push({
          country: country[0].country,
          id: getCountryId(country[0].country),
          households: Array.from(
            new Set(country.map((device) => device.priority))
          ).length,
          fill: am4core.color("#FDF2D1"),
          tooltip: "",
        });
      });

      let north, south, west, east;
      devicesPerCountry.map((item) => {
        const country = countriesData.find((obj) => obj.id === item.id);
        if (!country) {
          return;
        }
        if (north == undefined || country.north > north) {
          north = country.north;
        }
        if (south == undefined || country.south < south) {
          south = country.south;
        }
        if (west == undefined || country.west < west) {
          west = country.west;
        }
        if (east == undefined || country.east > east) {
          east = country.east;
        }
      });

      // Pre-zoom
      //TBD: to be removed later after approved by product
      // chartRef.current.zoomToRectangle(north, east, south, west, 0.75, true);

      setDirections({
        north: north,
        east: east,
        south: south,
        west: west,
        zoom: 0.75,
      });

      seriesRef.current.data = devicesPerCountry;
    }
  }, [accountDevices]);

  useEffect(() => {
    if (
      (isLoadingAccountDevices || isErrorAccountDevices) &&
      seriesRef.current
    ) {
      seriesRef.current.data = [];
    }
  }, [isLoadingAccountDevices, isErrorAccountDevices]);

  let content = null;
  let showChart = false;

  if (isLoadingAccountDevices) {
    content = <Loading className={classes.loadingMinHeight} />;
  } else if (accountDevices === null) {
    content = <NoData />;
  } else if (isErrorAccountDevices) {
    console.warn(
      "Failed to lookup for devices location data",
      errorAccountDevices.message
    );
    content = <ErrorMessage />;
  } else {
    showChart = true;
    if (
      chartRef.current &&
      directions?.north &&
      directions?.east &&
      directions?.south &&
      directions?.west
    ) {
      chartRef.current.zoomToRectangle(
        directions.north,
        directions.east,
        directions.south,
        directions.west,
        0.75,
        true
      );
    }
  }

  //TBD: to be removed later after approved by product
  // useEffect(() => {
  //   if (
  //     showChart &&
  //     chartRef.current &&
  //     directions?.north &&
  //     directions?.east &&
  //     directions?.south &&
  //     directions?.west
  //   ) {
  //     chartRef.current.zoomToRectangle(
  //       directions.north,
  //       directions.east,
  //       directions.south,
  //       directions.west,
  //       0.75,
  //       true
  //     );
  //   }
  // }, [showChart, chartRef.current, directions]);

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        {content}
        <div
          id="mapChart"
          style={{ display: showChart ? "block" : "none" }}
          className={classes.chart}
        ></div>
      </div>
    </div>
  );
};

LocationTab.propTypes = {
  accountID: PropTypes.string,
};

export default LocationTab;
