const JWTKeys = {
  accountId: "https://account.synamedia.com/id",
  accountAlias: "https://account.synamedia.com/alias",
  userId: "https://user.synamedia.com/id",
};

const parseJWT = (decodedJWT = {}) => {
  const {
    org_id: orgId,
    scope,
    [JWTKeys.accountId]: accountId,
    [JWTKeys.accountAlias]: accountAlias,
    [JWTKeys.userId]: userId,
  } = decodedJWT;

  return {
    accountId,
    accountAlias,
    userId,
    orgId,
    scope,
  };
};

export default parseJWT;
