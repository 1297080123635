import * as React from "react";
import { useState, useContext } from "react";
import PropTypes from "prop-types";

import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import classes from "./DevicesTable.module.css";
import "./DevicesTable.scss";

import Mobile from "../../../../../assets/img/mobile.svg";
import Content from "../../../../../assets/img/content.svg";
import TV from "../../../../../assets/img/TV.svg";

import { useDeepCompareEffect } from "../../../../../custom-hooks";

import Loading from "../../../../UI/Loading";
import ErrorMessage from "../../../../UI/ErrorMessage";
import NoData from "../../../../UI/NoData";

import { AccountDevicesContext } from "../../../../../contexts/accountDevicesContext";

import moment from "moment";

import FlagIcon from "./FlagIcon.js";

import { codes } from "iso-country-codes";

import HouseholdMultiSelect from "./HouseholdMultiSelect";
import { AccountsContext } from "../../../../../contexts/accountsContext";
import { AccountHouseholdsContext } from "../../../../../contexts/accountHouseholdsContext";

const DevicesTable = () => {
  const [rows, setRows] = useState([]);
  const { selectedAccount, getHashedOrRevealedDeviceId } =
    useContext(AccountsContext);
  const { selectedHouseholds, setSelectedHouseholds, setAvailableHouseholds } =
    useContext(AccountHouseholdsContext);
  const {
    accountDevices,
    isLoadingAccountDevices,
    isErrorAccountDevices,
    errorAccountDevices,
  } = useContext(AccountDevicesContext);

  const TVKeyWords = [
    "tv",
    "stb",
    "box",
    "settop",
    "station",
    "console",
    "cast",
  ];
  const MobileKeyWords = [
    "mobile",
    "phone",
    "ios",
    "android",
    "cellular",
    "cell",
  ];
  const ContentKeyWords = [
    "tablet",
    "pc",
    "desktop",
    "computer",
    "laptop",
    "phablet",
    "mac",
    "ipad",
  ];

  const getMergedDevices = () => {
    const ids = [];
    let finalObj = [];
    accountDevices.map((device) => {
      ids.push(device.device_id);
    });
    const uniqueIdsList = [...new Set(ids)];
    uniqueIdsList.map((deviceID) => {
      finalObj.push({
        device_id: deviceID,
      });
    });
    accountDevices.map((device) => {
      finalObj.map((obj, index) => {
        if (obj.device_id === device.device_id) {
          Object.keys(device)
            .filter((key) => key != "device_id")
            .map((key) => {
              if (key in finalObj[index]) {
                finalObj[index][key].push(device[key]);
              } else {
                finalObj[index][key] = [device[key]];
              }
            });
        }
      });
    });
    finalObj.map((device, i) => {
      device.id = i;

      device.device_id = getHashedOrRevealedDeviceId(
        selectedAccount.account_id,
        device.device_id
      );

      const priority = device.priority.filter((priority) => priority != "");
      let occurrences = priority.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
      }, {});
      let sortable = Object.fromEntries(
        Object.entries(occurrences).sort(([, a], [, b]) => b - a)
      );
      device.priority = Object.keys(sortable)[0] || "unknown";

      const device_type = device.device_type.filter((type) => type != "");
      occurrences = device_type.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
      }, {});
      sortable = Object.fromEntries(
        Object.entries(occurrences).sort(([, a], [, b]) => b - a)
      );
      device.device_type = Object.keys(sortable)[0] || "MOBILE";

      const countries = [
        ...new Set(device.country.filter((country) => country != "")),
      ];
      if (countries.length > 1) {
        device.country = countries.length.toString() + " countries";
      } else if (countries.length == 1) {
        device.country = countries[0];
      } else {
        device.country = "unknown";
      }

      const connectionTypes = [
        ...new Set(
          device.connection_type.filter(
            (connectionType) => connectionType != ""
          )
        ),
      ];
      if (connectionTypes.length > 0) {
        device.connection_type = connectionTypes.join(", ");
      } else {
        device.connection_type = "unknown";
      }

      const IPAddresses = device.ip_addresses.filter((ip) => ip != "");
      const sum = IPAddresses.reduce(
        (partialSum, a) => partialSum + parseInt(a),
        0
      );
      if (sum == 1) {
        device.ip_addresses = sum.toString() + " address";
      } else if (sum > 1) {
        device.ip_addresses = sum.toString() + " addresses";
      } else {
        device.ip_addresses = "unknown";
      }

      const durations = device.total_viewing_duration_seconds.filter(
        (duration) => duration != ""
      );
      const sumDuration = durations.reduce(
        (partialSum, a) => partialSum + parseInt(a),
        0
      );

      device.sumDuration = sumDuration;

      device.duration =
        (sumDuration / 3600).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + " Hours";

      const dates = device.last_active.map((date) => {
        return new Date(date);
      });
      const maxDate = new Date(Math.max.apply(null, dates));
      device.last_active = moment(maxDate).format("MMM D, HH:mm:ss");

      return finalObj;
    });

    finalObj.sort(function (a, b) {
      return a.priority - b.priority || b.sumDuration - a.sumDuration;
    });

    return finalObj;
  };

  useDeepCompareEffect(() => {
    if (accountDevices) {
      setAvailableHouseholds([
        ...new Set(accountDevices?.map((device) => device.priority)),
      ]);
      setSelectedHouseholds([
        ...new Set(accountDevices?.map((device) => device.priority)),
      ]);
      setRows(getMergedDevices());
    }
  }, [accountDevices, selectedAccount]);

  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  const getDeviceType = (type) => {
    type = type.toLocaleLowerCase();
    if (TVKeyWords.some((keyword) => type.includes(keyword))) {
      return TV;
    } else if (ContentKeyWords.some((keyword) => type.includes(keyword))) {
      return Content;
    } else if (MobileKeyWords.some((keyword) => type.includes(keyword))) {
      return Mobile;
    } else {
      return Mobile;
    }
  };

  const getIsoCode = (countryName) => {
    const countryWords = countryName.split(" ");
    countryWords.map((word, j) => {
      let wordProperties = word
        .replaceAll("(", " ( ")
        .replaceAll(")", " ) ")
        .replaceAll("[", " [ ")
        .replaceAll("]", " ] ")
        .replaceAll("-", " - ")
        .split(" ");
      wordProperties.map((prop, i) => {
        if (
          prop == "(" ||
          prop == ")" ||
          prop == "[" ||
          prop == "]" ||
          prop == "-"
        ) {
          wordProperties[i] = prop;
        } else if (
          [
            "of",
            "Of",
            "the",
            "The",
            "and",
            "And",
            "part",
            "Part",
            "da",
            "Da",
          ].includes(prop)
        ) {
          wordProperties[i] = prop.toLowerCase();
        } else {
          wordProperties[i] =
            prop.charAt(0).toUpperCase() + prop.slice(1).toLowerCase();
        }
      });
      word = wordProperties.join("");
      countryWords[j] = word;
    });
    const capitalizeName = countryWords.join(" ");
    const isoCountryObj = codes.filter(
      (countryObj) =>
        countryObj.name == capitalizeName ||
        countryObj.altName == capitalizeName ||
        countryObj.shortName == capitalizeName ||
        countryObj.alpha2 == countryName.toUpperCase() ||
        countryObj.alpha3 == countryName.toUpperCase()
    );
    return isoCountryObj;
  };

  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value, field } = props;
    let deviceTypeImg = <></>;
    if (field === "device_type") {
      const deviceTypeIcon = getDeviceType(value);
      deviceTypeImg = (
        <img
          src={deviceTypeIcon}
          style={{
            marginRight: ".5rem",
          }}
        />
      );
    }
    let countryFlag = <></>;
    if (field === "country") {
      const isoCodeObj = getIsoCode(value);
      const isoCode = isoCodeObj.length == 0 ? null : isoCodeObj[0].alpha2;
      countryFlag = isoCode ? <FlagIcon code={isoCode.toLowerCase()} /> : <></>;
    }
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
          setShowFullCell(false);
        }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: "center",
          lineHeight: "24px",
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {deviceTypeImg}
          {countryFlag}
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    field: PropTypes.string,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
        field={params.colDef.field}
      />
    );
  }

  renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.string.isRequired,
  };

  const columns = [
    {
      field: "priority",
      headerName: "Household",
      minWidth: 90,
      flex: 0.9,
      // renderCell: renderCellExpand,
    },
    {
      field: "device_id",
      headerName: "Device ID",
      minWidth: 100,
      flex: 1,
      // renderCell: renderCellExpand,
      title: "helllloooooo",
    },
    {
      field: "device_type",
      headerName: "Device Type",
      minWidth: 110,
      flex: 1.1,
      renderCell: renderCellExpand,
    },
    {
      field: "country",
      headerName: "Country",
      minWidth: 110,
      flex: 1.1,
      renderCell: renderCellExpand,
    },
    {
      field: "connection_type",
      headerName: "Connection Type",
      minWidth: 120,
      flex: 1.2,
      // renderCell: renderCellExpand,
    },
    {
      field: "ip_addresses",
      headerName: "# IP Addresses",
      minWidth: 110,
      flex: 1.1,
      // renderCell: renderCellExpand,
    },
    {
      field: "duration",
      headerName: "Viewing Duration",
      minWidth: 125,
      flex: 1.25,
      // renderCell: renderCellExpand,
    },
    {
      field: "last_active",
      headerName: "Last Active",
      minWidth: 110,
      flex: 1.1,
      // renderCell: renderCellExpand,
    },
  ];

  const tableMinWidth = columns
    .map((col) => col.minWidth)
    .reduce((a, b) => a + b);

  let content = (
    <div>
      <HouseholdMultiSelect />
      <div className="dataGrid">
        <DataGrid
          style={{ minWidth: tableMinWidth.toString() + "px" }}
          rows={rows.filter((row) => selectedHouseholds.includes(row.priority))}
          columns={columns}
          disableExtendRowFullWidth={true}
          autoHeight
        />
      </div>
    </div>
  );

  if (isLoadingAccountDevices) {
    content = <Loading className={classes.loadingMinHeight} />;
  } else if (accountDevices === null) {
    content = <NoData />;
  } else if (isErrorAccountDevices) {
    console.warn(
      "Failed to lookup for account sessions data",
      errorAccountDevices.message
    );
    content = <ErrorMessage />;
  }

  return <div className={classes.container}>{content}</div>;
};

DevicesTable.propTypes = {
  accountID: PropTypes.string,
};

export default DevicesTable;
