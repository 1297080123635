import React, { useContext, useEffect, useState } from "react";
import Card from "../../../UI/Card";

import classes from "./AvgSummary.module.scss";

import {
  useDeepCompareEffect,
  useQueryWithAuthorization,
} from "../../../../custom-hooks";

import SummaryBar from "../../specific-account/summary-bar/SummaryBar";
import { ISummaryData } from "../../../../services/accountInterfaces";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";
import { getAccountsSummary } from "../../../../external-apis";
import { AccountsContext } from "../../../../contexts/accountsContext";

const AvgSummary = () => {
  const [nonChartContent, setNonChartContent] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [avgSummaryData, setAvgSummaryData] = useState<ISummaryData>({
    score: 0,
    components: 0,
    viewing_duration_seconds: 0,
    device_types: 0,
    devices: 0,
    locations: 0,
    sessions: 0,
  });

  const { filtersProperties } = useContext(AccountsContext);

  const {
    data: accountsSummary,
    isLoading: isLoadingAccountsSummary,
    isError: isErrorAccountsSummary,
    error: errorAccountsSummary,
  } = useQueryWithAuthorization(
    ["accountsSummary", filtersProperties],
    getAccountsSummary(filtersProperties)
  );

  useDeepCompareEffect(() => {
    if (accountsSummary && accountsSummary.length > 0) {
      const avgData = accountsSummary[0];
      setAvgSummaryData({
        score: Math.round(avgData.score.average),
        components: Math.round(avgData.components.average),
        viewing_duration_seconds: avgData.duration.average,
        device_types: Math.round(avgData.device_types.average),
        devices: Math.round(avgData.devices.average),
        locations: Math.round(avgData.cities.average),
        sessions: Math.round(avgData.sessions.average),
      });
    }
  }, [accountsSummary]);

  useEffect(() => {
    if (isLoadingAccountsSummary) {
      setNonChartContent(<Loading />);
    } else if (accountsSummary === null) {
      setNonChartContent(<NoData />);
    } else if (isErrorAccountsSummary) {
      console.warn(
        "Failed to lookup for accounts summary data",
        errorAccountsSummary.message
      );
      setNonChartContent(<ErrorMessage />);
    } else {
      setNonChartContent(null);
      setShowChart(true);
    }
  }, [
    isLoadingAccountsSummary,
    accountsSummary,
    isErrorAccountsSummary,
    errorAccountsSummary,
  ]);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Average Account for Group</div>
          </div>
          <SummaryBar
            isFullScreenView={true}
            summary={avgSummaryData}
            showChart={showChart}
          />
        </div>
      </Card>
    </div>
  );
};

export default AvgSummary;
