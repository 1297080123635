import React, { useContext } from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../custom-hooks";

import {
  getAccountSummary,
  getSummaryAccounts,
} from "../../../../external-apis";

import { ISummaryData } from "../../../../services/accountInterfaces";

import SummaryProp from "./SummaryProp";

import Card from "../../../UI/Card";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import HouseholdLogo from "../../../../assets/img/household.svg";
import LocationLogo from "../../../../assets/img/location.svg";
import DeviceLogo from "../../../../assets/img/device.svg";
import SessionLogo from "../../../../assets/img/session.svg";
import UserAgentLogo from "../../../../assets/img/userAgent.svg";
import ExcessViewingLogo from "../../../../assets/img/excessViewing.svg";
import ScoreLogo from "../../../../assets/img/score.svg";

import classes from "./SummaryBar.module.scss";

import { AccountsContext } from "../../../../contexts/accountsContext";
export interface ISummaryBarProps {
  isFullScreenView: boolean;
  summary?: ISummaryData;
  showChart?: boolean;
}

const SummaryBar = (props: ISummaryBarProps) => {
  const [accountData, setAccountData] = useState<ISummaryData>(null);
  const [avgData, setAvgData] = useState<ISummaryData>(null);
  const [summaryProps, setSummaryProps] = useState([]);

  const { selectedAccount } = useContext(AccountsContext);

  const {
    data: accountSummary,
    isLoading: isLoadingAccountSummary,
    isError: isErrorAccountSummary,
    error: errorAccountSummary,
  } = props.summary
    ? {
        data: props.summary,
        isLoading: false,
        isError: false,
        error: undefined,
      }
    : useQueryWithAuthorization(
        ["accountSummary", selectedAccount],
        getAccountSummary(selectedAccount?.account_id || ""),
        { enabled: selectedAccount && selectedAccount.account_id != null }
      );

  useDeepCompareEffect(() => {
    if (props.summary) {
      setAccountData(props.summary);
    }
  }, [props.summary]);

  useDeepCompareEffect(() => {
    if (accountSummary?.[0]) {
      setAccountData({
        components: accountSummary[0].components.distinct,
        device_types: accountSummary[0].device_types.distinct,
        devices: accountSummary[0].devices.distinct,
        locations: accountSummary[0].locations.distinct,
        score: accountSummary[0].score,
        sessions: accountSummary[0].sessions.count,
        viewing_duration_seconds: accountSummary[0].duration_seconds.sum,
        definition: accountSummary[0].definition,
      });
    }
  }, [accountSummary]);

  const {
    data: summaryAccounts,
    isLoading: isLoadingSummaryAccounts,
    isError: isErrorSummaryAccounts,
    error: errorSummaryAccounts,
  } = useQueryWithAuthorization("summaryAccounts", getSummaryAccounts());

  useDeepCompareEffect(() => {
    if (summaryAccounts?.[0]) {
      setAvgData({
        components: Math.round(summaryAccounts[0].components.average),
        device_types: Math.round(summaryAccounts[0].device_types.average),
        devices: Math.round(summaryAccounts[0].devices.average),
        locations: Math.round(summaryAccounts[0].cities.average),
        score: Math.round(summaryAccounts[0].score.average),
        sessions: Math.round(summaryAccounts[0].sessions.average),
        viewing_duration_seconds: Math.round(
          summaryAccounts[0].duration.average
        ),
      });
    }
  }, [summaryAccounts]);

  const getSummaryProps = () => {
    return [
      {
        title: "Households",
        value: accountData.components,
        valueOverAvg: accountData.components - avgData.components,
        logo: HouseholdLogo,
        logoHeight: "2.5rem",
      },
      {
        title: "Viewing Dur. (h)",
        value: Math.round(accountData.viewing_duration_seconds / 3600),
        valueOverAvg:
          Math.round(accountData.viewing_duration_seconds / 3600) -
          Math.round(avgData.viewing_duration_seconds / 3600),
        logo: ExcessViewingLogo,
        logoHeight: "3.2rem",
      },
      // { title: 'Excess Viewing', value: '67.3%', valueOverAvg: Math.round(accountData.viewing_duration_seconds / 3600) - Math.round(avgData.viewing_duration_seconds / 3600), logo: ExcessViewingLogo, logoHeight: '3.2rem' },
      {
        title: "Last Score",
        value: accountData.score,
        valueOverAvg: accountData.score - avgData.score,
        logo: ScoreLogo,
        logoHeight: "2.5rem",
      },
      {
        title: "Sessions",
        value: accountData.sessions,
        valueOverAvg: accountData.sessions - avgData.sessions,
        logo: SessionLogo,
        logoWidth: "1.625rem",
        logoHeight: "1.625rem",
      },
      {
        title: "Devices",
        value: accountData.devices,
        valueOverAvg: accountData.devices - avgData.devices,
        logo: DeviceLogo,
        logoWidth: "1.625rem",
        logoHeight: "1.3125rem",
      },
      {
        title: "Locations",
        value: accountData.locations,
        valueOverAvg: accountData.locations - avgData.locations,
        logo: LocationLogo,
        logoHeight: "1.625rem",
      },
      {
        title: "User Agents",
        value: accountData.device_types,
        valueOverAvg: accountData.device_types - avgData.device_types,
        logo: UserAgentLogo,
        logoWidth: "1.625rem",
        logoHeight: "1.353rem",
      },
    ];
  };

  useDeepCompareEffect(() => {
    if (accountData && avgData) setSummaryProps(getSummaryProps());
  }, [accountData, avgData]);

  const content = (
    <div className={classes.barsContainer}>
      <ul className={classes.mainBar}>
        {summaryProps.slice(0, 3).map((prop) => {
          return prop.value != undefined ? (
            <SummaryProp
              type="main"
              title={prop.title || ""}
              value={prop.value}
              valueOverAvg={prop.valueOverAvg || undefined}
              logo={prop.logo}
              logoWidth={prop.logoWidth}
              logoHeight={prop.logoHeight}
              key={prop.title}
              isFullScreenView={props.isFullScreenView}
            />
          ) : null;
        })}
      </ul>
      <div className={classes.divider}></div>
      <ul className={classes.secondaryBar}>
        {summaryProps.slice(3).map((prop) => {
          return prop.value != undefined ? (
            <SummaryProp
              type="secondary"
              title={prop.title || ""}
              value={prop.value}
              valueOverAvg={prop.valueOverAvg || undefined}
              logo={prop.logo}
              logoWidth={prop.logoWidth}
              logoHeight={prop.logoHeight}
              key={prop.title}
            />
          ) : null;
        })}
      </ul>
    </div>
  );

  let nonChartContent = null;
  let showChart = false;
  if (isLoadingAccountSummary || isLoadingSummaryAccounts) {
    nonChartContent = <Loading />;
  } else if (accountSummary === null || summaryAccounts === null) {
    nonChartContent = <NoData />;
  } else if (isErrorAccountSummary) {
    console.warn(
      "Failed to lookup for account data",
      errorAccountSummary?.message
    );
    nonChartContent = <ErrorMessage />;
  } else if (isErrorSummaryAccounts) {
    console.warn(
      "Failed to lookup for average data",
      errorSummaryAccounts?.message
    );
    nonChartContent = <ErrorMessage />;
  } else {
    showChart = true;
  }

  return (
    <div
      className={
        props.isFullScreenView ? classes.fullScreenView : classes.tabsView
      }
    >
      <Card
        className={
          props.summary ? classes.noMarginContainer : classes.container
        }
      >
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{
            visibility:
              props.showChart != undefined && !props.showChart
                ? "hidden"
                : showChart
                ? "visible"
                : "hidden",
          }}
        >
          {content}
        </div>
      </Card>
    </div>
  );
};

SummaryBar.propTypes = {
  accountID: PropTypes.string,
  isFullScreenView: PropTypes.bool,
};

export default SummaryBar;
