import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      textAlign: "center",
    },
  };
});

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>404 - This page could not be found</h1>
    </div>
  );
};

export default PageNotFound;
