import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import classes from "./HouseholdActivity.module.scss";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import configData from "../../../../../config.js";

import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../../custom-hooks";

import {
  getAccountGantt,
  getAccountOverlaps,
} from "../../../../../external-apis";

import Loading from "../../../../UI/Loading";
import ErrorMessage from "../../../../UI/ErrorMessage";
import NoData from "../../../../UI/NoData";

import { AccountsContext } from "../../../../../contexts/accountsContext";

const HouseholdActivity = (props) => {
  const chartRef = useRef(null);
  const categoryAxisRef = useRef(null);
  const chartDateAxisRef = useRef(null);

  const [numOfHouseholds, setNumOfHouseholds] = useState<number>(0);
  const [numOfDevices, setNumOfDevices] = useState<number>(0);

  const { selectedAccount } = useContext(AccountsContext);

  const householdIconPath =
    "M49.832 19.7109L43.875 14.8281V7.21094C43.875 6.91797 43.582 6.625 43.2891 6.625H41.3359C40.9453 6.625 40.75 6.91797 40.75 7.21094V12.2891L27.0781 1.15625C26.5898 0.765625 25.7109 0.472656 25.125 0.472656C24.4414 0.472656 23.5625 0.765625 23.0742 1.15625L0.417969 19.7109C0.222656 19.8086 0.125 20.1016 0.125 20.2969C0.125 20.3945 0.125 20.6875 0.222656 20.7852L1.19922 21.957C1.39453 22.1523 1.6875 22.25 1.88281 22.25C1.98047 22.25 2.17578 22.1523 2.37109 22.0547L6.375 18.832V42.5625C6.375 43.4414 7.05859 44.125 7.9375 44.125H20.4375C21.2188 44.125 22 43.4414 22 42.5625V30.0625H28.25V42.5625C28.25 43.4414 28.9336 44.125 29.8125 44.125H42.3125C43.0938 44.125 43.875 43.4414 43.875 42.5625V18.832L47.7812 22.0547C47.9766 22.1523 48.1719 22.25 48.2695 22.25C48.5625 22.25 48.7578 22.1523 48.9531 21.957L49.9297 20.7852C50.0273 20.6875 50.0273 20.3945 50.0273 20.2969C50.0273 20.1016 49.9297 19.8086 49.832 19.7109ZM40.6523 41L31.2773 41.0977V28.5C31.2773 27.7188 30.5938 26.9375 29.8125 26.9375H20.4375H20.3398C19.5586 26.9375 18.7773 27.6211 18.7773 28.5V41H9.5V16.293L25.125 3.5L40.75 16.293L40.6523 41Z";
  const zoneName = configData.zoneName;

  const {
    data: accountGantt,
    isLoading: isLoadingAccountGantt,
    isError: isErrorAccountGantt,
    error: errorAccountGantt,
  } = useQueryWithAuthorization(
    ["accountGantt", selectedAccount],
    getAccountGantt(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount != null }
  );

  const {
    data: accountOverlaps,
    isLoading: isLoadingAccountOverlaps,
    isError: isErrorAccountOverlaps,
    error: errorAccountOverlaps,
  } = useQueryWithAuthorization(
    ["accountOverlaps", selectedAccount],
    getAccountOverlaps(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  const createChartAxisRangesFromAccountOverlaps = (AccountOverlaps) => {
    chartDateAxisRef.current.axisRanges.clear();
    AccountOverlaps.map((overlap) => {
      const range = chartDateAxisRef.current.axisRanges.create();
      range.date = overlap.localDate;
      range.endDate = overlap.localEndDate;
      range.grid.disabled = true;
      range.axisFill.fillOpacity = 1;
      range.axisFill.fill = am4core.color("#3D4257");
      range.axisFill.strokeWidth = 0;
      range.axisFill.tooltip = new am4core.Tooltip();
      range.axisFill.tooltipText = "{date} - {endDate}";
      range.axisFill.interactionsEnabled = true;
      range.axisFill.isMeasured = true;
    });
  };

  const customizeGrip = (grip) => {
    grip.icon.disabled = true;
    grip.background.fill = am4core.color("#576079");
    grip.background.stroke = am4core.color("#F0F0F0");
    grip.height = 15;
    grip.width = 15;
  };

  // create chart
  useEffect(() => {
    const chart = am4core.create("AccountGanttChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 20; // necessary
    chart.paddingTop = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = 10;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4; // opacity or "color strength"

    chart.data = [];

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxisRef.current = categoryAxis;

    categoryAxis.dataFields.category = "name";

    categoryAxis.renderer.minGridDistance = 5;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeDasharray = "3,2";
    categoryAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");
    categoryAxis.renderer.grid.template.opacity = 0.5;
    categoryAxis.renderer.inversed = true;

    // enable click on hh and show his devices in device table
    // categoryAxis.renderer.labels.template.events.on("hit", function (event) {
    //   let hh = event.target.currentText;
    //   event.target.axis._dataItems._values.map((category) => {
    //     if (category.properties.category.includes(hh + " Device")) {
    //       category.isHiding = !category.isHiding;
    //       category.hidden = !category.hidden;
    //     }
    //   });
    // setSelectedHouseholds([
    //   event.target.dataItem._dataContext.priority.toString(),
    // ]);
    // });
    // categoryAxis.renderer.labels.template.cursorOverStyle =
    //   am4core.MouseCursorStyle.pointer;

    categoryAxis.fontFamily = "Source Sans Pro, sans-serif";
    categoryAxis.fontSize = 12;
    categoryAxis.fontWeight = "600";
    categoryAxis.renderer.labels.template.fill = am4core.color("#3380ff");

    categoryAxis.renderer.labels.template.tooltip = new am4core.Tooltip();
    categoryAxis.renderer.labels.template.tooltip.pointerOrientation = "left";
    categoryAxis.renderer.labels.template.tooltipText = "text";
    categoryAxis.renderer.labels.template.align = "left";

    categoryAxis.renderer.labels.template.adapter.add(
      "fill",
      function (fill, target) {
        const category = target.dataItem.properties.category;
        if (!category?.includes("Device")) {
          return am4core.color("#FFFFFF");
        }
        return fill;
      }
    );

    categoryAxis.title.fontFamily = "Source Sans Pro, sans-serif";
    categoryAxis.title.fontWeight = "normal";
    categoryAxis.title.fontSize = 12;
    categoryAxis.title.fill = am4core.color("#979797");
    categoryAxis.renderer.baseGrid.stroke = am4core.color("#979797");
    categoryAxis.renderer.baseGrid.fill = am4core.color("#A0CA92");
    categoryAxis.renderer.baseGrid.strokeWidth = 5;
    categoryAxis.renderer.baseGrid.strokeOpacity = 0;

    // categoryAxis.renderer.line.strokeOpacity = 1;
    // categoryAxis.renderer.line.strokeWidth = 20;
    // categoryAxis.renderer.line.stroke = am4core.color("#3787ac");

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    chartDateAxisRef.current = dateAxis;
    dateAxis.dateFormatter.dateFormat = "MMM d, HH:mm:ss";
    dateAxis.renderer.minGridDistance = 45; // spaces between two dates (hours)
    // dateAxis.baseInterval = { count: 1, timeUnit: "millisecond" };
    dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;

    dateAxis.title.fontFamily = "Roboto";
    dateAxis.title.fontWeight = "bold";
    dateAxis.title.fontSize = 12;
    // dateAxis.renderer.labels.template.fill = am4core.color('#979797');
    dateAxis.renderer.labels.template.fill = am4core.color("#FFFFFF");
    // dateAxis.renderer.grid.disabled = true;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    dateAxis.renderer.grid.template.stroke = am4core.color("#FFFFFF");
    dateAxis.renderer.grid.template.opacity = 0;
    dateAxis.renderer.baseGrid.stroke = am4core.color("#979797");
    dateAxis.renderer.baseGrid.fill = am4core.color("#979797");
    dateAxis.renderer.baseGrid.strokeWidth = 5;
    dateAxis.renderer.baseGrid.strokeOpacity = 0;
    dateAxis.renderer.ticks.template.disabled = true;

    /*
        AccountOverlaps.map(overlap => {
            let range = dateAxis.axisRanges.create();
            range.date = overlap.localDate;
            range.endDate = overlap.localEndDate;
            range.grid.disabled = true;
            range.axisFill.fillOpacity = 1;
            range.axisFill.fill = am4core.color('#3D4257');
            range.axisFill.strokeWidth = 0;
            range.axisFill.tooltip = new am4core.Tooltip();
            range.axisFill.tooltipText = "{date} - {endDate}";
            range.axisFill.interactionsEnabled = true; 
            range.axisFill.isMeasured = true;
        })
        */

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Active";
    series.fill = am4core.color("white");
    series.columns.template.height = am4core.percent(70);
    series.columns.template.tooltipText =
      "{start_time_local.formatDate('MMM d, HH:mm:ss')} - {end_time_local.formatDate('MMM d, HH:mm:ss')}";
    series.dataFields.openDateX = "start_time_local";
    series.dataFields.dateX = "end_time_local";
    series.dataFields.categoryY = "name";
    series.columns.template.strokeOpacity = 1;

    series.columns.template.adapter.add("fill", function () {
      return am4core.color("#3380ff");
    });

    series.columns.template.adapter.add("fill", function (fill, target) {
      const category = target.dataItem.categories.categoryY;
      if (!category?.includes("Device")) {
        return am4core.color("#F0F0F0");
      }
      return fill;
    });

    series.columns.template.adapter.add("strokeWidth", function () {
      return 0;
    });

    series.columns.template.adapter.add("stroke", function () {
      return am4core.color("#F0F0F0");
    });

    chart.legend = new am4charts.Legend();
    chart.legend.valign = "bottom";
    chart.legend.contentAlign = "center";
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontWeight = "normal";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = 0;
    chart.legend.paddingTop = -15;

    chart.legend.data.push({
      name: "Overlapping Activity",
      fill: am4core.color("#3D4256"),
    });

    const marker = chart.legend.markers.template.children.getIndex(0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 1;

    const image = new am4core.Image();
    image.dx =
      props.isFullScreenView && numOfDevices != numOfHouseholds ? -163 : -110;
    image.paddingLeft = 25;
    image.dy = -2;
    image.horizontalCenter = "left";
    image.scale = 0.35;
    image.verticalCenter = "middle";
    image.fill = am4core.color("#F0F0F0");
    image.path = householdIconPath;
    image.adapter.add("fillOpacity", (fillOpacity, target) => {
      const category = target.dataItem.category;
      if (!category?.includes("Device")) {
        return 1;
      }
      return 0;
    });
    categoryAxis.dataItems.template.bullet = image;

    //////////////////////////////////////////////////////////////////////////

    // var series2 = chart.series.push(new am4charts.ColumnSeries());
    // series2.columns.template.width = am4core.percent(80);
    // series2.columns.template.tooltipText = "{component}: {start_time_utc} - {end_time_utc}, {device_type}";

    // series2.dataFields.openDateX = "start_time_utc";
    // series2.dataFields.dateX = "end_time_utc";
    // series2.dataFields.categoryY = "component";
    // series2.columns.template.propertyFields.fill = "color"; // get color from data
    // series2.columns.template.propertyFields.stroke = "color";
    // series2.columns.template.strokeOpacity = 1;

    ////////////////////////////////////////////////////////////////////////

    // let adjustHeight = chart.data.length * 100 - categoryAxis.pixelHeight;

    // get current chart height
    // let targetHeight = chart.pixelHeight + adjustHeight;

    // Set it on chart's container
    // chart.svgContainer.htmlElement.style.height = targetHeight + "px";

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.height = 5;
    chart.scrollbarX.background.fill = am4core.color("#3D4257");
    chart.scrollbarX.background.opacity = 0.4;
    chart.scrollbarX.thumb.background.fill = am4core.color("#3D4257");
    chart.scrollbarX.thumb.background.strokeWidth = 0;
    chart.scrollbarX.thumb.background.opacity = 0.4;
    chart.scrollbarX.thumb.minWidth = 20;
    chart.scrollbarX.dy = -12;

    customizeGrip(chart.scrollbarX.startGrip);
    customizeGrip(chart.scrollbarX.endGrip);

    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#576079");
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.opacity = 0.1;
    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill =
      am4core.color("#576079");
    chart.scrollbarX.endGrip.background.states.getKey(
      "hover"
    ).properties.opacity = 0.1;
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
      am4core.color("#576079");
    chart.scrollbarX.thumb.background.states.getKey(
      "hover"
    ).properties.opacity = 0.1;

    // Applied on mouse down
    chart.scrollbarX.startGrip.background.states.getKey(
      "down"
    ).properties.fill = am4core.color("#576079");
    chart.scrollbarX.startGrip.background.states.getKey(
      "down"
    ).properties.opacity = 0.4;
    chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill =
      am4core.color("#576079");
    chart.scrollbarX.endGrip.background.states.getKey(
      "down"
    ).properties.opacity = 0.4;
    chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
      am4core.color("#576079");
    chart.scrollbarX.thumb.background.states.getKey(
      "down"
    ).properties.opacity = 0.4;

    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#25283D");
    chart.zoomOutButton.stroke = am4core.color("red");
    chart.zoomOutButton.strokeWidth = 2;
    chart.zoomOutButton.icon.stroke = am4core.color("white");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#606271");

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  useDeepCompareEffect(() => {
    if (accountGantt) {
      let accountSessions = accountGantt.sort((a, b) =>
        a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
      );
      accountSessions.map(
        (session) =>
          (session.start_time_local = moment
            .utc(session.start_time_utc)
            .tz(zoneName)
            .format("YYYY-MM-DD HH:mm:ss"))
      );
      accountSessions.map(
        (session) =>
          (session.end_time_local = moment
            .utc(session.end_time_utc)
            .tz(zoneName)
            .format("YYYY-MM-DD HH:mm:ss"))
      );

      const numOfDevicesDict = Object.assign(
        {},
        ...accountSessions.map((x) => ({
          [x.priority]: Array.from(
            new Set(
              accountSessions
                .filter((item) => item.priority == x.priority)
                .map((session) => session.device_id)
            )
          ),
        }))
      );

      accountSessions.map(
        (session) => (session.name = "Household #" + session.priority)
      );

      const householdsTotalDuration = {};
      Array.from(new Set(accountGantt.map((session) => session.priority))).map(
        (priority) =>
          (householdsTotalDuration[priority] = accountSessions
            .filter((accountSession) => accountSession.priority === priority)
            .map(
              (session) =>
                (new Date(session.end_time_local).getTime() -
                  new Date(session.start_time_local).getTime()) /
                3600000
            )
            .reduce((accumulator, a) => accumulator + a, 0)
            .toLocaleString(undefined, { maximumFractionDigits: 2 }))
      );

      categoryAxisRef.current.renderer.labels.template.adapter.add(
        "tooltipText",
        function (text, target) {
          if (
            householdsTotalDuration &&
            householdsTotalDuration[target._dataItem.dataContext.priority] &&
            !target._dataItem.dataContext.name.includes("Device")
          ) {
            return (
              "Total Duration: " +
              householdsTotalDuration[target._dataItem.dataContext.priority] +
              " hours"
            );
          }
          return "";
        }
      );

      const objectsMap = new Map();
      const allSession = accountGantt.map((session) => ({
        priority: session.priority,
        component: session.component,
      }));
      allSession.forEach((object) => {
        objectsMap.set(object.priority, object);
      });

      if (props.isFullScreenView) {
        accountSessions.map((session) => {
          if (numOfDevicesDict[session.priority].length != 1) {
            accountSessions.push({
              ...session,
              name:
                "Household #" +
                session.priority +
                ": Device #" +
                (numOfDevicesDict[session.priority].indexOf(session.device_id) +
                  1),
            });
          }
        });
      }

      accountSessions = accountSessions.sort((a, b) =>
        a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
      );

      chartRef.current.data = accountSessions;

      const hh = Array.from(
        new Set(accountGantt.map((session) => session.component))
      ).length;
      setNumOfHouseholds(hh);
      const devices = Array.from(
        new Set(accountGantt.map((session) => session.device_id))
      ).length;
      setNumOfDevices(devices);

      categoryAxisRef.current.dataItems.template.bullet.dx =
        props.isFullScreenView && devices != hh ? -163 : -110;

      props.setHouseholdActivityLoaded(true);
    }
  }, [accountGantt, selectedAccount]);

  useDeepCompareEffect(() => {
    if (accountOverlaps) {
      accountOverlaps.map((overlap) => {
        overlap.localDate = new Date(
          new Date(overlap.start).toLocaleString("en", { timeZone: zoneName })
        );
        overlap.localEndDate = new Date(
          new Date(overlap.end).toLocaleString("en", { timeZone: zoneName })
        );
      });

      createChartAxisRangesFromAccountOverlaps(accountOverlaps);
    }
  }, [accountOverlaps, selectedAccount]);

  let content = (
    <div className={classes.title}>{"Households Activity".toUpperCase()}</div>
  );

  let showChart = false;
  if (isLoadingAccountGantt || isLoadingAccountOverlaps) {
    content = <Loading className={classes.loadingMinHeight} />;
  } else if (accountGantt === null) {
    content = <NoData />;
  } else if (isErrorAccountGantt) {
    console.warn(
      "Failed to lookup for AccountGantt data",
      errorAccountGantt.message
    );
    content = <ErrorMessage />;
  } else if (isErrorAccountOverlaps) {
    console.warn(
      "Failed to lookup for AccountOverlaps data",
      errorAccountOverlaps.message
    );
    content = <ErrorMessage />;
  } else if (chartRef.current.data.length == 0) {
    content = <Loading className={classes.loadingMinHeight} />;
  } else {
    showChart = true;
  }

  return (
    <div className={props.isFullScreenView ? null : classes.tabsView}>
      <div
        className={classes.container}
        style={{
          height: `${
            props.isFullScreenView
              ? numOfDevices * 3.46875 + 6
              : numOfHouseholds * 3.46875 + 12
          }rem`,
          padding: "0.5rem",
        }}
      >
        <div className={classes.chartWrapper}>
          {content}
          <div
            id="AccountGanttChart"
            style={{ display: showChart ? "block" : "none" }}
            className={classes.chart}
          ></div>
        </div>
      </div>
    </div>
  );
};

HouseholdActivity.propTypes = {
  accountID: PropTypes.string,
  setHouseholdID: PropTypes.func,
  setHouseholdsMap: PropTypes.func,
  isFullScreenView: PropTypes.bool,
};

export default HouseholdActivity;
