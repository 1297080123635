import { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import classes from "./DurationOverTime.module.css";

import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { useDeepCompareEffect } from "../../../../custom-hooks";

import Loading from "../../../UI/Loading";
import ErrorMessage from "../../../UI/ErrorMessage";
import NoData from "../../../UI/NoData";

import {
  getAccountSummaryByDate,
  getSummaryAccountsByDate,
} from "../../../../external-apis";
import { sortItemsWithDate } from "../../../../utils/statisticsUtils";
import { secondsToHours } from "../../../../utils/timeUnitsConverter";
import { IChartItem } from "./IChartItem";

import configData from "../../../../config.js";

import { AccountsContext } from "../../../../contexts/accountsContext";

interface IAvrDurationOverTimeData {
  date: string;
  duration: { average: number };
}

interface IDurationOverTimeData {
  date: string;
  duration_seconds: { sum: number };
}

const DurationOverTime = (props) => {
  const chartRef = useRef(null);
  const avgSeriesRef = useRef(null);
  const accountSeriesRef = useRef(null);
  const { selectedAccount } = useContext(AccountsContext);

  const {
    data: accountSummaryByDate,
    isLoading: isLoadingAccountSummaryByDate,
    isError: isErrorAccountSummaryByDate,
    error: errorAccountSummaryByDate,
  } = useQueryWithAuthorization(
    ["accountSummaryByDate", selectedAccount],
    getAccountSummaryByDate(selectedAccount?.account_id || ""),
    { enabled: selectedAccount && selectedAccount.account_id != null }
  );

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("durationOverTimeChart", am4charts.XYChart);
    chartRef.current = chart;

    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }

    chart.dateFormatter.dateFormat = "dd MMM yyyy";

    chart.paddingRight = 5;
    chart.paddingTop = 15;
    chart.paddingBottom = -17;
    chart.paddingLeft = -5;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 35;
    // dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.grid.template.strokeDasharray = "3,2";
    // dateAxis.renderer.grid.template.opacity = 0.5;
    dateAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontFamily = "Source Sans Pro, sans-serif";
    dateAxis.fontSize = 12;
    dateAxis.fontWeight = "bold";
    dateAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    dateAxis.renderer.labels.template.dy = -7;

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    // valueAxis.renderer.grid.template.opacity = 0.5;
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 16;
    // valueAxis.extraMax = 0.2;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 10;
    valueAxis.fontWeight = "bold";
    valueAxis.renderer.labels.template.fill = am4core.color("#E1E1E1");
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "#h";

    // Create series
    const avgSeries = chart.series.push(new am4charts.LineSeries());
    avgSeries.dataFields.valueY = "value";
    avgSeries.dataFields.dateX = "date";
    avgSeries.name = "Average Duration";
    avgSeries.fill = am4core.color("#F0F0F0");
    avgSeries.strokeWidth = 2;

    avgSeries.stroke = am4core.color("#F0F0F0");
    avgSeriesRef.current = avgSeries;

    // avgSeries.bullets.push(new am4charts.CircleBullet());

    const accountSeries = chart.series.push(new am4charts.LineSeries());
    accountSeries.dataFields.valueY = "value";
    accountSeries.dataFields.dateX = "date";
    accountSeries.name = "Account Duration";
    accountSeries.fill = am4core.color("#3380FF");
    accountSeries.strokeWidth = 2;

    accountSeries.stroke = am4core.color("#3380FF");
    // accountSeries.fill = am4core.color('#e8a2c6');
    accountSeries.fillOpacity = 0.15;

    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    accountSeries.segments.template.fillModifier = fillModifier;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.contentAlign = "center";
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontFamily = "Source Sans Pro, sans-serif";
    chart.legend.labels.template.fontSize = 12;
    chart.legend.labels.template.fontWeight = "normal";
    chart.legend.labels.template.fill = am4core.color("white");
    chart.legend.paddingBottom = -3;
    chart.legend.paddingTop = -20;

    const marker = chart.legend.markers.template.children.getIndex(0);
    // marker.cornerRadius(0, 0, 0, 0);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 8;
    markerTemplate.height = 8;

    accountSeriesRef.current = accountSeries;
    // accountSeries.bullets.push(new am4charts.CircleBullet());

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    if (accountSummaryByDate) {
      const duration: IChartItem[] = sortItemsWithDate<IDurationOverTimeData>(
        accountSummaryByDate
      ).map((item) => {
        return {
          date: item.date,
          value: secondsToHours(item.origItem.duration_seconds.sum),
        };
      });

      accountSeriesRef.current.data = duration;
    }
  }, [accountSummaryByDate]);

  const {
    data: summaryAccountsByDate,
    isLoading: isLoadingSummaryAccountsByDate,
    isError: isErrorSummaryAccountsByDate,
    error: errorSummaryAccountsByDate,
  } = useQueryWithAuthorization(
    "summaryAccountsByDate",
    getSummaryAccountsByDate()
  );

  useDeepCompareEffect(() => {
    if (summaryAccountsByDate) {
      const duration: IChartItem[] =
        sortItemsWithDate<IAvrDurationOverTimeData>(summaryAccountsByDate).map(
          (item) => {
            return {
              date: item.date,
              value: secondsToHours(item.origItem.duration.average),
            };
          }
        );

      avgSeriesRef.current.data = duration;
    }
  }, [summaryAccountsByDate]);

  useEffect(() => {
    if (isLoadingSummaryAccountsByDate || isErrorSummaryAccountsByDate) {
      avgSeriesRef.current.data = [];
    }
    if (isLoadingAccountSummaryByDate || isErrorAccountSummaryByDate) {
      accountSeriesRef.current.data = [];
    }
  }, [
    isLoadingSummaryAccountsByDate,
    isLoadingAccountSummaryByDate,
    isErrorAccountSummaryByDate,
    isErrorSummaryAccountsByDate,
  ]);

  //let content = <div className={classes.title}>Excess Viewing</div>;

  // let content = <div className={classes.main}>
  //     <div className={classes.title}>Excess Viewing</div>
  //     <div id="durationOverTimeChart" className={classes.chart}></div>
  // </div>;

  let nonChartContent = null;
  if (isLoadingAccountSummaryByDate || isLoadingSummaryAccountsByDate) {
    nonChartContent = <Loading />;
  } else if (accountSummaryByDate === null || summaryAccountsByDate === null) {
    nonChartContent = <NoData />;
  } else if (isErrorAccountSummaryByDate) {
    console.warn(
      "Failed to lookup for account duration data",
      errorAccountSummaryByDate.message
    );
    nonChartContent = <ErrorMessage />;
  } else if (isErrorSummaryAccountsByDate) {
    console.warn(
      "Failed to lookup for average duration data",
      errorSummaryAccountsByDate.message
    );
    nonChartContent = <ErrorMessage />;
  }

  return (
    <div
      className={
        props.isFullScreenView ? classes.fullScreenView : classes.tabsView
      }
    >
      <div className={classes.container}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: nonChartContent ? "hidden" : "visible" }}
        >
          <div className={classes.title}>Excess Viewing</div>
          <div id="durationOverTimeChart" className={classes.chart}></div>
        </div>
      </div>
    </div>
  );
};

DurationOverTime.propTypes = {
  accountID: PropTypes.string,
  isFullScreenView: PropTypes.bool,
};

export default DurationOverTime;
