import create from "zustand";
// import { ProductsPanel, TenantsPanel } from "../components/panels";
// import HomeWorkTwoToneIcon from "@material-ui/icons/HomeWorkTwoTone";
// import { ProductLogo } from "../icons";

const initialState = Object.freeze({
  activeComponent: undefined,
  routes: [
    // {
    // 	path: "/tenants",
    // 	component: TenantsPanel,
    // 	sidebar: {
    // 		id: "tenants",
    // 		title: "Tenants",
    // 		icon: <HomeWorkTwoToneIcon/>
    // 	}
    // },
    // {
    // 	path: "/products",
    // 	component: ProductsPanel,
    // 	sidebar: {
    // 		id: "products",
    // 		title: "Products",
    // 		icon: <ProductLogo/>
    // 	},
    // },
  ],
});

const compByRoutes = (routes) => {
  return routes.reduce((compByRoutes, routeObj) => {
    const { path, component, sidebar = {} } = routeObj;

    compByRoutes[path] = {
      component,
      sidebar,
    };

    return compByRoutes;
  }, {});
};

const useStore = create((set) => ({
  ...initialState,

  componentsRoutes: compByRoutes(initialState.routes),
  setActiveComponentByRoute: (route) => {
    set(
      (state) =>
        Object.prototype.hasOwnProperty.call(state.componentsRoutes, route) && {
          activeComponent: state.componentsRoutes[route].component,
        }
    );
  },
  reset: () => {
    set(() => ({ ...initialState }));
  },
}));

export default useStore;
