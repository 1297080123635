import { useRef, useState } from "react";

export function useAlignedPopperWidth<
  T extends HTMLElement = HTMLDivElement
>() {
  const [popperWidth, setPopperWidth] = useState(0);
  const elementAlignedToRef = useRef<T>(null);

  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const toggleIsPopperOpenClick = (/*event*/) => {
    setPopperWidth(elementAlignedToRef.current?.clientWidth || 0);
    setIsPopperOpen((val) => !val);
  };

  return {
    elementAlignedToRef,
    toggleIsPopperOpenClick,
    isPopperOpen,
    popperWidth,
  };
}
