import React from "react";
import Card from "../../UI/Card";

import classes from "./ScoreDistribution.module.css";

import { useRef, useEffect } from "react";

import { useQueryWithAuthorization } from "../../../custom-hooks";
import { useDeepCompareEffect } from "../../../custom-hooks";

import Loading from "../../UI/Loading";
import ErrorMessage from "../../UI/ErrorMessage";
import NoData from "../../UI/NoData";

import { getScoresDistribution } from "../../../external-apis";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import configData from "../../../config.js";

const scoresDistribution = () => {
  const chartRef = useRef(null);
  const seriesRef = useRef(null);

  const sum = (array) => {
    return array && array.length > 0 ? array.reduce((a, b) => a + b) : 0;
  };

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("scoresDistributionChart", am4charts.XYChart);
    chartRef.current = chart;
    am4core.addLicense(configData.am4chartsLicense);
    if (chart.logo !== undefined) {
      chart.logo.disabled = true;
    }
    chart.paddingRight = 5;
    chart.paddingTop = 10;
    chart.paddingBottom = 0;
    chart.paddingLeft = -10;
    chart.numberFormatter.numberFormat = "#.##%";
    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 1;
    categoryAxis.renderer.grid.template.strokeDasharray = "3,2";
    categoryAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    // categoryAxis.renderer.grid.template.opacity = 0.5;
    categoryAxis.renderer.baseGrid.disabled = true;
    categoryAxis.fontFamily = "Source Sans Pro, sans-serif";
    categoryAxis.fontSize = 12;
    categoryAxis.fontWeight = "normal";
    categoryAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.calculateTotals = true;
    valueAxis.min = 0;
    valueAxis.max = 1;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.renderer.grid.template.strokeDasharray = "3,2";
    valueAxis.renderer.grid.template.stroke = am4core.color("#E1E1E1");
    // valueAxis.renderer.grid.template.opacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.fontFamily = "Source Sans Pro, sans-serif";
    valueAxis.fontSize = 12;
    valueAxis.fontWeight = "normal";
    valueAxis.renderer.labels.template.fill = am4core.color("#9AA0AF");
    // Modify chart's colors
    chart.colors.list = [am4core.color("#3380FF")];
    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.columns.template.width = am4core.percent(55);
    series.columns.template.tooltipText = "{value}";

    seriesRef.current = series;
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, []);
  const {
    data: scoresDistribution,
    isLoading: isLoadingScoresDistribution,
    isError: isErrorScoresDistribution,
    error: errorScoresDistribution,
  } = useQueryWithAuthorization(
    ["scoresDistribution"],
    getScoresDistribution()
  );

  useDeepCompareEffect(() => {
    if (scoresDistribution) {
      const allAccounts = sum(scoresDistribution.map((item) => item.accounts));
      const distribution = scoresDistribution.map((interval) => {
        return {
          category: interval.bin,
          value: interval.accounts / allAccounts,
        };
      });
      chartRef.current.data = distribution;
    }
  }, [scoresDistribution]);
  useEffect(() => {
    if (isLoadingScoresDistribution || isErrorScoresDistribution) {
      seriesRef.current.data = [];
    }
  }, [isLoadingScoresDistribution, isErrorScoresDistribution]);
  let nonChartContent = null;
  let showChart = false;
  if (isLoadingScoresDistribution) {
    nonChartContent = <Loading />;
  } else if (scoresDistribution === null) {
    nonChartContent = <NoData />;
  } else if (isErrorScoresDistribution) {
    console.warn(
      "Failed to lookup for score data",
      errorScoresDistribution?.message
    );
    nonChartContent = <ErrorMessage />;
  } else {
    showChart = true;
  }
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {nonChartContent && (
          <div className={"absVerticalCenterContent"}>{nonChartContent}</div>
        )}
        <div
          className={classes.main}
          style={{ visibility: showChart ? "visible" : "hidden" }}
        >
          <div className={classes.header}>
            <div className={classes.title}>
              Score Distribution of Sharer Accounts
            </div>
          </div>
          <div id="scoresDistributionChart" className={classes.chart}></div>
        </div>
      </Card>
    </div>
  );
};
export default scoresDistribution;
